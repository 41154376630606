import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../../../../components";
import Switch from "react-switch";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Skeleton from "react-loading-skeleton";

const AI = ({
  form,
  handleChangeswitch,
  handleChangeForm,
  onChangeFunnel,
  funnelValue,
  funnels,
  loading,
  handleChangeQuestionList,
  questionListValue,
  loadQuestionListOptions,
  questionsList,
}) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <CollapsePanel
      title="Artificial Intelligence"
      subtitle="Enable Artificial Intelligence to this number"
    >
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex flex-column">
          <label className="itp-edit-number-label-1">AI</label>
          <label className="itp-edit-number-label-2">
             Enable auto response using AI
          </label>
        </div>
        {loading ? (
          <Skeleton width="5rem" height="2rem" />
        ) : (
          <Switch
            className="itp-custom-table-switch mt-3"
            onColor="#626ed4"
            height={20} // Ajusta la altura según tus necesidades
            width={40}
            checked={form.ai_enabled}
            onChange={() => handleChangeswitch("ai_enabled")}
          />
        )}
      </div>

      {form.ai_enabled && (
        <>
          <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
            <div className="d-flex flex-column">
              <label className="itp-edit-number-label-1">
                Appointment Scheduling
              </label>
              <label className="itp-edit-number-label-2">
                Ability to Create or Update appointments within User Assigned
                Calendars
              </label>
            </div>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <Switch
                className="itp-custom-table-switch mt-3"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={form.ai_manage_appointments}
                onChange={() => handleChangeswitch("ai_manage_appointments")}
              />
            )}
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
            <div className="d-flex flex-column">
                  <label className="itp-edit-number-label-1">
                    Appointment Scheduling Strategy
                  </label>
                  <label className="itp-edit-number-label-2">
                    Select how we should schedule appointments when there is more
                    than one user
                  </label>
            </div>
            {loading ? (
              <Skeleton height="2.5rem" width="10rem" />
            ) : (
              <select
                style={{ marginLeft: "0", width: "12.5rem" }}
                className="form-select-itp"
                value={form.ai_calender_strategy}
                name="ai_calender_strategy"
                onChange={handleChangeForm}
              >
                <option value="" disabled>
                  Select Option
                </option>
                <option value="round_robin">Round Robin</option>
                <option value="lease_busy">Lease Busy</option>
                <option value="random">Random</option>
              </select>
            )}
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
            <div className="d-flex flex-column w-90">
              <label className="itp-edit-number-label-1">
                Lead Management
              </label>
              <label className="itp-edit-number-label-2">
                Ability to Create, Update Leads, New leads will be assigned to
                Funnels and Stages which closely resemble the conversation
                flow. Ensure accurate descriptions and names are used in
                Funnels and Funnels Stages
              </label>
            </div>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <Switch
                className="itp-custom-table-switch mt-3"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={form.ai_lead_manager}
                onChange={() => handleChangeswitch("ai_lead_manager")}
              />
            )}
          </div>

          {form.ai_lead_manager && (
            <>
              <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
                <div className="d-flex flex-column">
                  <label className="itp-edit-number-label-1">
                    Lead Management Funnel
                  </label>
                  <label className="itp-edit-number-label-2">
                    Funnel to use for Lead Management
                  </label>
                </div>
                {loading ? (
                  <Skeleton height="3rem" width="10rem" />
                ) : (
                  <Select
                    onChange={onChangeFunnel}
                    className="basic-single itp-edit-number-ai-select"
                    classNamePrefix="select"
                    value={funnelValue}
                    isSearchable={true}
                    name="ai_lead_manager_funnel_id"
                    placeholder="Select Option"
                    options={funnels}
                  />
                )}
              </div>

              <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
                <div className="d-flex flex-column">
                  <label className="itp-edit-number-label-1">
                    Lead Manager Screening Questions List
                  </label>
                  <label className="itp-edit-number-label-2">
                    Screening Questions List to use for Lead Management (none)
                  </label>
                </div>
                {loading ? (
                  <Skeleton height="3rem" width="10rem" />
                ) : (
                  <AsyncSelect
                    onChange={handleChangeQuestionList}
                    className="basic-single itp-edit-number-ai-select"
                    classNamePrefix="select"
                    value={questionListValue}
                    isSearchable={true}
                    name="ai_lead_manager_funnel_id"
                    placeholder="Select Option"
                    loadOptions={loadQuestionListOptions}
                    defaultOptions={questionsList}
                    cacheOptions
                  />
                )}
              </div>
            </>
          )}

          <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
            <div className="d-flex flex-column">
              <label className="itp-edit-number-label-1">FAQ</label>
              <label className="itp-edit-number-label-2">
                Ability to respond with answers from the FAQ if a question is
                asked related to your industry.
              </label>
            </div>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <Switch
                className="itp-custom-table-switch mt-3"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={form.ai_faq}
                onChange={() => handleChangeswitch("ai_faq")}
              />
            )}
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
            <div className="d-flex flex-column">
              <label className="itp-edit-number-label-1">Auto Followup</label>
              <label className="itp-edit-number-label-2">
                Enables automatic chat tracking. If a sent message does not
                receive a response within a set time period, the AI will
                automatically send a nother message.
              </label>
            </div>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <Switch
                className="itp-custom-table-switch mt-3"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={form.auto_followup}
                onChange={() => handleChangeswitch("auto_followup")}
              />
            )}
          </div>
        </>
      )}
    </CollapsePanel>
  </>
);

export default AI;
