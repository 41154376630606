import { useEffect, useRef, useState } from "react";
import { LabelSelect } from "../index";
import petitionGet from "../../services/petitionGet";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const FunnelStages = ({ 
  loading, 
  form, 
  setForm, 
  defaultFunnelId, 
  defaultFunnel, 
  setFinishFetchFunnels, 
  defaultStageId, 
}) => {
  const firstLoad = useRef(true)

  const [stages, setStages] = useState([]);
  const [valueFunnel, setValueFunnel] = useState(null);
  const [valueStage, setValueStage] = useState(null);
  const [loadingChangeFunnel, setLoadingChangeFunnel] = useState(false);

  const handleChangeFunnels = (e) => {
    setLoadingChangeFunnel(true);
    petitionGet("stages", { parameter: `?funnel_id=${e.pk}` })
      .then(({ data: result }) => {
        if (result.result.length > 0) {
          result.result.forEach((stage) => {
            stage.label = stage.name;
            stage.value = stage.pk;
          });

          setStages(result.result);
        } else {
          setStages([]);
        }

        setForm({
          ...form,
          funnel_id: e.pk,
          funnel_pipeline_stage_id: "",
        });
        setValueFunnel(e);
        setValueStage(null);
        setLoadingChangeFunnel(false);
      })
      .catch((error) => console.log(error));
  };

  const loadOptionFunnel = (inputValue, callback) => {
    petitionGet("funnels", { parameter: inputValue ? `?name=${inputValue}` : "?offset=0&limit=10" })
      .then(({ data: result }) => {
        result.result.forEach((funnel) => {
          funnel.label = funnel?.name || "";
          funnel.value = funnel?.pk || null; 
        });

        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const petition = async () => {
    await petitionGet("stages", { parameter: `?funnel_id=${defaultFunnelId}` })
    .then(({ data: result }) => {
      if (result.result.length > 0) {
        result.result.forEach((stage) => {
          stage.label = stage.name;
          stage.value = stage.pk;
        });

        const defaultValueStage = result.result.find( (element) => element.pk === defaultStageId);

        firstLoad.current = false
        setValueStage(defaultValueStage);
        setStages(result.result);
        setFinishFetchFunnels(true)
      }
    })
    .catch((error) => console.log(error));
  }

  useEffect(() => {
    setValueFunnel(defaultFunnel || null);
  }, [defaultFunnel])

  useEffect(() => {
    if (defaultFunnelId && firstLoad.current) petition()
  }, [defaultFunnelId])

  return (
    <>
      <LabelSelect label="Funnel" loading={loading}>
        <AsyncSelect
          onChange={handleChangeFunnels}
          className="basic-single"
          classNamePrefix="select"
          value={valueFunnel}
          isSearchable={true}
          name="funnel_id"
          placeholder="Select a Funnel"
          loadOptions={loadOptionFunnel}
          defaultOptions
          cacheOptions
        />
      </LabelSelect>

      {loadingChangeFunnel ? (
        <LabelSelect label="Stages" loading={loadingChangeFunnel}>
          <></>
        </LabelSelect>
      ) : (
        <>
          {form.funnel_id && (
            <>
              {(loading || stages.length > 0) ? (
                <LabelSelect label="Stages" loading={loading}>
                  <Select
                    onChange={(e) => {
                      setForm({
                        ...form,
                        funnel_pipeline_stage_id: e.value,
                      });
                      setValueStage(e);
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    value={valueStage}
                    isSearchable={true}
                    name="funnel_pipeline_stage_id"
                    placeholder="Select a Stage"
                    options={stages}
                  />
                </LabelSelect>
              ) : (
                <p>There are no stages created for this funnel</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default FunnelStages;
