// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-10dlc-arrow-down {
  width: 2.5rem !important;
  height: 2.5rem !important;
  color: rgba(58, 58, 58, 0.5) !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/10dlc/10dlc.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,yBAAyB;EACzB,uCAAuC;AACzC","sourcesContent":[".itp-10dlc-arrow-down {\n  width: 2.5rem !important;\n  height: 2.5rem !important;\n  color: rgba(58, 58, 58, 0.5) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
