// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-edit-contact-container-custom-field {
  position: relative;
}

.itp-edit-contact-custom-field-actions {
  position: absolute;
  background-color: #ffffff;
  width: 6.5rem;
  visibility: hidden;
  top: -3rem;
  /* border: 1px solid #bebfc1; */
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  height: auto;
  box-shadow: #8d8a8a 0px 0px 3px;
}

.itp-edit-contact-container-custom-field:has(.itp-label-input__label:hover)
  > .itp-edit-contact-custom-field-actions,
.itp-edit-contact-custom-field-actions:hover {
  visibility: visible;
}

.itp-label-input__label.option {
  cursor: pointer;
}

.itp-label-input__label.option:hover {
  background-color: rgb(245, 245, 242);
}

.itp-label-border-bottom-property {
  border-bottom: #e3dede solid 1px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomFields/CustomFields.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,+BAA+B;EAC/B,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,+BAA+B;AACjC;;AAEA;;;EAGE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".itp-edit-contact-container-custom-field {\n  position: relative;\n}\n\n.itp-edit-contact-custom-field-actions {\n  position: absolute;\n  background-color: #ffffff;\n  width: 6.5rem;\n  visibility: hidden;\n  top: -3rem;\n  /* border: 1px solid #bebfc1; */\n  display: flex;\n  flex-direction: column;\n  border-radius: 3px;\n  height: auto;\n  box-shadow: #8d8a8a 0px 0px 3px;\n}\n\n.itp-edit-contact-container-custom-field:has(.itp-label-input__label:hover)\n  > .itp-edit-contact-custom-field-actions,\n.itp-edit-contact-custom-field-actions:hover {\n  visibility: visible;\n}\n\n.itp-label-input__label.option {\n  cursor: pointer;\n}\n\n.itp-label-input__label.option:hover {\n  background-color: rgb(245, 245, 242);\n}\n\n.itp-label-border-bottom-property {\n  border-bottom: #e3dede solid 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
