import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import { AddButton, CollapsePanel, CustomTableMain, LoadingButton, MenuRight, SearchBoxFilter } from "../../../../components";
import getQueryString from "../../../../utils/getQueryString";
import petitionGet from "../../../../services/petitionGet";
import petitionPost from "../../../../services/petitionPost";
import petitionPatch from "../../../../services/petitionPatch";
import petitionDelete from "../../../../services/petitionDelete";
import ModalDelete from "../ModalUtility/ModalDelete";
import Skeleton from "react-loading-skeleton";

const SalesLead = ({ accountDetails, loading }) => {
  const filterSearchqs = useRef("");
  const qsPagination = useRef({
    offset: 0,
    limit: 10,
  });

  const [loadingqs, setLoadingqs] = useState(false);
  const [loadingPagItems, setLoadingPagItems] = useState(false)
  const [qsAllResult, setQsAllResult] = useState({});
  const [showAddOrEditSalesLead, setShowAddOrEditSalesLead] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [showAddOrEditQuestion, setShowAddOrEditQuestion] = useState(false);
  const [questionInfo, setQuestionInfo] = useState(null);
  const [showModalDeleteModule, setShowModalDeleteModule] = useState("");
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingButtonMenu, setLoadingButtonMenu] = useState(false);
  const [searchValue, setSearchValue] = useState("")

  const [form, setForm] = useState({
    sales_name: "",
    question: "",
    description: "",
  });

  const [dataTableSalesLead, setDataTableSalesLead] = useState({
    columns: [
      { name: "Screening List", key: "name" },
      { name: "Description", key: "description" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const [dataTablequestions, setDataTableQuestions] = useState({
    columns: [{ name: "Question", key: "question" }],
    actions: { title: "Actions", content: [] }
  });

  const selectModuleModalDelete = () => {
    if (modalDelete && showModalDeleteModule === "sales") {
      return "Sale & Lead Generation"
    } else if (modalDelete && showModalDeleteModule === "question") {
      return "Question"
    }
  }

  const selectModalDeleteFunction = () => {
    if (modalDelete && showModalDeleteModule === "sales") {
      deleteSalesLead()
    } else if (modalDelete && showModalDeleteModule === "question") {
      deleteQuestion()
    }
  }

  const disabledsalesLeadButton = () => {
    if (!showAddOrEditQuestion && (!form.sales_name || loadingButtonMenu)) {
      return true;
    } else if (showAddOrEditQuestion && (!form.question || loadingButtonMenu)) {
      return true;
    } else {
      return false;
    }
  };

  const selectFunctionButtonSalesLead = () => {
    if (!showAddOrEditQuestion) {
      addOrEditSalesLead();
    } else {
      addOrEditQuestion();
    }
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const selectTitleMenu = () => {
    if (openMenu && showAddOrEditSalesLead && !showAddOrEditQuestion) {
      return "Question List";
    } else if (openMenu && showAddOrEditSalesLead && showAddOrEditQuestion) {
      return "Questions";
    } else {
      return "Error";
    }
  };

  const backToQuestionsTable = () => {
    setForm({ ...form, question: "", sales_name: info.name });
    setQuestionInfo(null);
    setShowAddOrEditQuestion(false);
    setShowModalDeleteModule("");
    setModalDelete(false);
  };

  const selectCloseMenuFunction = () => {
    if (!showAddOrEditQuestion) {
      closeMenu();
    } else {
      backToQuestionsTable();
    }
  };

  const closeMenu = () => {
    setShowModalDeleteModule("");
    setInfo(null);
    setModalDelete(false);
    setOpenMenu(false);
    setShowAddOrEditSalesLead(false);
    setForm({
      name: "",
      question: "",
      sales_name: "",
      description: "",
    });
  };

  const onChangeFilterSalesLead = async (e) => {
    if (loading || loadingqs || loadingPagItems) return;

    const { value } = e.target
    setSearchValue(value)

    const paginationTemp = { offset: 0, limit: 10 };

    const filter = !value ? "" : `&name=${value}`;

    const parameter = getQueryString(paginationTemp) + filter;

    await petitionqs(parameter);
    qsPagination.current = paginationTemp;
    filterSearchqs.current = filter;
  };

  const activeAddOrEditSalesLead = (element) => {
    setShowAddOrEditSalesLead(true);
    setOpenMenu(true);

    if (element) {
      setLoadingModal(true);

      const parameter = `?question_list_id=${element.pk}`;

      petitionGet("salesLeadQuestions", { parameter })
        .then(({ data: result }) => {
          setDataTableQuestions({
            columns: [{ name: "Question", key: "question" }],
            content: [...result.result],
            actions: {
              title: "Actions",
              content: [
                {
                  name: "Edit",
                  icon: <FontAwesomeIcon icon={faPenToSquare} />,
                  handleClick: activeAddOrEditQuestion,
                },
                {
                  name: "Delete",
                  icon: <FontAwesomeIcon icon={faTrash} />,
                  handleClick: activeDeleteQuestion,
                },
              ],
            },
          });
          setInfo(element);
          setForm({
            ...form,
            sales_name: element.name,
            description: element?.description || "",
          });
          setLoadingModal(false);
        })
        .catch((error) => console.log(error));
    }
  };

  const activeAddOrEditQuestion = (element) => {
    if (element) {
      setForm({ ...form, question: element.question });
      setQuestionInfo(element);
    }

    setShowAddOrEditQuestion(true);
  };

  const activeModalDelete = (e, module) => {
    setInfo(e);
    setShowModalDeleteModule(module);
    setModalDelete(true);
  };

  const activeDeleteQuestion = (e) => {
    setQuestionInfo(e);
    setShowModalDeleteModule("question");
    setModalDelete(true);
  };

  const qsChangePage = async (offset) => {
    if (loading || loadingqs || loadingPagItems) return;

    setLoadingPagItems(true)

    const paginationTemp = {
      offset: offset,
      limit: qsPagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp) + filterSearchqs.current;

    await petitionqs(parameter);
    qsPagination.current = paginationTemp;
  };

  const addOrEditSalesLead = () => {
    setLoadingButtonMenu(true);

    const data = {
      name: form.sales_name,
      description: form.description,
    };

    const parameter =
      getQueryString(qsPagination.current) + filterSearchqs.current;

    if (!info) {
      petitionPost("salesLead", { data })
        .then(({ data: result }) => {
          petitionqs(parameter);
        })
        .catch((error) => console.log(error));
    } else {
      petitionPatch("salesLead", { data, sale_id: info.pk })
        .then(({ data: result }) => {
          petitionqs(parameter);
        })
        .catch((error) => console.log(error));
    }
  };

  const addOrEditQuestion = () => {
    setLoadingButtonMenu(true);

    const data = {
      question: form.question,
      lead_screening_questions_list_id: info.pk,
    };

    if (!questionInfo) {
      petitionPost("salesLeadQuestions", { data })
        .then(({ data: result }) => {
          setDataTableQuestions((prevState) => {
            const newContent = [...prevState.content, result.result];

            return {
              ...prevState,
              content: [...newContent],
            };
          });
          setLoadingButtonMenu(false);
          backToQuestionsTable();
        })
        .catch((error) => console.log(error));
    } else {
      petitionPatch("salesLeadQuestions", {
        data,
        question_id: questionInfo.pk,
      })
        .then(({ data: result }) => {
          setDataTableQuestions((prevState) => {
            const newContent = prevState.content.map((element) => {
              if (element.pk === questionInfo.pk) {
                element = result.result;
              }

              return element;
            });

            return {
              ...prevState,
              content: [...newContent],
            };
          });
          setLoadingButtonMenu(false);
          backToQuestionsTable();
        })
        .catch((error) => console.log(error));
    }
  };

  const deleteSalesLead = () => {
    setLoadingModal(true)
    petitionDelete("salesLead", { sale_id: info.pk })
      .then(({ data: result }) => {
        let parameter = ""
        
        if (qsAllResult.result.length === 1 && qsPagination.current.offset > 0) {
          const paginationTemp = {
            offset: qsPagination.current.offset - qsPagination.current.limit,
            limit: 10
          }
          parameter = getQueryString(paginationTemp) + filterSearchqs.current
          qsPagination.current = paginationTemp
        } else {
          parameter = getQueryString(qsPagination.current) + filterSearchqs.current
        }

        petitionqs(parameter)
      })
      .catch((error) => console.log(error))
  }

  const deleteQuestion = () => {
    setLoadingModal(true)
    petitionDelete("salesLeadQuestions", { question_id: questionInfo.pk })
      .then(({ data: result }) => {
        setDataTableQuestions((prevState) => {
          const newContent = prevState.content.filter((element) => element.pk !== questionInfo.pk)

          return {
            ...prevState,
            content: [...newContent]
          }
        })
        setLoadingModal(false)
        backToQuestionsTable()
      })
      .catch((error) => console.log(error))
  }

  const petitionqs = async (parameter, loading) => {
    if (loading) setLoadingqs(true);
    await petitionGet("salesLead", { parameter })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.description = element?.description || "";
        });

        setQsAllResult(result);

        setDataTableSalesLead({
          columns: [
            { name: "Screening List", key: "name" },
            { name: "Description", key: "description" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeAddOrEditSalesLead,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: (e) => activeModalDelete(e, "sales"),
              },
            ],
          },
        });

        setLoadingButtonMenu(false);
        setLoadingqs(false);
        setLoadingPagItems(false)
        setLoadingModal(false);
        closeMenu();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (accountDetails?.ai_enabled) {
      petitionqs(getQueryString(qsPagination.current));
    }
  }, []);

  return (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={() => setModalDelete(false)}
          loading={loadingModal}
          modalFunction={selectModalDeleteFunction}
          module={selectModuleModalDelete()}
        />
      )}

      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        title="Sales & Lead Generation"
        subtitle="Screening Sales & Lead related options to automate the sales cycle"
      >
        <CustomTableMain
          data={dataTableSalesLead}
          loading={loading || loadingqs}
          loadingPagItems={loadingPagItems}
          isBackend={true}
          paginationData={10}
          totalPages={qsAllResult?.total_pages}
          onPageChange={qsChangePage}
          offset={qsPagination.current.offset}
          m0={true}
          actionsLeft={[
            {
              component: (
                <SearchBoxFilter 
                  placeholder="Search Sales & Lead Generation"
                  handleChange={onChangeFilterSalesLead}
                  value={searchValue}
                />
              )
            }
          ]}
          actionsRight={[
            {
              component: (
                <AddButton 
                  handleClick={() => {
                    if (loading || loadingqs || loadingPagItems) return;
                    activeAddOrEditSalesLead()
                  }}
                  name="Add Question List"
                />
              )
            }
          ]}
          allResult={qsAllResult}
          moduleNoData="aales & lead generation" 
        />
      </CollapsePanel>

      <MenuRight
        show={openMenu}
        closeMenu={selectCloseMenuFunction}
        title={selectTitleMenu()}
        to={showAddOrEditQuestion ? "Question Table" : ""}
      >
        {openMenu && showAddOrEditSalesLead && (
          <>
            {showAddOrEditQuestion ? (
              <>
                <Row className="itp-container-label-input">
                  <Col
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="itp-label-input-col"
                  >
                    <label className="itp-label-input__label">Question</label>
                  </Col>
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    className="itp-label-input-col"
                  >
                    {loadingModal ? (
                      <Skeleton height="1rem" width="100%" />
                    ) : (
                      <input
                        type="text"
                        placeholder=": Add Screening List Name"
                        className="form-control-itp itp-label-input__input"
                        name="question"
                        value={form.question}
                        onChange={handleChangeForm}
                      />
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="itp-container-label-input">
                  <Col
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="itp-label-input-col"
                  >
                    <label className="itp-label-input__label">
                      Screening List Name
                    </label>
                  </Col>
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    className="itp-label-input-col"
                  >
                    {loadingModal ? (
                      <Skeleton height="1rem" width="100%" />
                    ) : (
                      <input
                        type="text"
                        placeholder=": Add Screening List Name"
                        className="form-control-itp itp-label-input__input"
                        name="sales_name"
                        value={form?.sales_name}
                        onChange={handleChangeForm}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="itp-container-label-input">
                  <Col
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="itp-label-input-col"
                  >
                    <label className="itp-label-input__label">
                      Description
                    </label>
                  </Col>
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    className="itp-label-input-col"
                  >
                    {loadingModal ? (
                      <Skeleton height="1rem" width="100%" />
                    ) : (
                      <input
                        type="text"
                        placeholder=": Add description"
                        className="form-control-itp itp-label-input__input"
                        name="description"
                        value={form?.description}
                        onChange={handleChangeForm}
                      />
                    )}
                  </Col>
                </Row>

                {info && (
                  <>
                    <CustomTableMain
                      data={dataTablequestions}
                      loading={loadingModal}
                      module="Questions"
                      actionsRight={[
                        {
                          component: (
                            <AddButton 
                              handleClick={() => {
                                if (loadingModal || loadingButtonMenu) return;
                                activeAddOrEditQuestion()
                              }}
                               name="Add Question"
                            />
                          )
                        }
                      ]}
                    />
                  </>
                )}
              </>
            )}

            <button
              onClick={selectFunctionButtonSalesLead}
              className="btn-primary itp-lead-detail-button-save loading mb-4"
              disabled={disabledsalesLeadButton()}
            >
              {loadingButtonMenu && <LoadingButton />}
              Save
            </button>
          </>
        )}
      </MenuRight>
    </>
  );
};

export default SalesLead;
