import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { itemsMenu, secondItemsMenu } from "../const/itemsMenu";
import { Header, Sidebar, Footer } from "../components/index";
import { logoutRequest } from "../redux/actions/login";
import { defaultSidebar, hiddenSidebar } from "../redux/actions/sidebar";
import { saveDataInLocalStorage } from "../services/login.service";
import { routes } from "../const/routesComponents";
import useScreenSize from "../hooks/useScreenSize";
import NotFoundPage from "./NotFoundPage";
import petitionGet from "../services/petitionGet";
import "./index.css";

function Index({ setShowWebphone, handleActiveCall }) {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const currentPath = useRef(null);
  const params = new URL(document.location).searchParams;
  const token = params.get("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { width } = useScreenSize();

  const container1Ref = useRef(null);
  const container2Ref = useRef(null);

  const toggleMenu = useSelector((state) => state.sidebar.isDefault);
  const showHeader = useSelector((state) => state.header.show);
  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const publicRoutes = ["login", "reset-pw", "Change-Password", "/"]
  
  const [isPublic, setIsPublic] = useState(false);
  const [activeMenuItems, setActiveMenuItems] = useState(itemsMenu);

  const onLogout = () => {
    dispatch(logoutRequest());
  };

  const handleToggle = () => {
    if (toggleMenu) {
      dispatch(defaultSidebar());
    } else {
      if (myExtension && Object.keys(myExtension).length > 0) {
        dispatch(hiddenSidebar());
      }
    }
  };

  const handleMenuSwitch = () => {
    if (activeMenuItems === itemsMenu) {
      setActiveMenuItems(secondItemsMenu);
    } else {
      setActiveMenuItems(itemsMenu);
    }
  };

  useEffect(() => {
    if (path !== currentPath.current || window.location.search) {
      if (
        localStorage.getItem("userDetails") !== null &&
        myExtension &&
        Object.keys(myExtension).length > 0
      ) {
        petitionGet("verify")
          .then(({ data: result }) => {
            currentPath.current = path;
          })
          .catch((error) => {
            console.log(error.response);
            if (error?.response?.status === 401) {
              onLogout();
            }
          });
      }
    }
  }, [path, window.location.search, myExtension]);

  useEffect(() => {
    routes.forEach((element) => {
      if (
        window.location.pathname === "/" + element.url &&
        element.public === true
      ) {
        setIsPublic(true);
      }
    });

    const container1 = container1Ref.current;
    const container2 = container2Ref.current;

    if (container1 && container2) {
      const heightContainer1 = container1.offsetHeight;
      const heightContainer2 = `calc(100% - 0px)`;

      container2.style.height = heightContainer2;
    }

    const autoRefresh = () => {
      const token = JSON.parse(
        localStorage.getItem("userDetails")
      ).access_token;

      petitionGet("refresh", token)
        .then(({ data: result }) => {
          let dataLocalStorage = JSON.parse(
            localStorage.getItem("userDetails")
          );
          result.result.api_id = dataLocalStorage.api_id;
          saveDataInLocalStorage(result.result);
        })
        .catch((error) => {
          console.log(error.response);
          if (error?.response?.status === 401) {
            onLogout();
          }
        });
    };

    setInterval(autoRefresh, 60000);

    if (width < 991) {
      dispatch(defaultSidebar());
    }
  }, []);

  if (localStorage.getItem("userDetails") !== null) {
    return (
      <>
      
        <div
          className={`itp-page-container ${
            toggleMenu && !showHeader ? "mt-0" : ""
          }`}
        >
          {!isPublic && (
            <Sidebar
              items={itemsMenu}
              navigate={navigate}
              refSidebar={container2Ref}
              toggleMenu={toggleMenu}
              onUserSettingsClick={handleMenuSwitch}
            />
          )}
          <div
            className={`${
              !isPublic && !toggleMenu ? "itp-width-sidebar" : "itp-width-100"
            }`}
          >
            {/* Headers: */}

            {!isPublic && showHeader && (
                      <Header
                        refHEader={container1Ref}
                        handleToggle={handleToggle}
                        toggleMenu={toggleMenu}
                        setShowWebphone={setShowWebphone}
                      />
                    )}


            <Routes>
              {!token &&  (
                <>
                  {publicRoutes.map((route) => (
                     <Route
                       key={route}
                       path={route}
                       element={<Navigate to="/Dashboard" replace />}
                     />
                  ))}

                  {routes.map((data) => (
                    <Route
                      key={data.url}
                      path={`/${data.url}`}
                      element={<data.component handleActiveCall={handleActiveCall} />}
                    />
                  ))}

                  {/* Route 404 - Catch-all */}
                  <Route path="*" element={<NotFoundPage />} />
                </>
              )}
            </Routes>
            {/* {!isPublic && <Footer />} */}
          </div>
        </div>
      </>
    );
  }
}

function App({ setShowWebphone, handleActiveCall }) {
  return (
    <Router>
      {}
      <Index
        setShowWebphone={setShowWebphone}
        handleActiveCall={handleActiveCall}
      />
    </Router>
  );
}

export default App;