import call from "../../../../../../assets/icons/call.svg"
import callIncoming from "../../../../../../assets/icons/callIncoming.svg"
import callOutgoing from "../../../../../../assets/icons/callOutgoing2.svg"

const selectCallImage = (element) => {
  const isCallEventType = element?.last_event_type === "call" ? true : false
  const eventMetadata = element?.event_metadata
  const callDirection = eventMetadata?.call_direction

  if (isCallEventType && (!eventMetadata || !callDirection)) {
    return <img src={call} alt="Call" className="last-message-icons" />
  }

  if (isCallEventType && callDirection === "inbound") {
    return <img src={callIncoming} alt="Call" className="last-message-icons" />
  }

  if (isCallEventType && callDirection === "outbound") {
    return <img src={callOutgoing} alt="Call" className="last-message-icons" />
  }

  return ""
}

export default selectCallImage;