import React, { useState } from "react";
import AddContactAndLead from "./options/AddContactAndLead";
import AddLeadOnly from "./options/AddLeadOnly";
import "./AddLead.css";

const AddLead = ({
  loadingAddLead,
  addNewContactAndLead,
  addNewLead,
  defaultContactWithParameter,
  errorAddContact,
}) => {
  const [optionButton, setOptionButton] = useState(!defaultContactWithParameter ? "" : "existingContact");
  const [addLead, setAddLead] = useState("");

  const handleChange = (e) => {
    setAddLead(e.target.value);
  };

  const selectAddContact = () => {
    setOptionButton(addLead);
    setAddLead("");
  };

  return (
    <>
      {optionButton === "" && (
        <>
          <label className="itp-select-add-lead-label">
            Add a lead through
          </label>
          <br />
          <select
            onChange={handleChange}
            className="form-select-itp itp-add-lead-select"
          >
            <option value="">Select Option</option>
            <option value="newContact">Add New Contact & Lead</option>
            <option value="existingContact">
              Add existing contact & new lead
            </option>
          </select>
          <button
            disabled={addLead === "" ? true : false}
            className="btn-primary itp-lead-button"
            onClick={selectAddContact}
          >
            Next
          </button>
        </>
      )}

      {optionButton === "newContact" && (
        <AddContactAndLead
          loadingAddLead={loadingAddLead}
          addNewContactAndLead={addNewContactAndLead}
          errorAddContact={errorAddContact}
        />
      )}

      {optionButton === "existingContact" && (
        <AddLeadOnly 
          loadingAddLead={loadingAddLead} 
          addNewLead={addNewLead}    
          errorAddLead={errorAddContact}
        />
      )}
    </>
  );
};

export default AddLead;
