// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-custom-table-main-thead-styles {
  background: #f3f2f7 0% 0% no-repeat padding-box !important;
  border-radius: 8px 8px 0px 0px;
  opacity: 0.85;
  transition: all 0.2s ease;
}

.form-check-input.itp-custom-table-main-check-header {
  width: 1rem;
  height: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomTableMain/Components/TableHeader/TableHeader.css"],"names":[],"mappings":"AAAA;EACE,0DAA0D;EAC1D,8BAA8B;EAC9B,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".itp-custom-table-main-thead-styles {\n  background: #f3f2f7 0% 0% no-repeat padding-box !important;\n  border-radius: 8px 8px 0px 0px;\n  opacity: 0.85;\n  transition: all 0.2s ease;\n}\n\n.form-check-input.itp-custom-table-main-check-header {\n  width: 1rem;\n  height: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
