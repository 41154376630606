// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-dots span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 0 0 2px;
  background-color: #6d28d9;
  border-radius: 50%;
  animation: loadingDots 1s infinite ease-in-out;
}

@keyframes loadingDots {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.loading-dots span:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-dots span:nth-child(2) {
  animation-delay: -0.16s;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoadingDots/LoadingDots.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,8CAA8C;AAChD;;AAEA;EACE;;;IAGE,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".loading-dots {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.loading-dots span {\n  display: inline-block;\n  width: 12px;\n  height: 12px;\n  margin: 0 0 0 2px;\n  background-color: #6d28d9;\n  border-radius: 50%;\n  animation: loadingDots 1s infinite ease-in-out;\n}\n\n@keyframes loadingDots {\n  0%,\n  80%,\n  100% {\n    transform: scale(0);\n  }\n  40% {\n    transform: scale(1);\n  }\n}\n\n.loading-dots span:nth-child(1) {\n  animation-delay: -0.32s;\n}\n\n.loading-dots span:nth-child(2) {\n  animation-delay: -0.16s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
