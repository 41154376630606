// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-time-groups-details-title {
  font-size: 14px !important;
  text-align: left !important;
  letter-spacing: 0px !important;
  color: #3a3a3acc !important;
  opacity: 0.8 !important;
}

.itp-time-groups-details-subtitle {
  text-align: left !important;
  font: normal normal 600 24px/32px Instrument Sans !important;
  font-size: 24px !important;
  letter-spacing: 0px !important;
  color: #3a3a3a !important;
  opacity: 1 !important;
  margin-bottom: 0 !important;
}

.itp-time-groups-details-subtitle.time-zone {
  font-size: 14px !important;
}

.itp-add-date-time-select {
  appearance: none;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 8px 8px;
  padding-right: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/AdvancedCallFlows/components/TimeGroupsDetails/TimeGroupsDetails.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,8BAA8B;EAC9B,2BAA2B;EAC3B,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;EAC3B,4DAA4D;EAC5D,0BAA0B;EAC1B,8BAA8B;EAC9B,yBAAyB;EACzB,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,yDAAiR;EACjR,4BAA4B;EAC5B,yCAAyC;EACzC,wBAAwB;EACxB,kBAAkB;AACpB","sourcesContent":[".itp-time-groups-details-title {\n  font-size: 14px !important;\n  text-align: left !important;\n  letter-spacing: 0px !important;\n  color: #3a3a3acc !important;\n  opacity: 0.8 !important;\n}\n\n.itp-time-groups-details-subtitle {\n  text-align: left !important;\n  font: normal normal 600 24px/32px Instrument Sans !important;\n  font-size: 24px !important;\n  letter-spacing: 0px !important;\n  color: #3a3a3a !important;\n  opacity: 1 !important;\n  margin-bottom: 0 !important;\n}\n\n.itp-time-groups-details-subtitle.time-zone {\n  font-size: 14px !important;\n}\n\n.itp-add-date-time-select {\n  appearance: none;\n  background-image: url(\"data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e\");\n  background-repeat: no-repeat;\n  background-position: right 0.75rem center;\n  background-size: 8px 8px;\n  padding-right: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
