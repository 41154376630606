import fetchListCallHistory from "./fetchListCallHistory/fetchListCallHistory";
import downloadCallService from "./downloadCallService/downloadCallService";
import contactDetailsService from "./contactDetailsService/contactDetailsService";
import editContactService from "./editContactService/editContactService";

export {
  fetchListCallHistory,
  downloadCallService,
  contactDetailsService,
  editContactService,
};
