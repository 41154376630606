import petitionGet from "../../../../../services/petitionGet";
import { processListCallHistory } from "../../utils";

const fetchListCallHistory = async (timeFrame, myExtension) => {
  const parameter = `?start_date=${timeFrame.startDate}&end_date=${timeFrame.endDate}`
  const { data: result } = await petitionGet("callHistory", { parameter })

  const listCallHistory = result?.result?.user_reports[0]?.all_calls || []
  const allCalls = result?.result?.user_reports[0]?.total_calls
  const allResult = result?.result?.user_reports[0]

  const processedListCallHistory = processListCallHistory(listCallHistory, myExtension)
    
  return { processedListCallHistory, allCalls, allResult }
}

export default fetchListCallHistory;