import React from "react";
import { Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./DateReportCalls.css";
import Skeleton from "react-loading-skeleton";

const DateReportCalls = ({
  reportDateText,
  from,
  to,
  totalCallsText,
  totalCalls,
  loadingTotalCalls,
}) => (
  <div className="d-flex gap-2 flex-wrap">
    <Card className="itp-date-report-card-data">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <span className="itp-date-report-date">{reportDateText}</span>
          <div className="d-flex align-items-center">
            <span className="itp-date-report-range-date">From : {from}</span>
            <FontAwesomeIcon
              className="itp-date-report-arrow-separator"
              icon={faArrowRight}
            />
            <span className="itp-date-report-range-date">To : {to}</span>
          </div>
        </div>
      </CardBody>
    </Card>
    <Card
      className={`itp-date-report-card-data ${totalCallsText ? "" : "d-none"}`}
    >
      <CardBody>
        <div className="d-flex align-items-center">
          <span className="itp-date-report-date">{totalCallsText}</span>
          {loadingTotalCalls ? (
            <Skeleton width="2rem" height="1rem" />
          ) : (
            <span className="itp-date-report-range-date">{totalCalls}</span>
          )}
        </div>
      </CardBody>
    </Card>
  </div>
);

export default DateReportCalls;
