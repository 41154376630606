import React from "react"

const ClickColumn = ({ element, config, column }) => {
  const handleClick = (event) => {
    event.stopPropagation()
    config.handleClick(element)
  }

  return (
    <div onClick={handleClick}>
      {config?.icon || ""} {element[column.key]}
    </div>
  )
}

export default ClickColumn;