import axios from "axios";
import json from "../config.json";

export default function petitionDelete(key, value) {
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;
  var url;

  if (token) {
    switch (key) {
      case "contact":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/contacts/${value.contact_id}`;
        break;
      case "deleteNote":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/contact/notes/${value.note_id}`;
        break;
      case "contactList":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/contact-lists/${value.contact_list_id}`;
        break;
      case "tag":
        url = urlBase + `/360/accounts/${id}/my-account/tags/${value.tag_id}`;
        break;
      case "lead":
        url = urlBase + `/360/accounts/${id}/my-account/leads/${value.lead_id}`;
        break;
      case "funnel":
        url = urlBase + `/360/accounts/${id}/funnels/${value.funnel_id}`;
        break;
      case "stage":
        url =
          urlBase +
          `/360/accounts/${id}/funnels/${value.funnel_id}/stages/${value.stage_id}`;
        break;
      case "deleteThreadSms":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/chat/sms/${value.number}/${value.smsId}`;
        break;
      case "deleteMessageSms":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/chat/sms/${value.number}/${value.message_thread_id}/${value.smsId}`;
        break;
      case "intercom":
        url = urlBase + `/itpvoice/v2/${id}/my-extension/callflow/${value.pk}`;
        break;
      case "apiKey":
        url = urlBase + `/itpvoice/v2/${id}/apikeys/${value.api_key_id}`;
        break;
      case "webhook":
        url = urlBase + `/itpvoice/v2/${id}/webhooks/${value.webHookId}`;
        break;
      case "brands":
        url = urlBase + `/itpvoice/v2/${id}/brands/${value.brand_id}`;
        break;
      case "campaign":
        url = urlBase + `/itpvoice/v2/${id}/csp-campaign/${value.brand_id}`;
        break;
      case "number":
        url = urlBase + `/itpvoice/v2/${id}/numbers/${value.phone}`;
        break;
      case "deleteTimeRules":
        url = urlBase + `/itpvoice/v2/${id}/time-rules/${value.time_id}`;
        break;
      case "callFlow":
        url = urlBase + `/itpvoice/v2/${id}/callflows/${value.callflow_id}`;
        break;
      case "timeGroup":
        url = urlBase + `/itpvoice/v2/${id}/time-groups/${value.time_id}`;
        break;
      case "timeRule":
        url = urlBase + `/itpvoice/v2/${id}/time-rules/${value.time_id}`;
        break;
      case "user":
        url = urlBase + `/itpvoice/v2/${id}/users/${value.user_id}`;
        break;
      case "queue":
        url = urlBase + `/itpvoice/v2/${id}/queues/${value.queue_id}`;
        break;
      case "queueMember":
        url =
          urlBase +
          `/itpvoice/v2/${id}/queues/${value.queue_id}/members/${value.member_id}`;
        break;
      case "switch":
        url = urlBase + `/itpvoice/v2/${id}/switches/${value.switch_id}`;
        break;
      case "messageTemplates":
        url =
          urlBase +
          `/360/accounts/${id}/message-templates/${value.message_template_id}`;
        break;
      case "leadSource":
        url =
          urlBase + `/360/accounts/${id}/leads/sources/${value.source_lead_id}`;
        break;
      case "blockedCall":
        url = urlBase + `/itpvoice/v2/${id}/blocked-calls/${value.pk}`;
        break;
      case "blockedCountries":
        url =
          urlBase + `/itpvoice/v2/${id}/blocked-countries/${value.country_id}`;
        break;
      case "mediaFile":
        url =
          urlBase +
          `/itpvoice/v2/${id}/media-groups/${value.media_group_id}/${value.filename}`;
        break;
      case "operationPanelGroups":
        url =
          urlBase +
          `/itpvoice/v2/${id}/operator-panel/groups/${value.group_id}`;
        break;
      case "basicModeCallFlowNumber":
        url =
          urlBase +
          `/itpvoice/v2/${id}/numbers/${value.number}/default-callflow`;
        break;
      case "voicemail":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/voicemail-messages/${value.media_id}`;
        break;
      case "customField":
        url =
          urlBase +
          `/360/accounts/${id}/contacts/custom-fields/${value.custom_field_id}`;
        break;
      case "undoContacsInJob":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/import-jobs/${value.job_id}/contacts`;
        break;
      case "smartCampaign":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/smart-campaigns/${value.campaign_id}`;
        break;
      case "pinChat":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/chat/sms/${value.number}/${value.chat_id}/pin-thread`;
        break;
      case "unarchive":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/chat/sms/${value.number}/${value.chat_id}/archive-thread`;
        break;
      case "optOutKeyword":
        url =
          urlBase + `/itpvoice/v2/${id}/opt-out-keywords/${value?.keyword_id}`;
        break;
      case "salesLead":
        url = urlBase + `/ai/accounts/${id}/question-list/${value?.sale_id}`;
        break;
      case "faqs":
        url = urlBase + `/ai/accounts/${id}/faqs/${value?.faqs_id}`;
        break;
      case "salesLeadQuestions":
        url =
          urlBase +
          `/ai/accounts/${id}/screening-question/${value?.question_id}`;
        break;
      case "aiFollowUpSchedule":
        url =
          urlBase +
          `/ai/accounts/${id}/follow-up/schedule/${value?.schedule_id}`;
        break;
      case "allVoicemails":
        url = urlBase + `/itpvoice/v2/${id}/my-extension/bulk/voice-messages`;
        break;
      case "closingAccount":
        url = urlBase + `/itpvoice/v2/${id}/cancel-service`;
        break;
      case "followupTasks":
        url = urlBase + `/ai/${id}/my-extension/chat/sms/${value.number}/${value.threadID}/followup-tasks${value?.parameter || ""}`;
        break;
      case "aiAgent":
        url = urlBase + `/ai/accounts/${id}/ai_agent/${value.aiAgentID}`;
        break;
      case "disabledIntegration":
        url = urlBase + `/integrations/accounts/${id}/customer-integrations/${value.integrationId}`;
        break;
      case "mediaGroup":
        url = urlBase + `/itpvoice/v2/${id}/media-groups/${value.media_group_id}`;
        break;
      default:
        return "error";
    }

    var config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };

    return axios.delete(url, config);
  }
}
