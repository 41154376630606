// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-custom-table-main-row {
  visibility: visible;
}

.itp-custom-table-main-row:hover {
  cursor: pointer;
  background-color: rgba(67, 112, 151, 0.2);
}

.itp-custom-table-main-td-styles {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomTableMain/Components/TableRow/TableRow.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,yCAAyC;AAC3C;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".itp-custom-table-main-row {\n  visibility: visible;\n}\n\n.itp-custom-table-main-row:hover {\n  cursor: pointer;\n  background-color: rgba(67, 112, 151, 0.2);\n}\n\n.itp-custom-table-main-td-styles {\n  text-align: left;\n  font-size: 14px;\n  letter-spacing: 0px;\n  color: #3a3a3acc;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
