import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton, Loading, SelectComponent } from "../../../../../components";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionDelete from "../../../../../services/petitionDelete";
import "../AddLead.css";

const AddLeadOnly = ({ loadingAddLead, addNewLead, errorAddLead }) => {
  const [valueLeadSource, setValueLeadSource] = useState(null);
  const [valueFunnel, setValueFunnel] = useState(null);
  const [valueStage, setValueStage] = useState(null);
  const [stages, setStages] = useState([]);
  const [loadingChangeFunnel, setLoadingChangeFunnel] = useState(false);
  const [showAddLeadSource, setShowAddLeadSource] = useState(false);
  const [form, setForm] = useState({
    lead_source_id: "",
    funnel_id: "",
    funnel_pipeline_stage_id: "",
    contact_id: "",
  });
  const [newLeadSource, setNewLeadSource] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [showdeleteLeadSource, setShowdeleteLeadSource] = useState(false);
  const [infoToDelete, setInfoToDelete] = useState(null);

  const onChangeFunnel = (e) => {
    setLoadingChangeFunnel(true);
    petitionGet("stages", { parameter: `?funnel_id=${e.pk}` })
      .then(({ data: result }) => {
        if (result.result.length > 0) {
          result.result.forEach((stage) => {
            stage.label = stage.name;
            stage.value = stage.pk;
          });

          setStages(result.result);
        }
        setForm({ ...form, funnel_id: e.pk, funnel_pipeline_stage_id: "" });
        setValueFunnel(e);
        setValueStage(null);
        setLoadingChangeFunnel(false);
      })
      .catch((error) => console.log(error));
  };

  const loadOptions = (inputValue, callback) => {
    petitionGet("contacts", {
      parameter: `?search_query=${inputValue}`,
    })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.firstname} ${element.lastname || ""}`;
          element.value = element.pk;
        });

        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadOptionFunnel = (inputValue, callback) => {
    petitionGet("funnels", { parameter: inputValue ? `?name=${inputValue}` : "?offset=0&limit=10" })
      .then(({ data: result }) => {
        result.result.forEach((funnel) => {
          funnel.label = funnel?.name || "";
          funnel.value = funnel?.pk || null; 
        });

        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addLeadSource = () => {
    setLoadingButton(true);
    petitionPost("leadSource", { data: { name: newLeadSource } })
      .then(({ data: result }) => {
        setShowAddLeadSource(false);
        setShowdeleteLeadSource(false);
        setLoadingButton(false);
        setValueLeadSource(result.result)
        setForm({ ...form, lead_source_id: result.result.value });

      })
      .catch((error) => console.log(error));
  };

  const back = () => {
    setShowAddLeadSource(false);
    setShowdeleteLeadSource(false);
    setInfoToDelete(null)
  };

  const deleteLeadSource = () => {
    setLoadingButton(true);
    petitionDelete("leadSource", {
      source_lead_id: infoToDelete?.pk,
    })
      .then(({ data: result }) => {
        setLoadingButton(false);
        back();
        setValueLeadSource(null)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const stopFunction = (e, info) => {
    setShowdeleteLeadSource(true);
    setInfoToDelete(info)
  };

  const loadOptionsSource = (inputValue, callback) => {
    petitionGet("leadSource", { parameter: inputValue ? `?name=${inputValue}` : "?offset=0&limit=10" })
      .then(({ data: result }) => {
        const leadSource = result.result

        leadSource.forEach((element) => {
          if (element.value !== "add") {
            if (element.list_name !== "Default") {
              element.icon = <FontAwesomeIcon icon={faTrash}/>;
            }
          }
    
          element.label = element.name
          element.value = element.pk
        });
    
        const existAdd = leadSource.some(objeto => objeto.value === 'add')
    
        if (!existAdd && leadSource?.length > 0) {
          leadSource.unshift({
            label: "Add New Lead Source",
            value: "add",
          });
        }
    
        callback(leadSource);
      })
      .catch((error) => console.log(error))

  };

  const handleChangeSelectComponent = (e) => {
    if (e.value === "add") {
      setShowAddLeadSource(true);
    } else {
      setForm({ ...form, lead_source_id: e.pk });
      setValueLeadSource(e);
    }
  }

  return (
    <>
      <div className="add-lead-container-field">
        <label className="itp-label-input__label">Contact</label>
        <AsyncSelect
          onChange={(e) => setForm({ ...form, contact_id: e.value })}
          className="basic-single add-lead-select"
          classNamePrefix="select"
          loadOptions={loadOptions}
          isSearchable={true}
          name="contact_id"
          placeholder="Choose contact"
          defaultOptions
          cacheOptions
        />
      </div>

      {!showAddLeadSource && !showdeleteLeadSource && (
        <div className="add-lead-container-field">
          <label className="itp-label-input__label">Lead Source</label>
          <SelectComponent
            onChange={handleChangeSelectComponent}
            defaultValue={valueLeadSource}
            isSearchable={true}
            name="lead_source_id"
            placeholder="Choose lead source"
            includeOption={true}
            functionOption={stopFunction}
            asyncSelectOptions={true}
            loadOptions={loadOptionsSource}
            className="add-lead-select"
          />
        </div>

      )}

      {showAddLeadSource && !showdeleteLeadSource && (
        <div className="add-lead-container-field">
          <label className="itp-label-input__label">Lead Source Name List</label>
          <div className="label-input-add-contact-list">
            <input
              onChange={(e) => setNewLeadSource(e.target.value)}
              name="lead_source"
              className="form-control-itp input-add-contact-list"
              type="text"
            />
          </div>
          <div className="add-contact-list-buttons">
            <button
              disabled={loadingButton}
              className="btn-light"
              onClick={back}
            >
              Cancel
            </button>
            <button
              // disabled={loadingButton || form.list_name === ""}
              disabled={loadingButton}
              className="btn-primary loading"
              onClick={addLeadSource}
            >
              {loadingButton && <LoadingButton />}
              Add
            </button>
          </div>
        </div>
      )}

      {!showAddLeadSource && showdeleteLeadSource && (
        <div className="add-lead-container-field">
          <label className="itp-label-input__label">Are You Sure?</label>
          <div className="add-contact-list-buttons">
            <button
              disabled={loadingButton}
              className="btn-light"
              onClick={back}
            >
              Cancel
            </button>
            <button
              disabled={loadingButton}
              className="btn-primary loading"
              onClick={deleteLeadSource}
            >
              {loadingButton && <LoadingButton />}
              Delete
            </button>
          </div>
        </div>
      )}

      <div className="add-lead-container-field">
        <label className="itp-label-input__label">Funnel</label>
        <AsyncSelect
          onChange={(e) => {
            setForm({ ...form, funnel_pipeline_stage_id: e.value });
            onChangeFunnel(e);
          }}
          className="basic-single add-lead-select"
          classNamePrefix="select"
          value={valueFunnel}
          isSearchable={true}
          name="funnel_id"
          placeholder="Choose a funnel"
          loadOptions={loadOptionFunnel}
          defaultOptions
          cacheOptions
        />
      </div>

      {loadingChangeFunnel ? (
        <Loading />
      ) : (
        <>
          {form.funnel_id && (
            <>
              {stages.length > 0 ? (
                <div className="add-lead-container-field">
                  <label className="itp-label-input__label">Funnel Stage</label>
                  <Select
                    onChange={(e) => {
                      setForm({ ...form, funnel_pipeline_stage_id: e.value });
                      setValueStage(e);
                    }}
                    className="basic-single add-lead-select"
                    classNamePrefix="select"
                    value={valueStage}
                    isSearchable={true}
                    name="funnel_pipeline_stage_id"
                    placeholder="Choose funnel stage"
                    options={stages}
                  />
                </div>
              ) : (
                <p>There are no stages created for this funnel</p>
              )}
            </>
          )}
        </>
      )}

      {errorAddLead && (
        <p className="text-danger">{errorAddLead}</p>
      )}

      <button
        className="btn-primary loading add-lead-new-contact"
        disabled={
          !form.lead_source_id ||
          !form.funnel_id ||
          !form.funnel_pipeline_stage_id ||
          !form.contact_id
        }
        onClick={() => addNewLead(form)}
      >
        {loadingAddLead && <LoadingButton />}
        Save
      </button>
    </>
  );
};

export default AddLeadOnly;
