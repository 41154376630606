// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-ai-select {
  width: 100%;
  margin-top: -1.2rem;
}

.ai-company-title {
  color: #333333;
  font-family: "Instrument Sans", sans-serif;
  font-size: 16px;
  text-align: left;
}

.ai-company-descp {
  color: #9e9e9e;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  font-family: "Instrument Sans", sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}

.w-100.ai-textarea {
  resize: none;
}

.itp-custom-table-container-search-input.centered.ai-search {
  margin-left: 30px !important;
}

.itp-table-container-centered.ai-table {
  margin-top: -2rem;
}

.itp-ai-menu-questions-table-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -1rem;
}

.btn-primary.itp-ai-menu-questions-table-button {
  width: 8rem;
}

.itp-ai-menu-questions-table-container {
  /* overflow: hidden auto; */
  margin-left: -1.8rem;
}

.itp-container-label-select.itp-ai-followup__addSchedle-container {
  margin-top: -2rem !important;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/AI/AI.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,0CAA0C;EAC1C,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,0CAA0C;EAC1C,mBAAmB;EACnB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,oBAAoB;AACtB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,oBAAoB;AACtB;;AAEA;EACE,4BAA4B;EAC5B,eAAe;AACjB","sourcesContent":[".itp-ai-select {\n  width: 100%;\n  margin-top: -1.2rem;\n}\n\n.ai-company-title {\n  color: #333333;\n  font-family: \"Instrument Sans\", sans-serif;\n  font-size: 16px;\n  text-align: left;\n}\n\n.ai-company-descp {\n  color: #9e9e9e;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 19px;\n  font-family: \"Instrument Sans\", sans-serif;\n  letter-spacing: 0px;\n  opacity: 1;\n  text-align: left;\n}\n\n.w-100.ai-textarea {\n  resize: none;\n}\n\n.itp-custom-table-container-search-input.centered.ai-search {\n  margin-left: 30px !important;\n}\n\n.itp-table-container-centered.ai-table {\n  margin-top: -2rem;\n}\n\n.itp-ai-menu-questions-table-button-container {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: -1rem;\n}\n\n.btn-primary.itp-ai-menu-questions-table-button {\n  width: 8rem;\n}\n\n.itp-ai-menu-questions-table-container {\n  /* overflow: hidden auto; */\n  margin-left: -1.8rem;\n}\n\n.itp-container-label-select.itp-ai-followup__addSchedle-container {\n  margin-top: -2rem !important;\n  flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
