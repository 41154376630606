// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.device-emulator-container {
  align-items: center;
}

.mount {
  margin-top: 5rem;
}

.phone {
  width: 300px;
  height: 650px;
  border: solid black 2px;
  border-radius: 36px;
  padding: 20px;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.5);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 3rem;
}

.screen {
  height: 80%;
  background-color: #f2f2f2;
  border-radius: 24px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.message {
  background-color: lightgrey;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  height: auto;
  max-height: 30rem;
  overflow: auto;
}

.input-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 12px;
}

.text-input {
  width: 70%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid grey;
}

.send-button {
  background-color: blue;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/SmartCampaigns/AddCampaign/Steps/Steps.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,wCAAwC;EACxC,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".device-emulator-container {\n  align-items: center;\n}\n\n.mount {\n  margin-top: 5rem;\n}\n\n.phone {\n  width: 300px;\n  height: 650px;\n  border: solid black 2px;\n  border-radius: 36px;\n  padding: 20px;\n  box-shadow: 5px 5px 15px rgba(0,0,0,0.5);\n  background-color: white;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  margin-left: 3rem;\n}\n\n.screen {\n  height: 80%;\n  background-color: #f2f2f2;\n  border-radius: 24px;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.message {\n  background-color: lightgrey;\n  padding: 10px;\n  border-radius: 10px;\n  margin: 5px 0;\n  height: auto;\n  max-height: 30rem;\n  overflow: auto;\n}\n\n.input-area {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 10px;\n  gap: 12px;\n}\n\n.text-input {\n  width: 70%;\n  padding: 10px;\n  border-radius: 20px;\n  border: 1px solid grey;\n}\n\n.send-button {\n  background-color: blue;\n  color: white;\n  padding: 10px 20px;\n  border: none;\n  border-radius: 20px;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
