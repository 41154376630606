import petitionGet from "../../../../../../services/petitionGet";

const searchQuestionListService = async (inputValue) => {
  try {
    const parameter = inputValue ? `?name=${inputValue}` : "?offset=0&limit=10";
    const { data: result } = await petitionGet("salesLead", { parameter });

    result.result.forEach((element) => {
      element.label = element.name;
      element.value = element.pk;
    });

    return result.result;
  } catch (error) {
    console.log(error);
  }
};

export default searchQuestionListService;
