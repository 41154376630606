import petitionGet from "../../../../services/petitionGet";

const fetchTags = async (query, setLoading, setOptionsTags, updateAlertVariables, setFirstFocus, setDefaultTags) => {
  try {
    setLoading(true);

    const { data: result } = await petitionGet("tags", { parameter: query || "" });

    result.result.forEach((element) => {
      element.title = element.name;
      element.disabled = false;
      element.label = element.name;
      element.value = element.pk;
    });

    if (setDefaultTags) setDefaultTags(result.result)

    setOptionsTags(result.result);
    setLoading(false);
  } catch (error) {
    console.log(error);
    const message = "An error has ocurred, please try again";
    updateAlertVariables("error", "Error", message, 4000);

    if (setFirstFocus) setFirstFocus(true)

    setLoading(false);
  }
};

export default fetchTags;
