import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";
import { AlignedRow, PaginationITP, SelectSingleCheckbox } from "../index";
import { Actions, ResponsiveTable, Table } from "./Components";
import PropTypes, { bool } from 'prop-types';
import usePagination from "./Hooks/usePagination";
import useCallStatus from "./Hooks/useCallStatus";
import 'simplebar/dist/simplebar.min.css';
import SimpleBar from 'simplebar-react';
import "./CustomTableMain.css";

const CustomTableMain = ({
  data,
  setData,
  centered,
  paginationData,
  isBackend,
  totalPages,
  onPageChange,
  offset,
  loading,
  module,
  noDataMessage,
  showSelectSingleCheckbox,
  onChangeItemsPerPage,
  actionsLeft,
  actionsRight,
  title,
  subTitle,
  showTitleCentered,
  withCheckboxes,
  externalSelectedRows,
  onSelectionChange,
  allRows,
  allowSelectAll,
  selectedAllRows,
  handleSelectAllClick,
  getBorderColor,
  getTextColor,
  borderColumnName,
  colorColumnName,
  columnConfig,
  clickRow,
  defaultOrderColumn,
  columnNames,
  changeColumnOrder,
  m0,
  m3,
  noShowPagination,
  style,
  loadingIcon,
  titleInCard,
  subtitleInCard,
  loadingPagItems,
  noCenterTitleCard,
  actionsInsideCard,
  customCoponent,
  responsive,
  responsiveActionsClassMenu,
}) => {
  // usePagination manages the frontend and backend pagination of the table data.
  // Inputs:
  // - data.content: rows of the table to paginate.
  // - paginationData: maximum number of rows per page.
  // - isBackend: Indicates whether paging is handled in the backend.
  // - totalPages: Total pages, provided by the backend
  // - onPageChange: Function to handle page changes in the backend
  // - offset: indicates the initial offset to know the page number.

  // Outputs:
  // - currentData: rows corresponding to the current page.
  // - currentPage: current page number.
  // - setCurrentPage: function to change the current page, used in pagination.
  // - maxPage: total number of pages available, calculated from the total number of rows or provived by the backend.
  const { currentData, currentPage, setCurrentPage, maxPage } = usePagination(
    data?.content,
    paginationData,
    isBackend,
    totalPages,
    onPageChange,
    offset,
    loading,
  );

  // useCallStatus handles the logic to display the status of a call (Doesn't work well with 
  // pagination through frontend, best to show all rows)
  // Inputs:
  // - currentData: rows corresponding to the current page.
  // - setData: updates the data to be displayed in the table.
  // - columnConfig: contains the key that indicates the column to which the call status will be applied.
  
  // Outputs:
  // - renderCallStatus: feature that updates call status and call time.
  const renderCallStatus = useCallStatus(currentData, setData, columnConfig);


  // Set the size of the columns based on the `centered` prop.
  // For lg, xl and xxl sizes, a size of 6 is used if `centered` is true to center the content,
  // otherwise, it expands to 12 occupying the full width. Smaller sizes are always full width.
  const size = {
    lg: centered ? 10 : 12,
    xl: centered ? 10 : 12,
    xxl: centered ? 10 : 12,
  };

  // Pagination options
  const optionsPagination = [
    { value: "10", label: "10" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
  ];

  //main class to Row component
  const rowContainerClassName = `itp-custom-table-main-container ${m0 ? "margin-0" : ""} ${m3 ? "margin-3" : ""} ${
    centered ? "justify-content-center" : ""
  }`;

  // Memorizes the click management function to select/deselect and avoid unnecessary re-renders
  const memoizedHandleSelectAllClick = useCallback(() => handleSelectAllClick(), [handleSelectAllClick]);

  return (
    <>
      <Row style={style} className={rowContainerClassName}>
        <Col xs={12} sm={12} md={12} lg={size.lg} xl={size.xl} xxl={size.xxl}>
          {title && (
            <h1 className={`itp-custom-table-main-title ${showTitleCentered ? "text-center" : ""}`}>
              {title}
            </h1>
          )}

          {subTitle && (
            <p className="itp-custom-table-main-label">
              {subTitle}
            </p>
          )}
        </Col>
      </Row>

      <Row style={style} className={rowContainerClassName}>
        <Col xs={12} sm={12} md={12} lg={size.lg} xl={size.xl} xxl={size.xxl}>
          {!actionsInsideCard && (
            <Actions 
              actionsLeft={actionsLeft}
              actionsRight={actionsRight}
            />
          )}
        </Col>
      </Row>

      <Row style={style} className={rowContainerClassName}>
        <Col xs={12} sm={12} md={12} lg={size.lg} xl={size.xl} xxl={size.xxl}>
          {/* if the checkboxes column is activated and one or more checkboxes 
          are selected, the following message is shown */}
          {externalSelectedRows.length > 0 && (
            <div className="d-flex align-items-center gap-2">
              <p className="itp-custom-table-main-p">
                You have selected {externalSelectedRows.length} {module}
              </p>

              {/* if all rows are allowed to be selectable, a message is displayed 
              allowing to select or not to select all rows  */}
              {allowSelectAll && (
                <Link 
                  className="itp-custom-table-main-select-all-rows-link"
                  to="#"
                  onClick={memoizedHandleSelectAllClick}
                >
                  {selectedAllRows ? `Unselect all ${allRows} ${module}` : `Select all ${allRows} ${module}`}
                </Link>
              )}
            </div>
          )}

          <Card className={responsive ? "itp-custom-table-main-cardResponsive" : ""}>
            {titleInCard && (
              <AlignedRow noCentered={noCenterTitleCard || false} m0={true}>
                <h3 className="itp-accordion-header-title mt-2">{titleInCard}</h3>
              </AlignedRow>
            )}

            {subtitleInCard && (
              <AlignedRow m0={true}>
                <h6 className="itp-accordion-header-subtitle">{subtitleInCard}</h6>
              </AlignedRow>
            )}
            
            {customCoponent && (
              <>{customCoponent()}</>
            )}

            {actionsInsideCard && (
              <Actions 
                actionsLeft={actionsLeft}
                actionsRight={actionsRight}
              />
            )}


            <SimpleBar className="itp-custom-table-main-card">
              {!responsive ? (
                <Table
                  centered={centered}
                  data={data}
                  currentData={currentData}
                  module={module}
                  noDataMessage={noDataMessage}
                  withCheckboxes={withCheckboxes}
                  externalSelectedRows={externalSelectedRows}
                  onSelectionChange={onSelectionChange}
                  selectedAllRows={selectedAllRows}
                  getBorderColor={getBorderColor}
                  getTextColor={getTextColor}
                  borderColumnName={borderColumnName}
                  colorColumnName={colorColumnName}
                  columnConfig={columnConfig}
                  renderCallStatus={renderCallStatus}
                  clickRow={clickRow}
                  defaultOrderColumn={defaultOrderColumn} 
                  columnNames={columnNames} 
                  changeColumnOrder={changeColumnOrder} 
                  loading={loading || loadingPagItems}
                  loadingIcon={loadingIcon}
                />
              ): (
                <ResponsiveTable 
                  currentData={currentData}
                  data={data}
                  selectedAllRows={selectedAllRows}
                  loading={loading || loadingPagItems}
                  externalSelectedRows={externalSelectedRows}
                  onSelectionChange={onSelectionChange}
                  withCheckboxes={withCheckboxes}
                  defaultOrderColumn={defaultOrderColumn}
                  columnNames={columnNames}
                  changeColumnOrder={changeColumnOrder}
                  getBorderColor={getBorderColor}
                  borderColumnName={borderColumnName}
                  colorColumnName={colorColumnName}
                  getTextColor={getTextColor}
                  columnConfig={columnConfig}
                  renderCallStatus={renderCallStatus}
                  loadingIcon={loadingIcon}
                  responsiveActionsClassMenu={responsiveActionsClassMenu}
                />
              )}
            </SimpleBar>
          </Card>
        </Col>
      </Row>

      <Row style={style} className={rowContainerClassName}>
        <Col xs={12} sm={12} md={12} lg={size.lg} xl={size.xl} xxl={size.xxl}>
          <div to="pagination" className="itp-custom-pagination-container d-flex align-items-center gap-4">
            <div className={loading ? "" : "mt-3"}>
              {!noShowPagination && (
                <PaginationITP
                  pages={maxPage}
                  active={currentPage}
                  setActive={(page) => setCurrentPage(page)}
                  loading={loading}
                />
              )}
            </div>

            {showSelectSingleCheckbox && (
              <SelectSingleCheckbox
                name="Items Per Page"
                options={optionsPagination}
                defaultSelected={paginationData.toString()}
                checkFunction={onChangeItemsPerPage}
                loading={loading}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

CustomTableMain.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  centered: PropTypes.bool,
  paginationData: PropTypes.number,
  isBackend: PropTypes.bool,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
  offset: PropTypes.number,
  loading: PropTypes.bool,
  module: PropTypes.string,
  noDataMessage: PropTypes.string,
  showSelectSingleCheckbox: bool,
  onChangeItemsPerPage: PropTypes.func,
  actionsLeft: PropTypes.array,
  actionsRight: PropTypes.array,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showTitleCentered: PropTypes.bool,
  withCheckboxes: PropTypes.bool,
  externalSelectedRows: PropTypes.array,
  onSelectionChange: PropTypes.func,
  allRows: PropTypes.number,
  allowSelectAll: PropTypes.bool,
  selectedAllRows: PropTypes.bool,
  handleSelectAllClick: PropTypes.func,
  getBorderColor: PropTypes.func,
  getTextColor: PropTypes.func,
  borderColumnName: PropTypes.string,
  colorColumnName: PropTypes.string,
  columnConfig: PropTypes.object,
  clickRow: PropTypes.func,
  defaultOrderColumn: PropTypes.object,
  columnNames: PropTypes.object,
  changeColumnOrder: PropTypes.func,
  m0: PropTypes.bool,
  m3: PropTypes.bool,
  noShowPagination: PropTypes.bool,
  style: PropTypes.object,
  loadingIcon: PropTypes.object,
  titleInCard: PropTypes.string,
  subtitleInCard: PropTypes.string,
  loadingPagItems: PropTypes.bool,
  noCenterTitleCard: PropTypes.bool,
  actionsInsideCard: PropTypes.bool,
  customCoponent: PropTypes.func,
  responsive: PropTypes.bool,
  responsiveActionsClassMenu: PropTypes.string,
};

CustomTableMain.defaultProps = {
  data: {},
  setData: () => {},
  centered: false,
  paginationData: 10,
  isBackend: false,
  totalPages: 0,
  onPageChange: () => {},
  offset: 0,
  loading: false,
  module: "",
  noDataMessage: "",
  showSelectSingleCheckbox: false,
  onChangeItemsPerPage: () => {},
  actionsLeft: [],
  actionsRight: [],
  title: "",
  subTitle: "",
  showTitleCentered: false,
  withCheckboxes: false,
  externalSelectedRows: [],
  onSelectionChange: () => {},
  allRows: 0,
  allowSelectAll: false,
  selectedAllRows: false,
  handleSelectAllClick: () => {},
  getBorderColor: () => {},
  getTextColor: () => {},
  borderColumnName: "",
  colorColumnName: "",
  columnConfig: {},
  clickRow: () => {},
  defaultOrderColumn: {},
  columnNames: {},
  changeColumnOrder: () => {},
  m0: false,
  m3: false,
  noShowPagination: false,
  style: {},
  loadingIcon: {},
  titleInCard: "",
  subtitleInCard: "",
  loadingPagItems: false,
  noCenterTitleCard: false,
  actionsInsideCard: false,
  customCoponent: () => {},
  responsive: false,
  responsiveActionsClassMenu: "",
};

export default CustomTableMain;
