import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { faCalendarAlt, faCheckSquare, faFileText, faMapMarkerAlt, faPaperclip, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalTagsColor, LoadingButton, ModalDeleteCustomFields } from "../../../../../components";
import { formatDate, convertUtcDateToTimeZoneDate } from "../../../../../utils/getDate";
import { selectNoSaveButton,  selectTitleModal,  selectTextModal, selectCloseButtonName } from "../../../../../utils/selectFunctions";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Chip from '@mui/material/Chip';
import Skeleton from "react-loading-skeleton";
import phoneInput from "../../../../../assets/icons/phoneInput.svg";
import email from "../../../../../assets/icons/email.svg";
import address from "../../../../../assets/icons/address.svg";
import tag from "../../../../../assets/icons/tag.svg";
import profileAdd from "../.././../../../assets/icons/profileAdd.svg";
import tagUser from "../.././../../../assets/icons/tagUser.svg";
import phoneBlack from "../../../../../assets/icons/phoneBlack.svg";
import messageIcon from "../../../../../assets/icons/messageIcon.svg";
import arrowDownIcon from "../../../../../assets/icons/arrowDownIcon.svg";
import arrowUp from "../../../../../assets/icons/arrowUp.svg";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import petitionDelete from "../../../../../services/petitionDelete";
import "../../Conversations.css";

const ContactDetails = ({
  chatWindowData,
  openMenu,
  form,
  setForm,
  handleActiveCall,
  myExtension,
  loading,
  setLoading,
  addContact,
  setAddContact,
  changedNumberOrContact,
  setChangedNumberOrContact,
  refForm,
  addContactFunction,
}) => {
  const valuePhoneIfNotContactTemp = useRef("");
  const firstChageInput = useRef(true);

  const filter = createFilterOptions();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [fieldType, setFieldType] = useState("");
  const [newField, setNewField] = useState("");
  const [loadingAddField, setLoadingAddField] = useState(false);
  const [valueContactList, setValueContactList] = useState(null);
  const [valueAssignedUser, setValueAssignedUser] = useState(null);
  const [tags, setTags] = useState([]);
  const [optionsTags, setOptionsTags] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [users, setUsers] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [newTags, setNewTags] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [editCustomField, setEditCustomField] = useState(null);
  const [modalDeleteCustomField, setModalDeleteCustomField] = useState(false);
  const [notEdit, setNotEdit] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [isLoadingSelectUsers, setIsLoadingSelectUsers] = useState(false);
  const [contactInfo, setContactInfo] = useState({})
  const [loadingContactDetails, setLoadingContactDetails] = useState(true)
  const [errorEditContact, setErrorEditContact] = useState("")
  const [errorAddOrEditCustomField, setErrorAddOrEditCustomField] = useState("")
  const [openColor, setOpenColor] = useState(false);
  const [openModalColor, setOpenModalColor] = useState(false);
  const [useColor, setUseColor] = useState(false);
  const [color, setColor] = useState('#D0021B');
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const tagColorsWithBlackFont = ["#f8e71c", "#dff21b", ""]

  const handleChangeComplete = (color) => {
    setColor(color.hex); 
    setOpenColor(false);
    setUseColor(true);
  };

  const returnDate = (date, spliter) => {
    let formatedDate = formatDate(date, spliter);
    let day = formatedDate.split("at ")[0];
    let hour = formatedDate.split("at ")[1];
    return (
      <>
        <span style={{ font: "normal normal normal 10px/13px Instrument Sans" }}>{day}</span>
        <span className="itp-custom-table-hour mt-1">
          {" at "}
          {hour}
        </span>
      </>
    );
  };

  const toggle = () => {
    if (loading || loadingContactDetails) return;

    setOpenDropdown(!openDropdown);
  };

  const defaultValueAddField = (field) => {
    switch (field) {
      case "address":
        return "Address";
      case "url":
        return "URL";
      case "date":
        return "Date";
      case "text/note":
        return "Text / Note";
      case "checkbox":
        return "Checkbox";
      default:
        return "error";
    }
  };

  const selectPlaceHolder = (field) => {
    switch (field) {
      case "address":
        return "Enter Address";
      case "url":
        return "Enter URL";
      case "text/note":
        return "Enter Text";
      default:
        return "";
    }
  };

  const onchangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTag = (newValue) => {
    setColor('#D0021B');
    if (newValue.length === 0) {
      setOptionsTags(optionsTags);
      setTags([]);
    } 
    else {
      if (newValue[newValue.length - 1]?.inputValue) {
          setLoading(true); 
          setDisabled(true);
          addTag(newValue[newValue.length - 1]);
          setTags(newValue);
      } 
      else {
        setTags(newValue);
      }
    }
  };

  const addTag = (tag) => {
      petitionPost("addTags", {
        data: {
           name: tag.inputValue,
           tag_color: tag.tag_color || "",
        },
      })
        .then(({ data: result }) => {
          let data = newTags;
          petitionTag();
          setDisabled(false);
          data.push(result.result);
          setNewTags(data);
          setUseColor(false);
        })
        .catch((error) => console.log(error)); 
    setLoading(false);
  };

  const formatTags = () => {
    let dataTagsNew = [];
    tags.map((element) => {
      if (typeof element !== "string") {
        if (element.inputValue) {
          let dataFilter = newTags.filter(
            (elementTwo) => elementTwo?.name === element?.inputValue
          );
          dataTagsNew.push(dataFilter[0]?.pk);
        } else {
          dataTagsNew.push(element.pk);
        }
      }
    });

    let result = dataTagsNew.filter((item, index) => {
      return dataTagsNew.indexOf(item) === index;
    });

    return result;
  };

  const returnCheck = (state) => {
    if (
      state === true ||
      state === "true" ||
      state === "True" ||
      state === "enabled" ||
      state === "Enabled"
    ) {
      return true;
    } else if (
      state === false ||
      state === "false" ||
      state === "False" ||
      state === "" ||
      state === null ||
      state === undefined ||
      state === "disabled" ||
      state === "Disabled"
    ) {
      return false;
    } else return false;
  };

  const handleCheck = (state) => {
    if (
      state === true ||
      state === "true" ||
      state === "True" ||
      state === "enabled" ||
      state === "Enabled"
    ) {
      return false;
    } else if (
      state === false ||
      state === "false" ||
      state === "False" ||
      state === "" ||
      state === null ||
      state === undefined ||
      state === "disabled" ||
      state === "Disabled"
    ) {
      return true;
    } else return true;
  };

  const addCustomField = () => {
    setLoadingAddField(true);
    let data = {
      name: newField || defaultValueAddField(fieldType),
      field_type: fieldType,
      field_metadata: {
        max_length: 150,
      },
    };
    petitionPost("customFieldMyExtension", { data })
      .then(({ data: result }) => {
        petitionGet("customFields")
          .then(({ data: result }) => {
            result.result.forEach((element) => {
              element.label = element.name;
              element.value = element.pk;
            });

            setCustomFields(result.result);
            setLoadingAddField(false);
            setFieldType("");
            setNewField("");
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        console.log(error);

        
        if (error?.response?.status === 400 && error?.response?.data?.error?.error_message) {
          setModalDeleteCustomField(true)
          setErrorAddOrEditCustomField(error?.response?.data?.error?.error_message)
        }

        setLoadingAddField(false);
      });
  };

  const onSaveChange = () => {
    if (loading || loadingSave || loadingContactDetails) return;

    setLoadingSave(true);
    let data = {
      ...form,
      tags: formatTags(),
      contact_list_id: valueContactList?.value || "",
      phone: form.phone.length > 0 ? `+${form.phone}` : "",
    };

    if (valueAssignedUser) data.owner_id = valueAssignedUser.value;

    editContact(data);
  };

  const editContact = (form) => {
    setErrorEditContact("")
    petitionPatch("contact", {
      data: form,
      contact_id: chatWindowData?.current?.notIsSelf?.contact?.pk,
    })
      .then(({ data: result }) => {
        let newChatWindowData = {};
        if (result.result.phone !== chatWindowData.current.numberChat) {
          let newnNotIsSelf = chatWindowData.current.notIsSelf;
          delete newnNotIsSelf.contact;

          newChatWindowData = {
            ...chatWindowData.current,
            isContact: false,
            notIsSelf: newnNotIsSelf,
            numberChat: chatWindowData.current.numberChat,
          };

          delete newChatWindowData.firstname;
          delete newChatWindowData.lastname;
          delete newChatWindowData.nameContact;
          setAddContact(true);
        } else {
          newChatWindowData = {
            ...chatWindowData.current,
            firstname: result?.result?.firstname,
            lastname: result?.result?.lastname,
            nameContact: `${result?.result?.firstname} ${
              result?.result?.lastname || ""
            }`,
            notIsSelf: {
              ...chatWindowData.current.notIsSelf,
              contact: {
                ...chatWindowData.current.notIsSelf.contact,
                ...result?.result,
              },
            },
          };
        }

        if (result.result.phone === chatWindowData.current.numberChat) {
          setForm({
            ...form,
            firstname: result.result.firstname,
            lastname: result.result.lastname,
          });
        }

        setChangedNumberOrContact(newChatWindowData);
        forceUpdate();

        if (result.result.phone === chatWindowData.current.numberChat) {
          petition(false, newChatWindowData);
        } else {
          setForm({
            ...form,
            phone: newChatWindowData.numberChat,
            email: "",
          });

          setAddContact(true);
        }

        chatWindowData.current = newChatWindowData;
      })
      .catch((error) => {
        console.log(error);

        if (error?.response?.status === 400) {
          const messageError = error?.response?.data?.error?.error_message
          setErrorEditContact(messageError)
        }

        setLoadingSave(false);
      });
  };

  const activeModalDelete = (e) => {
    setModalDeleteCustomField(e);
  };

  const closeModal = () => {
    setErrorAddOrEditCustomField("")
    setModalDeleteCustomField(false);
    setEditCustomField(null);
    setNotEdit(false);
    setOpenModalColor(false);
  };

  const activeEditCustomField = (element) => {
    if (myExtension.priv_level !== "admin") {
      setNotEdit(true);
      activeModalDelete(element);
    } else {
      setEditCustomField(element);
      updateCustomField(element);
    }
  };

  const updateCustomField = (infoCustomField) => {
    const data = infoCustomField;
    const tempPk = data.pk;
    delete data.label;
    delete data.value;
    delete data.pk;
    delete data.account_id;
    data.name = document.getElementById(tempPk).value;
    petitionPatch("customField", { data, custom_field_id: tempPk })
      .then(({ data: result }) => {
        const data = document.getElementsByClassName(
          "itp-edit-contact-custom-field-actions"
        );
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          element.style.visibility = "hidden";
        }
        petitionGet("customFields")
          .then(({ data: result }) => {
            result.result.forEach((element) => {
              element.label = element.name;
              element.value = element.pk;
            });

            setCustomFields(result.result);
            closeModal();
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        console.log(error)

        if (error?.response?.status === 400 && error?.response?.data?.error?.error_message) {
          setModalDeleteCustomField(true)
          setErrorAddOrEditCustomField(error?.response?.data?.error?.error_message)
        }
      });
  };

  const deleteCustomField = () => {
    setLoadingModal(true);
    petitionDelete("customField", {
      custom_field_id: modalDeleteCustomField.pk,
    })
      .then(({ data: result }) => {
        petitionGet("customFields")
          .then(({ data: result }) => {
            result.result.forEach((element) => {
              element.label = element.name;
              element.value = element.pk;
            });

            setCustomFields(result.result);
            setLoadingModal(false);
            closeModal();
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  const loadOptions = (inputValue, callback) => {
    petitionGet("contactList", { parameter: `?name=${inputValue}` })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.list_name} (${element.contact_count})`;
          element.value = element.pk;
        });

        if (!valueContactList) {
          setValueContactList(result.result[0]);
          setForm({
            ...form,
            contact_list_id: result.result[0].pk,
          });
        }
        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const petitionTag = async () => {
    try {
      const response = await petitionGet("tags", { parameter: "?offset=0&limit=10000" });
      const { data: result } = response;

      result.result.forEach((element) => {
        element.title = element.name;
        element.disabled = false;
        element.label = element.name;
        element.value = element.pk;
        element.tag_color = element.tag_color || "";
      });

      setOptionsTags(result.result);

      return result.result; // Devuelve la respuesta del endpoint
    } catch (error) {
      console.log(error);
    }
  };

  const selectIcon = (element) => {
    switch (element.last_activity_type) {
      case "call_inbound":
        return <img style={{ width: "16px", height: "16px" }} src={phoneBlack} alt="" />;

      case "call_outbound":
        return <img style={{ width: "16px", height: "16px" }} src={phoneBlack} alt="" />;

      case "sms_received":
        return <img style={{ width: "16px", height: "16px" }} src={messageIcon} alt="" />;

      case "sms_sent":
        return <img style={{ width: "16px", height: "16px" }} src={messageIcon} alt="" />;

      default:
        return ""
    }
  }

  const selectArrow = (element) => {
    switch (element.last_activity_type) {
      case "call_inbound":
        return <img style={{ width: "16px", height: "16px" }} src={arrowDownIcon} alt="" />;

      case "call_outbound":
        return <img style={{ width: "16px", height: "16px" }} src={arrowUp} alt="" />;

      case "sms_received":
        return <img style={{ width: "16px", height: "16px" }} src={arrowDownIcon} alt="" />;

      case "sms_sent":
        return <img style={{ width: "16px", height: "16px" }} src={arrowUp} alt="" />;

      default:
        return ""
    }
  }

  const petition = async (noLoading, dataContact) => {
    const contactID = dataContact?.notIsSelf?.contact?.pk

    let newContactObject = {}

    if (dataContact?.notIsSelf?.contact?.last_activity_time) {
      dataContact.notIsSelf.contact.keyDate = convertUtcDateToTimeZoneDate(dataContact?.notIsSelf?.contact?.last_activity_time?.replaceAll(" ", "T"), myExtension.time_zone)
    }

    setContactInfo(dataContact)

    if (!noLoading) setLoading(true);

    await petitionTag();

    await petitionGet("contacts", { parameter: `/${contactID}` })
      .then(({ data: result }) => {
        newContactObject = { ...result?.result }
      })
      .catch((error) => console.log(error))

    if (dataContact?.notIsSelf?.contact?.owner_id) {
      await petitionGet("user", { user_id: dataContact?.notIsSelf?.contact?.owner_id })
      .then(({ data: result }) => {
        result.result.label = `${result?.result?.firstname} ${result?.result?.lastname}`
        result.result.value = result?.result?.pk
  
        setValueAssignedUser(result.result);
      })
      .catch((error) => console.log(error));
    }

    await petitionGet("contactList", {
      parameter: `/${dataContact?.notIsSelf?.contact?.contact_list_id}`,
    })
      .then(({ data: result }) => {
        result.result.label = `${result.result.list_name} (${
          result?.result?.contact_count || ""
        })`;
        result.result.value = result.result.pk;
        setValueContactList(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("customFields")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
        });

        let currentData = newContactObject || [];
        let formatedTags = [];

        if (currentData?.tags) {
          formatedTags = currentData?.tags.map((tag) => ({
            ...tag,
            title: tag.tag.name,
            pk: tag.tag_id,
            tag_color: tag.tag.tag_color || "",
          }));
        }
        let valueCustomField = {};

        result.result.forEach((element) => {
          var isDate = function () {
            return (
              new Date(currentData[element.name]) !== "Invalid Date" &&
              !isNaN(new Date(currentData[element.name]))
            );
          };

          if (
            isDate &&
            currentData[element.name] !== null &&
            currentData[element.name] !== undefined &&
            element.field_type === "date"
          ) {
            let temp_date = currentData[element.name].split("/");
            if (temp_date.length == 1) {
              valueCustomField[element.name] = temp_date[0];
            } else {
              valueCustomField[
                element.name
              ] = `${temp_date[2]}-${temp_date[0]}-${temp_date[1]}`;
            }
          } else {
            valueCustomField[element.name] = currentData[element.name];
          }
        });

        currentData.tags = formatedTags;

        const currentNotIsSelf = chatWindowData?.current?.notIsSelf
        const currentContactObject = chatWindowData?.current?.notIsSelf?.contact

        const newChat = {
          ...chatWindowData?.current,
          notIsSelf: {
            ...currentNotIsSelf,
            contact: {
              ...currentContactObject,
              ...newContactObject,
            }
          }
        }

        chatWindowData.current = newChat
        forceUpdate()

        setForm({
          ...refForm.current,
          phone: newContactObject?.phone || "",
          email: newContactObject?.email || "",
          address: newContactObject?.address || "",
          dnd_sms: newContactObject?.dnd_sms || false,
          ...valueCustomField,
        });

        setCustomFields(result.result);
        setTags(formatedTags);
        setLoading(false);
        setLoadingContactDetails(false)
        setLoadingSave(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (isLoadingSelectUsers) {
      const fetchUsers = async () => {
        await petitionGet("users")
        .then(({ data: result }) => {
          result.result.forEach((element) => {
            element.label = `${element.firstname} ${element.lastname}`;
            element.value = element.pk;
          });
  
          setUsers(result.result);
          setIsLoadingSelectUsers(false)
        })
        .catch((error) => console.log(error));
      }

      fetchUsers()
    }
  }, [isLoadingSelectUsers])

  useEffect(() => {
    if (changedNumberOrContact) {
      petition(false, chatWindowData?.current);
      setChangedNumberOrContact(false);
    }
  }, [changedNumberOrContact]);

  useEffect(() => {
    if (openMenu) {
      if (chatWindowData?.current?.isContact) {
        petition(false, chatWindowData?.current);
      } else {
        setForm({
          ...form,
          phone: chatWindowData?.current.numberChat,
          email: "",
        });
        valuePhoneIfNotContactTemp.current = chatWindowData?.current.numberChat;
        setAddContact(true);
        setLoading(false);
        setLoadingContactDetails(false)
      }
    } else {
      setErrorEditContact("")
      setOpenDropdown(false);
      setFieldType("");
      setNewField("");
      setLoadingAddField(false);
      setForm({ firstname: "", lastname: "" });
      setValueContactList(null);
      setValueAssignedUser(null);
      setLoading(true);
      setLoadingContactDetails(true)
      setTags([]);
      setOptionsTags([]);
      setCustomFields([]);
      setUsers([]);
      setDisabled(false);
      setNewTags([]);
      setLoadingSave(false);
      setAddContact(false);
    }
  }, [openMenu]);

  return (
    <>
      {!addContact && (
        <>
          {modalDeleteCustomField && (
            <ModalDeleteCustomFields
              modal={modalDeleteCustomField}
              closeModal={closeModal}
              loading={loadingModal}
              modalFunction={deleteCustomField}
              text={selectTextModal(notEdit, myExtension, errorAddOrEditCustomField)}
              title={selectTitleModal(notEdit, errorAddOrEditCustomField)}
              noSaveButton={selectNoSaveButton(myExtension, errorAddOrEditCustomField)}
              closeButtonName={selectCloseButtonName(errorAddOrEditCustomField)}
            />
          )}

          {openModalColor && (
            <ModalTagsColor
              modal={openModalColor}
              closeModal={closeModal}
              loading={loadingModal}
              title={"Add tag"}
              setOpenColor={setOpenColor}
              openColor={openColor}
              sendDatatoParent={handleTag}
              tagsExternal={tags}
            />
          )}

          <Row className="itp-container-label-select margin itp-chat-menu-body__set-account">
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
              className="itp-label-input-col"
            >
              <img
                src={phoneInput}
                alt=""
                className="itp-contact-details-menu cursor-pointer mt-2"
                onClick={() => handleActiveCall(true, form.phone)}
              />
            </Col>
            <Col
              xs={10}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              className="itp-label-input-col"
            >
              {(loading || loadingContactDetails) ? (
                <Skeleton height={50} width="100%" />
              ) : (
                <PhoneInput
                  enableSearch={true}
                  country={"us"}
                  value={form.phone}
                  onChange={(e) => {
                    setForm({ ...form, phone: e });
                  }}
                />
              )}
            </Col>
          </Row>

          <Row className="itp-container-label-input">
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
              className="itp-label-input-col"
            >
              <img
                src={address}
                className="itp-contact-details-menu opacity-50"
              />
            </Col>
            <Col
              xs={11}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              xxl={11}
              className="itp-label-input-col pl-8px"
            >
              {(loading || loadingContactDetails) ? (
                <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
              ) : (
                <input
                  type="text"
                  placeholder=": Enter a Address"
                  className="form-control-itp itp-label-input__input"
                  name="address"
                  value={form.address}
                  onChange={onchangeForm}
                />
              )}
            </Col>
          </Row>

          <Row className="itp-container-label-input">
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
              className="itp-label-input-col"
            >
              <img src={email} className="itp-contact-details-menu" />
            </Col>
            <Col
              xs={11}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              xxl={11}
              className="itp-label-input-col pl-8px"
            >
              {(loading || loadingContactDetails) ? (
                <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
              ) : (
                <input
                  type="text"
                  placeholder=": Enter a email"
                  className="form-control-itp itp-label-input__input"
                  name="email"
                  value={form.email}
                  onChange={onchangeForm}
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={(loading || loadingContactDetails) ? 12 : 7} sm={(loading || loadingContactDetails) ? 12 : 7} md={(loading || loadingContactDetails) ? 12 : 7} lg={(loading || loadingContactDetails) ? 12 : 7} xl={(loading || loadingContactDetails) ? 12 : 7} xxl={(loading || loadingContactDetails) ? 12 : 7}>
              <div className="itp-component-tag-container">
                <img
                  src={tag}
                  alt="Add Tag"
                  className="itp-component-tag-img"
                />
            <div className="itp-component-tag-width">
              {(loading || loadingContactDetails) ? (
                <Skeleton style={{ marginLeft: "0.25rem" }} height={45} width="90%" />
              ) : (
                <Stack>
                <Autocomplete
                  disableClearable
                  disablePortal
                  filterSelectedOptions
                  disabled={disabled}
                  freeSolo
                  multiple
                  value={tags}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option?.inputValue || option.title}
                        {...getTagProps({ index })}
                        style={{ 
                          backgroundColor: option.tag_color || "#edf6fd" , 
                          color: tagColorsWithBlackFont.includes(option.tag_color) ? "black" : "white",
                        }}
                      />
                    ))
                  }
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return "error";
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    else{
                      return option.title;
                    }
                  }}
                  getOptionDisabled={(option) => !!option.disabled}
                  options={optionsTags}
                  id="tags-standard"
                  isOptionEqualToValue={(option, value) =>
                    option.title === value.title
                  }
                  onChange={(event, newValue) => handleTag(newValue)}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.title
                    );

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Add Tags"
                    />
                  )}
                  renderOption={(props, option) => (
                    <Chip
                    {...props}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#cfe3ff', // Cambiar al color más oscuro deseado
                        color: "black",
                      },
                      backgroundColor: option.tag_color || '#ffffff',
                      borderRadius: 0,
                      border: 0, // Mantener los bordes cuadrados
                      color: tagColorsWithBlackFont.includes(option.tag_color) ? "black" : "white",
                    }}
                    label={option.title}
                    variant="outlined"
                  />
                  )}
                />
                </Stack>
              )}
            </div>
              </div>
            </Col>
            <Col xs={(loading || loadingContactDetails) ? 0 : 5} sm={(loading || loadingContactDetails) ? 0 : 5} md={(loading || loadingContactDetails) ? 0 : 5} lg={(loading || loadingContactDetails) ? 0 : 5} xl={(loading || loadingContactDetails) ? 0 : 5} xxl={(loading || loadingContactDetails) ? 0 : 5}>
              <div style={{display: (loading || loadingContactDetails) ? "none" : "flex", alignItems: "end", height: "100%"}}>
                <div>
                    <button className="btn-primary itp-add-button" onClick={() => setOpenModalColor(!openModalColor)}>
                      <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" /> Add tag
                    </button>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="itp-contact-details-row-select">
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <label className="itp-contact-details-label chat-menu-label">
                Contact List
              </label>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            {(loading || loadingContactDetails) ? (
                <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
              ) : (
                <AsyncSelect
                  onChange={(e) => {
                    setValueContactList(e);
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  value={valueContactList}
                  isSearchable={true}
                  name="contact_list_id"
                  loadOptions={loadOptions}
                  defaultOptions
                  cacheOptions
                />
              )}
            </Col>
          </Row>

          <Row className="itp-contact-details-row-select">
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <label className="itp-contact-details-label">Assigned User</label>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            {(loading || loadingContactDetails) ? (
                <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
              ) : (
                <Select
                  onChange={(e) => setValueAssignedUser(e)}
                  className="basic-single"
                  classNamePrefix="select"
                  name="owner_id"
                  placeholder="Select a User"
                  options={users}
                  isLoading={isLoadingSelectUsers}
                  isSearchable={true}
                  value={valueAssignedUser}
                  onMenuOpen={() => {
                    if (!users.length) {
                      // Descargar opciones solo si no hay opciones cargadas
                      setIsLoadingSelectUsers(true);
                    }
                  }}
                />
              )}
            </Col>
          </Row>

          <Row className="itp-contact-details-row-select">
            <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={4}>
              <label className="itp-contact-details-label">Last Actitity (Only Read)</label>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={8}>
             {(loading || loadingContactDetails) ? (
                <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
              ) : (
                <div className="d-flex align-items-center gap-1">
                <div>{selectIcon(contactInfo?.notIsSelf?.contact)}</div>
                <div>{selectArrow(contactInfo?.notIsSelf?.contact)}</div>
                {contactInfo?.notIsSelf?.contact?.keyDate && returnDate(contactInfo?.notIsSelf?.contact?.keyDate)}
               </div>
              )}
            </Col>
          </Row>

          <Row className="itp-container-label-select" >
            <Col
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              className="itp-label-input-col"
              style={{ wordWrap: "anywhere" }}
            >
              <label className="itp-label-input__label">Opted Out (SMS)</label>
            </Col>
            <Col
              xs={7}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              xxl={7}
              className="itp-label-input-col"
            >
              {(loading || loadingContactDetails) ? (
                <Skeleton height={20} width="20px" />
              ) : (
                <input
                  checked={returnCheck(form?.dnd_sms) || false}
                  name="dnd_sms"
                  onChange={(e) => {
                    setForm({
                      ...form,
                      dnd_sms: handleCheck(form.dnd_sms || false),
                    });
                  }}
                  type="checkbox"
                  className="form-check-input itp-custom-checkbox-contact-details"
                />
              )}
            </Col>
          </Row>          

          {customFields.map((element, index) => (
            <div key={index} className="itp-edit-contact-container-custom-field">
              {!editCustomField && (
                <div className="itp-edit-contact-custom-field-actions p-1">
                  <input
                    name={element.label}
                    id={element.pk}
                    defaultValue={element.label}
                  />
                  <label
                    onClick={() => activeEditCustomField(element)}
                    className="itp-label-input__label option itp-label-border-bottom-property"
                  >
                   Save
                  </label>
                  <label
                    onClick={() => activeModalDelete(element)}
                    className="itp-label-input__label option"
                  >
                   Delete
                  </label>
               </div>
              )}
             <Row
               className={`itp-container-label-${
               element.field_type !== "list" &&
               element.field_type !== "checkbox" &&
               element.field_type !== "date"
                 ? "input"
                 : "select"
               }`}
             >
             <Col
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              className="itp-label-input-col"
             >
              <label className="itp-label-input__label">{element.name}</label>
            </Col>
            <Col
              xs={7}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              xxl={7}
              className="itp-label-input-col"
            >
              {element.field_type !== "list" &&
                element.field_type !== "date" &&
                element.field_type !== "checkbox" && (
                  <>
                    {(loading || loadingContactDetails) ? (
                      <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
                    ) : (
                      <input
                        type="text"
                        placeholder={selectPlaceHolder(element.field_type)}
                        className="form-control-itp itp-label-input__input"
                        name={element.name}
                        value={form[element.name] || ""}
                        onChange={onchangeForm}
                      />
                    )}
                  </>
                )}

              {element.field_type === "date" && (
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <div className="container-date-range">
                      <label className="date-range-label">Select Date:</label>
                      {(loading || loadingContactDetails) ? (
                      <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
                    ) : (
                      <input
                        type="date"
                        className="date-range-input"
                        name={element.name}
                        value={form[element.name] || ""}
                        onChange={onchangeForm}
                      />
                    )}
                    </div>
                  </Col>
                </Row>
              )}

              {element.field_type === "list" && (
                <>
                  {(loading || loadingContactDetails) ? (
                    <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
                  ) : (
                    <select
                      className="form-select-itp"
                      name={element.name}
                      value={form[element.name] || ""}
                      onChange={onchangeForm}
                    >
                      <option value="" disabled>
                        Select Option
                      </option>
                      {element.field_metadata.list_values.map((val, i) => (
                        <option key={i} value={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                  )}
                </>
              )}

              {element.field_type === "checkbox" && (
                <>
                  {(loading || loadingContactDetails) ? (
                    <Skeleton height={20} width="20px" />
                  ) : (
                    <input
                      checked={returnCheck(form[element.name]) || false}
                      name={element.name}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [e.target.name]: handleCheck(
                            form[element.name] || false
                          ),
                        });
                       }}
                       type="checkbox"
                       className="form-check-input itp-custom-checkbox-contact-details"
                    />
              )}
                </>
              )}
            </Col>
             </Row>
            </div>
          ))}          

          {fieldType === "" && (
            <Dropdown
              isOpen={openDropdown}
              toggle={toggle}
              className="itp-contact-details-add-field"
            >
              <DropdownToggle className="btn-light">
                {<FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />}{" "}
                Add Field
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => setFieldType("address")}
                  className="dropdown-item"
                >
                  {<FontAwesomeIcon icon={faMapMarkerAlt} />} Address
                </DropdownItem>
                <DropdownItem
                  onClick={() => setFieldType("url")}
                  className="dropdown-item"
                >
                  {<FontAwesomeIcon icon={faPaperclip} />} URL
                </DropdownItem>
                <DropdownItem
                  onClick={() => setFieldType("date")}
                  className="dropdown-item"
                >
                  {<FontAwesomeIcon icon={faCalendarAlt} />} Date
                </DropdownItem>
                <DropdownItem
                  onClick={() => setFieldType("text/note")}
                  className="dropdown-item"
                >
                  {<FontAwesomeIcon icon={faFileText} />} Text / Note
                </DropdownItem>
                <DropdownItem
                  onClick={() => setFieldType("checkbox")}
                  className="dropdown-item"
                >
                  {<FontAwesomeIcon icon={faCheckSquare} />} Checkbox
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}

          {fieldType !== "" && (
            <div className="itp-contact-details-new-field">
              <input
                className="itp-contact-details-new-field__input"
                defaultValue={defaultValueAddField(fieldType)}
                onChange={(e) => setNewField(e.target.value)}
              />
              {loadingAddField ? (
                <LoadingButton />
              ) : (
                <button
                  onClick={addCustomField}
                  className="itp-contact-details-new-field__button save"
                >
                  Save
                </button>
              )}
              <button
                className="itp-contact-details-new-field__button"
                onClick={() => setFieldType("")}
                disabled={loadingAddField}
              >
                Delete
              </button>
            </div>
          )}

          {errorEditContact && (
            <p className="text-danger">{errorEditContact}</p>
          )}

          <button
            onClick={onSaveChange}
            className="btn-primary itp-contact-detail-button-save loading"
          >
            {loadingSave && <LoadingButton />}
            Save Changes
          </button>
        </>
      )}

      {addContact && (
        <>
          <p className="mt-3">Add Contact</p>
          <Row className="itp-container-label-input">
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
              className="itp-label-input-col"
            >
              <img src={profileAdd} className="itp-contact-details-menu" />
            </Col>
            <Col
              xs={11}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              xxl={11}
              className="itp-label-input-col"
            >
              {(loading || loadingContactDetails) ? (
                <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
              ) : (
                <input
                  type="text"
                  placeholder=": Enter a first name"
                  className="form-control-itp itp-label-input__input"
                  name="firstname"
                  value={form.firstname}
                  onChange={onchangeForm}
                />
              )}
            </Col>
          </Row>

          <Row className="itp-container-label-input">
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
              className="itp-label-input-col"
            >
              <img src={tagUser} className="itp-contact-details-menu" />
            </Col>
            <Col
              xs={11}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              xxl={11}
              className="itp-label-input-col"
            >
              {(loading || loadingContactDetails) ? (
                <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
              ) : (
                <input
                  type="text"
                  placeholder=": Enter a last name"
                  className="form-control-itp itp-label-input__input"
                  name="lastname"
                  value={form.lastname}
                  onChange={onchangeForm}
                />
              )}
            </Col>
          </Row>

          <Row className="itp-container-label-select margin itp-chat-menu-body__set-account">
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
              className="itp-label-input-col"
            >
              <img
                src={phoneInput}
                alt=""
                className="itp-contact-details-menu cursor-pointer"
                onClick={() => handleActiveCall(true, form.phone)}
              />
            </Col>
            <Col
              xs={10}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              className="itp-label-input-col"
            >
              {(loading || loadingContactDetails) ? (
                <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
              ) : (
                <PhoneInput
                  country={"us"}
                  value={firstChageInput.current ? valuePhoneIfNotContactTemp.current : form.phone}
                  enableSearch={true}
                  onChange={(e) => {
                    firstChageInput.current = false;
                    setForm({ ...form, phone: e });
                  }}
                />
              )}
            </Col>
          </Row>

          <Row className="itp-container-label-input">
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
              className="itp-label-input-col"
            >
              <img src={email} className="itp-contact-details-menu" />
            </Col>
            <Col
              xs={11}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              xxl={11}
              className="itp-label-input-col"
            >
              {(loading || loadingContactDetails) ? (
                <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
              ) : (
                <input
                  type="text"
                  placeholder=": Enter a email"
                  className="form-control-itp itp-label-input__input"
                  name="email"
                  value={form.email}
                  onChange={onchangeForm}
                />
              )}
            </Col>
          </Row>

          <Row className="itp-contact-details-row-select">
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <label className="itp-contact-details-label chat-menu-label">
                Contact List
              </label>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
              {(loading || loadingContactDetails) ? (
                <Skeleton style={{ marginLeft: "4px" }} height={45} width="94%" />
              ) : (
                <AsyncSelect
                  onChange={(e) => {
                    setForm({ ...form, contact_list_id: e.pk });
                    setValueContactList(e);
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  value={valueContactList}
                  isSearchable={true}
                  name="contact_list_id"
                  loadOptions={loadOptions}
                  defaultOptions
                  cacheOptions
                />
              )}
            </Col>
          </Row>

          <button
            onClick={addContactFunction}
            className="btn-primary itp-contact-detail-button-save loading"
            disabled={loading || loadingContactDetails || loadingSave || !form?.firstname || (form?.phone && (form?.phone === "+" || form?.phone?.length === 2))}
          >
            {loadingSave && <LoadingButton />}
            Create
          </button>
        </>
      )}
    </>
  );
};

export default ContactDetails;
