import BasicSettings from "./BasicSettings/BasicSettings";
import GranullarPermissions from "./GranullarPermissions/GranullarPermissions";
import Devices from "./Devices/Devices";
import CallForwarding from "./CallForwarding/CallForwarding";
import CallerId from "./CallerId/CallerId";
import CallRecording from "./CallRecording/CallRecording";
import IntercomPaging from "./IntercomPaging/IntercomPaging";
import Faxing from "./Faxing/Faxing";
import Voicemails from "./Voicemails/Voicemails";
import JitterBuffer from "./JitterBuffer/JitterBuffer";

export {
  BasicSettings,
  GranullarPermissions,
  Devices,
  CallForwarding,
  CallerId,
  CallRecording,
  IntercomPaging,
  Faxing,
  Voicemails,
  JitterBuffer,
};
