import { useState } from "react";
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { LabelInput, LabelSelect, LoadingButton, ModalDeleteCustomFields } from "../index";
import { defaultValueAddField, returnCheck, selectPlaceHolder } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCheckSquare, faFileText, faMapMarkerAlt, faPaperclip, faPlus } from "@fortawesome/free-solid-svg-icons";
import { selectCloseButtonName, selectNoSaveButton, selectTextModal, selectTitleModal } from "../../utils/selectFunctions";
import { deleteCustomFieldService, editCustomFieldService, fetchCustomFields } from "./services";
import petitionPost from "../../services/petitionPost";
import "./CustomFields.css"

const CustomFields = ({
  customFields,
  form,
  setForm,
  onchangeForm,
  loading,
  myExtension,
  setAddedCustomField,
  customReload,
}) => {
  const [editCustomField, setEditCustomField] = useState(null);
  const [errorAddOrEditCustomField, setErrorAddOrEditCustomField] = useState("")
  const [notEdit, setNotEdit] = useState(false)
  const [modalDeleteCustomField, setModalDeleteCustomField] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [fieldType, setFieldType] = useState("");
  const [newField, setNewField] = useState("");
  const [loadingAddField, setLoadingAddField] = useState(false);

  const toggle = () => {
    if (loading) return;
    setOpenDropdown(!openDropdown);
  };

  const closeModal = () => {
    setErrorAddOrEditCustomField("")
    setModalDeleteCustomField(false);
    setEditCustomField(null);
    setNotEdit(false)
  };

  const activeModalDelete = (e) => {
    setModalDeleteCustomField(e);
  };

  const activeEditCustomField = (element) => {
    if (myExtension.priv_level !== "admin") {
      setNotEdit(true);
      activeModalDelete(element);
    } else {
      setEditCustomField(element)
      updateCustomField(element);
    }
  }

  const deleteCustomField = async () => {
    await deleteCustomFieldService(setLoadingModal, modalDeleteCustomField, customFields, closeModal)
  };

  const updateCustomField = async (infoCustomField) => {
    await editCustomFieldService(infoCustomField, closeModal, customFields, setModalDeleteCustomField, setErrorAddOrEditCustomField)
  };

  const addCustomField = async () => {
    try {
      setLoadingAddField(true);
  
      const data = {
        name: newField || defaultValueAddField(fieldType),
        field_type: fieldType,
        field_metadata: { max_length: 150 },
      };
  
      await petitionPost("customFieldMyExtension", { data })
      setAddedCustomField(true);
  
      await fetchCustomFields(customFields)

      if (typeof customReload === "function") {
        await customReload()
      }

      setLoadingAddField(false);
      setFieldType("");
      setNewField("");
    } catch (error) {
      console.log(error);
  
      if (error?.response?.status === 400 && error?.response?.data?.error?.error_message) {
        setModalDeleteCustomField(true)
        setErrorAddOrEditCustomField(error?.response?.data?.error?.error_message)
      }
  
      setLoadingAddField(false);
    }
  };

  return (
    <>
      {modalDeleteCustomField && (
        <ModalDeleteCustomFields
          modal={modalDeleteCustomField}
          closeModal={closeModal}
          loading={loadingModal}
          modalFunction={deleteCustomField}
          text={selectTextModal(notEdit, myExtension.priv_level, errorAddOrEditCustomField)}
          title={selectTitleModal(notEdit, errorAddOrEditCustomField)}
          noSaveButton={selectNoSaveButton(myExtension.priv_level, errorAddOrEditCustomField)}
          closeButtonName={selectCloseButtonName(errorAddOrEditCustomField)}
        />
      )}

      {customFields.current.map((element, index) => (
        <div key={index} className="itp-edit-contact-container-custom-field">
          {!editCustomField && (
            <div className="itp-edit-contact-custom-field-actions p-1">
              <input
                name={element.label}
                id={element.pk}
                defaultValue={element.label}
              />
              <label
                onClick={() => activeEditCustomField(element)}
                className="itp-label-input__label option itp-label-border-bottom-property"
              >
                Save
              </label>
              <label
                onClick={() => activeModalDelete(element)}
                className="itp-label-input__label option"
              >
                Delete
              </label>
            </div>
          )}

          {element.field_type !== "list" &&
          element.field_type !== "checkbox" &&
          element.field_type !== "date" ? (
            <LabelInput label={element.name} loading={loading}>
              {element.field_type !== "list" &&
                element.field_type !== "date" &&
                element.field_type !== "checkbox" && (
                  <input
                    type="text"
                    placeholder={selectPlaceHolder(element.field_type)}
                    className="form-control-itp itp-label-input__input"
                    name={element.name}
                    value={form[element.name] || ""}
                    onChange={onchangeForm}
                  />
                )}

              {element.field_type === "date" && (
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <div className="container-date-range">
                      <label className="date-range-label">Select Date:</label>
                      <input
                        type="date"
                        className="date-range-input"
                        name={element.name}
                        value={form[element.name] || ""}
                        onChange={onchangeForm}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              {element.field_type === "list" && (
                <select
                  className="form-select-itp"
                  onChange={onchangeForm}
                  name={element.name}
                  value={form[element.name] || ""}
                >
                  <option value="" disabled>
                    Select Option
                  </option>
                  {element.field_metadata.list_values.map((val, i) => (
                    <option key={i} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              )}

              {element.field_type === "checkbox" && (
                <input
                  checked={returnCheck(form[element.name]) || false}
                  name={element.name}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      [e.target.name]: returnCheck(
                        form[element.name] || false,
                        true
                      ),
                    });
                  }}
                  type="checkbox"
                  className="form-check-input itp-custom-checkbox-contact-details"
                />
              )}
            </LabelInput>
          ) : (
            <LabelSelect label={element.name} loading={loading}>
              {element.field_type !== "list" && element.field_type !== "date" && element.field_type !== "checkbox" && (
                  <input
                    type="text"
                    placeholder={selectPlaceHolder(element.field_type)}
                    className="form-control-itp itp-label-input__input"
                    name={element.name}
                    value={form[element.name] || ""}
                    onChange={onchangeForm}
                  />
              )}

              {element.field_type === "date" && (
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <div className="container-date-range">
                      <label className="date-range-label">Select Date:</label>
                      <input
                        type="date"
                        className="date-range-input"
                        name={element.name}
                        value={form[element.name] || ""}
                        onChange={onchangeForm}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              {element.field_type === "list" && (
                <select
                  className="form-select-itp"
                  onChange={onchangeForm}
                  name={element.name}
                  value={form[element.name] || ""}
                >
                  <option value="" disabled>
                    Select Option
                  </option>
                  {element.field_metadata.list_values.map((val, i) => (
                    <option key={i} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              )}

              {element.field_type === "checkbox" && (
                <input
                  checked={returnCheck(form[element.name]) || false}
                  name={element.name}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      [e.target.name]: returnCheck(
                        form[element.name] || false,
                        true
                      ),
                    });
                  }}
                  type="checkbox"
                  className="form-check-input itp-custom-checkbox-contact-details"
                />
              )}
            </LabelSelect>
          )}
        </div>
      ))}

      {fieldType === "" && (
        <Dropdown
          isOpen={openDropdown}
          toggle={toggle}
          className="itp-lead-details-add-field"
        >
          <DropdownToggle className="btn-light">
            {<FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />} Add
            Field
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => setFieldType("address")}
              className="dropdown-item"
            >
              {<FontAwesomeIcon icon={faMapMarkerAlt} />} Address
            </DropdownItem>
            <DropdownItem
              onClick={() => setFieldType("url")}
              className="dropdown-item"
            >
              {<FontAwesomeIcon icon={faPaperclip} />} URL
            </DropdownItem>
            <DropdownItem
              onClick={() => setFieldType("date")}
              className="dropdown-item"
            >
              {<FontAwesomeIcon icon={faCalendarAlt} />} Date
            </DropdownItem>
            <DropdownItem
              onClick={() => setFieldType("text/note")}
              className="dropdown-item"
            >
              {<FontAwesomeIcon icon={faFileText} />} Text / Note
            </DropdownItem>
            <DropdownItem
              onClick={() => setFieldType("checkbox")}
              className="dropdown-item"
            >
              {<FontAwesomeIcon icon={faCheckSquare} />} Checkbox
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}

      {fieldType !== "" && (
        <div className="itp-lead-details-new-field">
          <input
            className="itp-lead-details-new-field__input"
            defaultValue={defaultValueAddField(fieldType)}
            onChange={(e) => setNewField(e.target.value)}
          />
          {loadingAddField ? (
            <LoadingButton />
          ) : (
            <button
              onClick={addCustomField}
              className="itp-lead-details-new-field__button save"
            >
              Save
            </button>
          )}
          <button
            className="itp-lead-details-new-field__button"
            onClick={() => setFieldType("")}
            disabled={loadingAddField}
          >
            Delete
          </button>
        </div>
      )}
    </>
  );
};

export default CustomFields;
