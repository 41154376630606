import petitionDelete from "../../../../../../services/petitionDelete";

const deleteWebhookService = async (setLoadingWebhook, info, reloadData) => {
  try {
    setLoadingWebhook(true);
    await petitionDelete("webhook", { webHookId: info.pk })

    setLoadingWebhook(false);
    reloadData();
  } catch (error) {
    console.log(error)
  }
}

export default deleteWebhookService;