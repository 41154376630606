import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import ToggleImage from "../../../../../DropdownToggle/DropdownToggle";

const ResponsiveActions = ({
  classMenu,
  actions,
  element,
  toggle,
  isOpen,
  index,
}) => {
  const selectClassName = (action) => {
    if (action?.canBeDisabled && !element[action?.keyDisabled]) {
      return `disabled-action ${action?.hoverDisabledAction ? "hover-action" : ""}`
    } else {
      return ""
    }
  }

  return (
    <Dropdown isOpen={isOpen} toggle={(event) => toggle(event, index)}>
      <DropdownToggle className="nav-link">
        <ToggleImage />
      </DropdownToggle>
      <DropdownMenu className={`${classMenu || ""}`}>
        {actions.map((action, idx) => (
          <DropdownItem
            key={`${action.name}-${idx}`}
            onClick={() => action.handleClick(element, idx)}
          >
            <div className={selectClassName(action)}>
              {action.hoverDisabledAction ? (
                <>
                  <div className="action-dropdown-hover responsive">
                    <span className="action-dropdown-hover-text">
                      {action.textHover}
                    </span>
                  </div>
                  {action?.icon || ""} {action?.name || ""}
                </>
              ) : (
                <>
                  {action?.icon || ""} {action?.name || ""}
                </>
              )}
            </div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ResponsiveActions;
