// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-add-contact-subtitle {
  text-align: left;
  font-family: "Montserrat";
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-select-add-contact-label {
  text-align: left;
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-add-contact-select {
  margin-top: 6px;
  width: 90%;
}

.itp-add-contact-buttons {
  display: flex;
  gap: 8px;
}

.itp-contact-button {
  width: 4rem;
  height: 2rem;
  margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/private/Contacts/AddContact/AddContact.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,oDAAoD;EACpD,mBAAmB;EACnB,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".itp-add-contact-subtitle {\n  text-align: left;\n  font-family: \"Montserrat\";\n  letter-spacing: 0px;\n  color: #3a3a3acc;\n  opacity: 1;\n}\n\n.itp-select-add-contact-label {\n  text-align: left;\n  font: normal normal normal 12px/16px Instrument Sans;\n  letter-spacing: 0px;\n  color: #002340;\n  opacity: 1;\n}\n\n.itp-add-contact-select {\n  margin-top: 6px;\n  width: 90%;\n}\n\n.itp-add-contact-buttons {\n  display: flex;\n  gap: 8px;\n}\n\n.itp-contact-button {\n  width: 4rem;\n  height: 2rem;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
