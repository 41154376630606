// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-label-hover {
  position: relative;
  cursor: pointer;
}

.container-hover-info {
  position: absolute;
  visibility: hidden;
  background-color: #f6f6f9;
  top: -4rem;
  width: auto;
  padding: 8px;
  white-space: normal;
}

.container-label-hover:hover > .container-hover-info {
  visibility: visible;
}

.itp-contact-details-label.aligned-label {
  margin-bottom: 0.8rem;
}

.itp-contact-details-row-select {
  margin-top: 20px !important;
  margin-bottom: 1.3rem;
}

.itp-contact-details-row-select.b-bottom {
  border-bottom: 1px solid #ddd;
}

.itp-contact-details-row-select.b-bottom:focus-within {
  border-bottom: 1px solid #002340;
}
`, "",{"version":3,"sources":["webpack://./src/components/RowSelect/RowSelect.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,UAAU;EACV,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".container-label-hover {\n  position: relative;\n  cursor: pointer;\n}\n\n.container-hover-info {\n  position: absolute;\n  visibility: hidden;\n  background-color: #f6f6f9;\n  top: -4rem;\n  width: auto;\n  padding: 8px;\n  white-space: normal;\n}\n\n.container-label-hover:hover > .container-hover-info {\n  visibility: visible;\n}\n\n.itp-contact-details-label.aligned-label {\n  margin-bottom: 0.8rem;\n}\n\n.itp-contact-details-row-select {\n  margin-top: 20px !important;\n  margin-bottom: 1.3rem;\n}\n\n.itp-contact-details-row-select.b-bottom {\n  border-bottom: 1px solid #ddd;\n}\n\n.itp-contact-details-row-select.b-bottom:focus-within {\n  border-bottom: 1px solid #002340;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
