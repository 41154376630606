import { formatDateAndHour } from "../../../../../../utils/getDate";
import Skeleton from "react-loading-skeleton";

const ChatItemDate = ({ element, showUnreadMessages, loading, timeZone }) => (
  <div className="itp-chat-list-item__date-container">
    <small className="itp-chat-list-item__date">
      {(loading || element?.isFake) ? (
        <Skeleton height="1rem" width="100%" />
      ) : (
        <>{formatDateAndHour(element?.last_updated, timeZone)}</>
      )}
    </small>

    <span className={`itp-chat-list-item__unreadMessages ${!showUnreadMessages ? "v-hidden" : ""}`}>
      {!loading && !element?.isFake && (
        <>{element?.unread_messages}</>
      )}
    </span>
  </div>
)

export default ChatItemDate;