import { converDateToTimeStap, formatDateToUSDate, formatLocalTimeToTimeZoneFormatedInput, get7DaysBeforeADate } from "../../../../../utils/getDate"

const defaultDate = (myExtensionTemp, setFrom, setTo, setDate, startDateTemp, endDateTemp) => {
  const endDate  = endDateTemp || formatLocalTimeToTimeZoneFormatedInput(myExtensionTemp.time_zone)
  const startDate = startDateTemp || get7DaysBeforeADate(endDate)

  const endDateFormat = formatDateToUSDate(endDate)
  const startDateFormat = formatDateToUSDate(startDate)

  const endTimeStap = converDateToTimeStap(`${endDate} 23:59:59`)
  const startTimeStap = converDateToTimeStap(`${startDate} 00:00:00`)

  const timeFrame = { startDate: startTimeStap, endDate: endTimeStap }

  if (!startDateTemp && !endDateTemp) setDate({ timeStart: startDate, timeEnd: endDate })
  
  setFrom(startDateFormat)
  setTo(endDateFormat)

  return timeFrame
}

export default defaultDate;