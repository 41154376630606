import petitionGet from "../../../../../../services/petitionGet";

const fetchLeadDetails = async (chatWindowData) => {
  const contactId = chatWindowData.current.notIsSelf.contact.pk;
  const parameter = `?contact_id=${contactId}&lead_status=active`;
  const { data: result } = await petitionGet("leads", { parameter });
  const leadDetails = result.result[0]

  const name = leadDetails?.name || ""
  const funnel_id = leadDetails?.funnel_pipeline_stage?.funnel?.pk
  const funnel_pipeline_stage_id = leadDetails?.funnel_pipeline_stage?.pk

  const leadSource = { 
    ...leadDetails?.lead_source, 
    label: leadDetails?.lead_source?.name, 
    value: leadDetails?.lead_source?.pk, 
  } 

  const funnel = {
    ...leadDetails?.funnel_pipeline_stage?.funnel,
    label: leadDetails?.funnel_pipeline_stage?.funnel?.name,
    value: leadDetails?.funnel_pipeline_stage?.funnel?.pk,
  }

  return { leadDetails, name, leadSource, funnel, funnel_id, funnel_pipeline_stage_id }
};

export default fetchLeadDetails;
