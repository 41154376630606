import json from "../../../../../config.json"

const downloadCallService = async (element, setLoadingCall, updateAlertVariables) => {
  return new Promise(async (resolve, reject) => {
    try {
      setLoadingCall(element);

      const token = JSON.parse(localStorage.getItem("userDetails"))?.access_token;
      const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;
      const urlB = json.prod ? json.urlBase.prod : json.urlBase.dev;

      const downloadFile = async () =>
        fetch(`${urlB}/itpvoice/v2/${id}/my-extension/cdr/${element.pk}/download-recording`,
          {
            headers: {
              Authorization: "Bearer " + token.replace(/['"]+/g, ""),
            },
          }
        ).then((res) => {
          if (res.status === 200) {
            return res.blob();
          } else if (res.status === 404) {
            return res.status;
          }
        });
      const createURL = (blob) => URL.createObjectURL(blob);

      const blob = await downloadFile();
      if (blob === 404) return setLoadingCall(null), updateAlertVariables("error", "Error", "This recording is no longer available", 4000);
      const create = createURL(blob);

      let link = document.createElement("a");
      link.download = "file.mp3";
      link.href = create;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();

      setLoadingCall(null);

      resolve(true);
    } catch (error) {
      setLoadingCall(null);
      reject(error);
    }
  });
};

export default downloadCallService;
