import editUserService from "./editUserService/editUserService";
import fetchUserDetails from "./fetchUserDetails/fetchUserDetails";
import fetchIntercomAdmin from "./fetchIntercomAdmin/fetchIntercomAdmin";
import fetchNumbers from "./fetchNumbers/fetchNumbers";
import fetchAccountDetails from "./fetchAccountDetails/fetchAccountDetails";
import fetchDevicesAdmin from "./fetchDevicesAdmin/fetchDevicesAdmin";
import fetchVoicemailData from "./fetchVoicemailData/fetchVoicemailData";
import editSwitchsCallForwardingService from "./editSwitchsCallForwardingService/editSwitchsCallForwardingService";
import editSwitchsCallerIdService from "./editSwitchsCallerIdService/editSwitchsCallerIdService";
import editSwitchsCallRecordingService from "./editSwitchsCallRecordingService/editSwitchsCallRecordingService";
import editOtherSwitchsService from "./editOtherSwitchsService/editOtherSwitchsService";
import editSwitchsVoicemailsService from "./editSwitchsVoicemailsService/editSwitchsVoicemailsService";
import editTimeZoneService from "./editTimeZoneService/editTimeZoneService";
import resetPasswordService from "./resetPasswordService/resetPasswordService";

export {
  editUserService,
  fetchUserDetails,
  fetchIntercomAdmin,
  fetchNumbers,
  fetchAccountDetails,
  fetchDevicesAdmin,
  fetchVoicemailData,
  editSwitchsCallForwardingService,
  editSwitchsCallerIdService,
  editSwitchsCallRecordingService,
  editOtherSwitchsService,
  editSwitchsVoicemailsService,
  editTimeZoneService,
  resetPasswordService,
};
