import { Col, Row } from "reactstrap";
import { AudioMessage, CollapsePanel, LoadingButton, MenuRight } from "../../../../../../../../components";
import Select from "react-select";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseIcon from "@mui/icons-material/Pause";
import Skeleton from "react-loading-skeleton";

const AdvancedCallflows = ({
  advanceCallFlows,
  activeConfirmModal,
  handleChangeForm,
  form,
  handleBlurInputs,
  downloadVoicemail,
  activeLoadVoicemail,
  openMenu,
  closeMenu,
  showLoadVoicemail,
  setUsersSelected,
  usersSelected,
  allUsers,
  saveNumbers,
  loadingNumber,
  onChangeOptionLoadVoicemail,
  selectedOptionVoicemail,
  reload,
  setReload,
  inputRef,
  saveLoadVoicemail,
  loadingVoicemail,
  recording,
  startRecording,
  stopRecording,
  audioBlob,
  audioUrl,
  loading,
}) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <CollapsePanel
      title="Advanced Callflow"
      subtitle="Manage the way this number behaves when calling it"
    >
      {advanceCallFlows ? (
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Call flow</label>
          </div>
          <button
            onClick={activeConfirmModal}
            className="btn-primary itp-edit-number-button"
            style={{ width: "14rem" }}
          >
            Deactivate Advanced Callflow
          </button>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <label className="itp-edit-number-label-1">Call flow</label>
              <label className="itp-edit-number-label-2">
                Manage the way this number behaves when calling it
              </label>
            </div>
            <button
              onClick={activeConfirmModal}
              className="btn-primary itp-edit-number-button-2"
              style={{ width: "14rem" }}
            >
              Activate Advanced Callflow
            </button>
          </div>

          <Row className="mt-4">
            <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
              <div className="d-flex flex-column">
                <label className="itp-edit-number-label-1">Ring Strategy</label>
                <label className="itp-edit-number-label-2">
                  Manage how users assigned are dialed
                </label>
              </div>
            </Col>
            <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
              {loading ? (
                <div className="itp-edit-number-select ms-4">
                  <Skeleton height="3rem" width="100%" />
                </div>
              ) : (
                <select
                  onChange={handleChangeForm}
                  value={form.ring_strategy}
                  name="ring_strategy"
                  className="form-select-itp itp-edit-number-select ms-4"
                >
                  <option value="" disabled>
                    Select Option
                  </option>
                  <option value="ringall">Ring all</option>
                  <option value="random">Random</option>
                  <option value="linear">Linear</option>
                </select>
              )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
              <div className="d-flex flex-column">
                <label className="itp-edit-number-label-1">Timeout</label>
                <label className="itp-edit-number-label-2">
                  Number of seconds before going to voicemail
                </label>
              </div>
            </Col>
            <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
              {loading ? (
                <div className="itp-edit-number-input ms-2">
                  <Skeleton height="2rem" width="100%" />
                </div>
              ) : (
                <input
                  placeholder="Add Timeout"
                  type="number"
                  className="form-control-itp itp-edit-number-input ms-2"
                  name="timeout"
                  value={form.timeout.toString()}
                  onChange={handleChangeForm}
                  onBlur={() => handleBlurInputs("timeout")}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </CollapsePanel>

    {!advanceCallFlows && (
      <>
        <br></br>
        <div>
          <Row>
            <Col>
              <div className="itp-my-settings-separator-line"></div>
            </Col>
          </Row>
        </div>
        <br></br>

        <CollapsePanel
          title="Voicemail"
          subtitle="Manage which voicemail calls will reach if no user answers the call"
        >
          <Row className="d-flex align-items-center">
            <Col 
              xs={loading ? 12 : 10} 
              sm={loading ? 12 : 10} 
              md={loading ? 12 : 10} 
              lg={loading ? 12 : 10} 
              xl={loading ? 12 : 10} 
              xxl={loading ? 12 : 10}
            > 
              {loading ? (
                <Skeleton height="3rem" width="100%" />
              ) : (
                <AudioMessage
                  audioUrl={"url"}
                  duration={0}
                  icon={true}
                  hiddenTime={true}
                  w100={true}
                  noBorderContainer={true}
                  outVolume={true}
                  ml0={true}
                  styles={{ marginLeft: "-12px" }}
                  downloadWhenClicked={true}
                  customDownload={downloadVoicemail}
                  audioType="audio/wav"
                />
              )}
            </Col>

            {!loading && (
              <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                <button
                  onClick={activeLoadVoicemail}
                  className="btn-primary"
                  style={{ marginLeft: "40px" }}
                >
                  Modify
                </button>
              </Col>
            )}
          </Row>
        </CollapsePanel>
      </>
    )}

    <MenuRight
      show={openMenu}
      closeMenu={closeMenu}
      title={!showLoadVoicemail ? "Add Users" : "Load Voicemail"}
      to="to Edit Phone Number"
    >
      {openMenu && !showLoadVoicemail && (
        <>
          <label className="itp-label-input__label">Select Users</label>
          <Select
            isMulti
            onChange={(e) => {
              setUsersSelected(e);
            }}
            className="basic-single itp-add-contact-manually-select"
            classNamePrefix="select"
            value={usersSelected}
            isSearchable={true}
            name="contact_list_id"
            placeholder="Select Option"
            options={allUsers}
          />
          <button
            className="btn-primary loading add-lead-new-contact"
            disabled={usersSelected.length === 0 ? true : false}
            onClick={saveNumbers}
          >
            {loadingNumber && <LoadingButton />}
            Save
          </button>
        </>
      )}

      {openMenu && showLoadVoicemail && (
        <>
          <label className="itp-edit-number-label-1">
            Select the option to save a voicemail
          </label>
          <select
            onChange={onChangeOptionLoadVoicemail}
            value={selectedOptionVoicemail}
            className="form-select-itp"
          >
            <option value="" disabled>
              Select Option
            </option>
            <option value="upload">Upload File</option>
            <option value="record">Record Sound</option>
          </select>

          {selectedOptionVoicemail === "upload" && (
            <>
              <Col
                className="d-flex col-12 pt-4"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    style={{
                      color: "white",
                      marginRight: "1rem",
                      marginBottom: "0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "8rem",
                    }}
                    className="btn-primary"
                    htmlFor="input_file"
                  >
                    Browse Files
                  </label>

                  <input
                    onChange={() => {
                      setTimeout(() => {
                        setReload(reload + 1);
                      }, [100]);
                    }}
                    id="input_file"
                    className="d-none form-control"
                    type="file"
                    accept="audio/mp3,audio/wav"
                    ref={inputRef}
                  />
                  <span>Only MP3 or WAV Files Are Allowed</span>
                </div>
                <label className="mt-3 itp-label-input__label itp-analytics-label-select">
                  {inputRef.current && inputRef.current.files.length !== 0 && inputRef.current.files[0].name}
                </label>
              </Col>
              <button
                onClick={saveLoadVoicemail}
                className="btn-primary itp-lead-detail-button-save loading mb-4"
                disabled={!inputRef.current || inputRef.current.files.length === 0 || !inputRef.current.files[0].name || loadingVoicemail}
              >
                {loadingVoicemail && <LoadingButton />}
                Save
              </button>
            </>
          )}

          {selectedOptionVoicemail === "record" && (
            <>
              <label className="itp-label-input__label itp-analytics-label-select">
                To start recording click on play button and to stop click on
                pause
              </label>
              <div>
                {!recording ? (
                  <PlayCircleOutlineIcon
                    style={{ cursor: "pointer" }}
                    onClick={startRecording}
                  />
                ) : (
                  <PauseIcon
                    style={{ cursor: "pointer" }}
                    onClick={stopRecording}
                  />
                )}
                {audioBlob && (
                  <div className="mt-2">
                    <audio controls src={audioUrl}></audio>
                  </div>
                )}
              </div>
              <button
                onClick={saveLoadVoicemail}
                className="btn-primary itp-lead-detail-button-save loading mb-4"
                disabled={!audioBlob || !audioUrl || loadingVoicemail}
              >
                {loadingVoicemail && <LoadingButton />}
                Save
              </button>
            </>
          )}
        </>
      )}
    </MenuRight>
  </>
);

export default AdvancedCallflows;
