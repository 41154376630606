// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-stages-drag-icon {
  color: #437097;
}

.itp-stages-delete-icon {
  margin-top: -8px;
}

.itp-stages-add-container {
  background: #0023400a 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #85858529;
  border: 0.30000001192092896px solid #0023405c;
  border-radius: 8px;
  opacity: 1;
  padding: 8px 8px 12px 8px;
  margin-bottom: 2rem;
}

.itp-stage-add-title {
  font: normal normal 600 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-stage-add-label {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.btn-primary.itp-stage-add-button {
  width: 8rem;
}

.itp-stages-add-container.step2 {
  background: #a2c7e630 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #85858529;
  border: 0.30000001192092896px solid #0023405c;
  border-radius: 8px;
  opacity: 1;
  padding: 8px 8px 12px 8px;
  margin-bottom: -8px;
}

.form-control-itp.itp-label-input__input.itp-add-stage-input {
  background-color: transparent;
}

.itp-add-stage-buttons {
  display: flex;
  gap: 8px;
  margin-bottom: 2rem;
}

.form-select-itp.itp-add-stage-select {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/Funnels/Stages/Stages.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iDAAiD;EACjD,iCAAiC;EACjC,6CAA6C;EAC7C,kBAAkB;EAClB,UAAU;EACV,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,iDAAiD;EACjD,mBAAmB;EACnB,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iDAAiD;EACjD,iCAAiC;EACjC,6CAA6C;EAC7C,kBAAkB;EAClB,UAAU;EACV,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".itp-stages-drag-icon {\n  color: #437097;\n}\n\n.itp-stages-delete-icon {\n  margin-top: -8px;\n}\n\n.itp-stages-add-container {\n  background: #0023400a 0% 0% no-repeat padding-box;\n  box-shadow: 0px 0px 6px #85858529;\n  border: 0.30000001192092896px solid #0023405c;\n  border-radius: 8px;\n  opacity: 1;\n  padding: 8px 8px 12px 8px;\n  margin-bottom: 2rem;\n}\n\n.itp-stage-add-title {\n  font: normal normal 600 14px/19px Instrument Sans;\n  letter-spacing: 0px;\n  color: #002340;\n  opacity: 1;\n}\n\n.itp-stage-add-label {\n  font: normal normal normal 14px/19px Instrument Sans;\n  letter-spacing: 0px;\n  color: #3a3a3acc;\n  opacity: 1;\n}\n\n.btn-primary.itp-stage-add-button {\n  width: 8rem;\n}\n\n.itp-stages-add-container.step2 {\n  background: #a2c7e630 0% 0% no-repeat padding-box;\n  box-shadow: 0px 0px 6px #85858529;\n  border: 0.30000001192092896px solid #0023405c;\n  border-radius: 8px;\n  opacity: 1;\n  padding: 8px 8px 12px 8px;\n  margin-bottom: -8px;\n}\n\n.form-control-itp.itp-label-input__input.itp-add-stage-input {\n  background-color: transparent;\n}\n\n.itp-add-stage-buttons {\n  display: flex;\n  gap: 8px;\n  margin-bottom: 2rem;\n}\n\n.form-select-itp.itp-add-stage-select {\n  width: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
