import Skeleton from "react-loading-skeleton";
import { renderCell } from "../../../../utils";

const TableRowResponsive = ({
  allData,
  element,
  getBorderColor,
  borderColumnName,
  colorColumnName,
  getTextColor,
  columnConfig,
  renderCallStatus,
  loading,
  column
}) => {
  return (
    <div 
      className="column-value"
      style={{
        // here you put the left border or text color to a column
        borderLeft: (column.name === borderColumnName && !loading) ? `6px solid ${getBorderColor(element)}` : '',
        color: (column.name === colorColumnName && !loading) ? getTextColor(element) : '',
      }}
    >
      {loading ? (
        <Skeleton height="1.5rem" width="10rem" />
      ) : (
        <>
          {renderCell(element, column, columnConfig[column?.name], allData, renderCallStatus)}
        </>
      )}
    </div>
  )
}

export default TableRowResponsive;