// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-table-container-allf {
    width: 991px!important;
    /* overflow: inherit!important; */
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/Users/Users.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,iCAAiC;IACjC,YAAY;AAChB","sourcesContent":[".itp-table-container-allf {\n    width: 991px!important;\n    /* overflow: inherit!important; */\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
