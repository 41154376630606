// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-custom-table-main-th-styles {
  text-align: left;
  font-family: Montserrat;
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomTableMain/Components/ColumnHeader/ColumnHeader.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,WAAW;EACX,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".itp-custom-table-main-th-styles {\n  text-align: left;\n  font-family: Montserrat;\n  color: #000;\n  font-size: 12px;\n  text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
