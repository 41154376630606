// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row.itp-timeGroupDetails-addEditTimeDate-clock-row {
  margin-top: -2.5rem;
  margin-bottom: 2rem;
}

.clock-container {
  display: inline-flex;
  align-items: center;
  color: #333;
  font-family: "Montserrat";
  font-size: 1rem;
}

.clock-icon {
  margin-right: 4px;
  margin-left: 1rem;
}

.clock-time {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/AdvancedCallFlows/components/TimeGroupsDetails/components/AddEditTimeDate/AddEditTimeDate.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,WAAW;EACX,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".row.itp-timeGroupDetails-addEditTimeDate-clock-row {\n  margin-top: -2.5rem;\n  margin-bottom: 2rem;\n}\n\n.clock-container {\n  display: inline-flex;\n  align-items: center;\n  color: #333;\n  font-family: \"Montserrat\";\n  font-size: 1rem;\n}\n\n.clock-icon {\n  margin-right: 4px;\n  margin-left: 1rem;\n}\n\n.clock-time {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
