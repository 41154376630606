import { useNavigate } from "react-router-dom";
import { useMenuDispatch } from "../contexts/MenuContext";
import "./index.css"

const NotFoundPage = () => {
  const navigate = useNavigate()
  const dispatchMenu = useMenuDispatch();

  const backToDashboard = () => {
    dispatchMenu({ type: "SET_DEFAULT_MENU" });
    navigate("/Dashboard");
  }

  return (
    <div className="itp-page-not-found">
      <h1>404 - Page not found</h1>
      <p>The page you are looking for does not exist.</p>

      <button onClick={backToDashboard} className="btn-primary itp-not-found-button">Back To Dashboard</button>
    </div>
  );
};

export default NotFoundPage;
