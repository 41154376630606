// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.itp-analytics-card-report {
  padding: 12px;
}

.itp-analytics-title {
  font: normal normal 600 18px/24px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
}

.itp-analytics-button-report {
  width: 7rem;
  height: 2rem;
}

.itp-analytics-label {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
}

.itp-analytics-description {
  font: normal normal normal 13px/18px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-analytics-container-select-report {
  background: #0023400a 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #85858529;
  border: 0.30000001192092896px solid #0023405c;
  border-radius: 8px;
  opacity: 1;
  padding: 12px 12px 0px 12px;
}

.itp-analytics-label-select {
  font: normal normal normal 14px/19px Instrument Sans !important;
  letter-spacing: 0px !important;
  color: #3a3a3acc !important;
  opacity: 1 !important;
}

.itp-w-buton {
  width: 7.5rem;
}

.itp-w-entire {
  width: 1750px!important;
  /* width: 100%!important; */
}

.itp-alone-property {
  width: 98%!important;
  margin-left: 12px!important;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/Analytics/Analytics.css"],"names":[],"mappings":";AACA;EACE,aAAa;AACf;;AAEA;EACE,iDAAiD;EACjD,mBAAmB;EACnB,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,iDAAiD;EACjD,iCAAiC;EACjC,6CAA6C;EAC7C,kBAAkB;EAClB,UAAU;EACV,2BAA2B;AAC7B;;AAEA;EACE,+DAA+D;EAC/D,8BAA8B;EAC9B,2BAA2B;EAC3B,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,oBAAoB;EACpB,2BAA2B;AAC7B","sourcesContent":["\n.itp-analytics-card-report {\n  padding: 12px;\n}\n\n.itp-analytics-title {\n  font: normal normal 600 18px/24px Instrument Sans;\n  letter-spacing: 0px;\n  color: #3a3a3a;\n  opacity: 1;\n}\n\n.itp-analytics-button-report {\n  width: 7rem;\n  height: 2rem;\n}\n\n.itp-analytics-label {\n  font: normal normal normal 14px/19px Instrument Sans;\n  letter-spacing: 0px;\n  color: #3a3a3a;\n  opacity: 1;\n}\n\n.itp-analytics-description {\n  font: normal normal normal 13px/18px Instrument Sans;\n  letter-spacing: 0px;\n  color: #3a3a3acc;\n  opacity: 1;\n}\n\n.itp-analytics-container-select-report {\n  background: #0023400a 0% 0% no-repeat padding-box;\n  box-shadow: 0px 0px 6px #85858529;\n  border: 0.30000001192092896px solid #0023405c;\n  border-radius: 8px;\n  opacity: 1;\n  padding: 12px 12px 0px 12px;\n}\n\n.itp-analytics-label-select {\n  font: normal normal normal 14px/19px Instrument Sans !important;\n  letter-spacing: 0px !important;\n  color: #3a3a3acc !important;\n  opacity: 1 !important;\n}\n\n.itp-w-buton {\n  width: 7.5rem;\n}\n\n.itp-w-entire {\n  width: 1750px!important;\n  /* width: 100%!important; */\n}\n\n.itp-alone-property {\n  width: 98%!important;\n  margin-left: 12px!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
