import petitionPatch from "../../../../../../services/petitionPatch";

const handleChangeQuestionListService = async (e, number, questionListValue, setQuestionListValue, updateAlertVariables) => {
  const currentQuestionListValue = questionListValue;

  try {
    setQuestionListValue(e);

    const data = {
      ai_lead_manager_screening_questions_list_id: e.pk,
    };

    await petitionPatch("numberEdit", { number, data });
  } catch (error) {
    console.log(error);
    setQuestionListValue(currentQuestionListValue);
    updateAlertVariables("error", "Error", "An error has ocured", 3000);
  }
};

export default handleChangeQuestionListService;
