// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font: normal normal 600 18px/24px Instrument Sans;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  letter-spacing: 0px;
  color: #fff;
}

.message {
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
  font: normal normal normal 15px/20px Instrument Sans;
}
`, "",{"version":3,"sources":["webpack://./src/components/AlertNotificactions/AlertNotificactions.css"],"names":[],"mappings":"AAAA;EACE,iDAAiD;EACjD,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,wCAAwC;EACxC,oBAAoB;EACpB,qBAAqB;EACrB,oDAAoD;AACtD","sourcesContent":[".title {\n  font: normal normal 600 18px/24px Instrument Sans;\n  font-weight: 600 !important;\n  font-size: 18px !important;\n  line-height: 24px !important;\n  letter-spacing: 0px;\n  color: #fff;\n}\n\n.message {\n  background-color: transparent !important;\n  margin: 0 !important;\n  padding: 0 !important;\n  font: normal normal normal 15px/20px Instrument Sans;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
