import petitionPatch from "../../../../../../services/petitionPatch";

const updateContactTagsService = async (newValue, element, chatList) => {
  try {
    const data = { tags: newValue.map((tag) => tag.pk) };
    const contact_id = element?.notIsSelf?.contact?.pk;
    
    const { data: result } = await petitionPatch("contact", { data, contact_id })

    const newChatList = chatList.current.map((chat) => {
        if (chat.pk === element.pk) {
          chat.notIsSelf.contact = {
            ...chat?.notIsSelf?.contact,
            ...result?.result,
          };
        }
        return chat;
      });

      return newChatList;
  } catch (error) {
    console.log(error)
  }
}

export default updateContactTagsService;