import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AddButton, CollapsePanel, CustomTableMain, LoadingButton, MenuRight, SearchBoxFilter } from "../../../../components";
import getQueryString from "../../../../utils/getQueryString";
import ModalDelete from "../ModalUtility/ModalDelete";
import petitionGet from "../../../../services/petitionGet";
import petitionPost from "../../../../services/petitionPost";
import petitionPatch from "../../../../services/petitionPatch";
import petitionDelete from "../../../../services/petitionDelete";

const FrequentlyAsked = ({ accountDetails, loading }) => {
  const filterSearchfaqs = useRef("");
  const faqsPagination = useRef({
    offset: 0,
    limit: 10,
  });

  const [loadingFaqs, setLoadingFaqs] = useState(false);
  const [loadingPagItems, setLoadingPagItems] = useState(false)
  const [faqsAllResult, setFaqsAllResult] = useState({});
  const [info, setInfo] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [showAddOrEditFrequentlyAsked, setShowAddOrEditFrequentlyAsked] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [loadingButtonMenu, setLoadingButtonMenu] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [searchValue, setSearchValue] = useState("")

  const [form, setForm] = useState({
    question: "",
    answer: "",
  });

  const [dataTableFrequentlyAsked, setDataTableFrequentlyAsked] = useState({
    columns: [{ name: "Question", key: "question" }],
    actions: { title: "Actions", content: [] }
  });

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const closeMenu = () => {
    setShowAddOrEditFrequentlyAsked(false);
    setInfo(null);
    setModalDelete(false);
    setOpenMenu(false);
    setForm({
      question: "",
      answer: "",
    });
  };

  const onChangeFilterFrequentlyAsked = async (e) => {
    if (loading || loadingFaqs || loadingPagItems) return

    const { value } = e.target
    setSearchValue(value)

    const paginationTemp = { offset: 0, limit: 10 };

    const filter = !value ? "" : `&search=${value}`;

    const parameter = getQueryString(paginationTemp) + filter;

    await petitionfaqs(parameter);
    faqsPagination.current = paginationTemp;
    filterSearchfaqs.current = filter;
  };

  const faqsChangePage = async (offset) => {
    if (loading || loadingFaqs || loadingPagItems) return

    setLoadingPagItems(true)

    const paginationTemp = {
      offset: offset,
      limit: faqsPagination.current.limit,
    };

    const parameter = getQueryString(paginationTemp) + filterSearchfaqs.current;
    await petitionfaqs(parameter);
    faqsPagination.current = paginationTemp;
  };

  const activeAddOrEditFrequentlyAsked = (element) => {
    if (element) {
      setInfo(element);
      setForm({
        ...form,
        name: element.name,
        question: element.question,
        answer: element?.answer || "",
      });
    }
    setOpenMenu(true);
    setShowAddOrEditFrequentlyAsked(true);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const addOrEditFrequentlyAsked = () => {
    setLoadingButtonMenu(true);

    const data = {
      question: form.question,
      answer: form.answer,
    };

    const parameter =
      getQueryString(faqsPagination.current) + filterSearchfaqs.current;

    if (!info) {
      petitionPost("faqs", { data })
        .then(({ data: result }) => {
          petitionfaqs(parameter);
        })
        .catch((error) => console.log(error));
    } else {
      petitionPatch("faqs", { data, faqs_id: info.pk })
        .then(({ data: result }) => {
          petitionfaqs(parameter);
        })
        .catch((error) => console.log(error));
    }
  };

  const deleteFrequentlyAsked = () => {
    setLoadingModal(true)
    petitionDelete("faqs", { faqs_id: info.pk })
      .then(({ data: result }) => {
        let parameter = ""
        
        if (faqsAllResult.result.length === 1 && faqsPagination.current.offset > 0) {
          const paginationTemp = {
            offset: faqsPagination.current.offset - faqsPagination.current.limit,
            limit: 10
          }
          parameter = getQueryString(paginationTemp) + filterSearchfaqs.current
          faqsPagination.current = paginationTemp
        } else {
          parameter = getQueryString(faqsPagination.current) + filterSearchfaqs.current
        }

        petitionfaqs(parameter)
      })
      .catch((error) => console.log(error))
  }

  const petitionfaqs = async (parameter, loading) => {
    if (loading) setLoadingFaqs(true);
    await petitionGet("faqs", { parameter })
      .then(({ data: result }) => {
        setFaqsAllResult(result);

        setDataTableFrequentlyAsked({
          columns: [{ name: "Question", key: "question" }],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeAddOrEditFrequentlyAsked,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeModalDelete,
              },
            ],
          },
        });

        setLoadingButtonMenu(false);
        setLoadingFaqs(false);
        setLoadingPagItems(false)
        setLoadingModal(false);
        closeMenu();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (accountDetails?.ai_enabled) {
      petitionfaqs(getQueryString(faqsPagination.current), true);
    }
  }, []);

  return (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingModal}
          modalFunction={deleteFrequentlyAsked}
          module="Frequently asked Question"
        />
      )}

      <br></br>
        <div>
          <Row>
            <Col style={{ zIndex: "-1 " }}>
              <div className="itp-my-settings-separator-line"></div>
            </Col>
          </Row>
        </div>
      <br></br>

      <CollapsePanel
        title="Frequently asked Questions"
        subtitle="Specific questions and answers related to your company products or services"
      >
        <CustomTableMain
          data={dataTableFrequentlyAsked}
          isBackend={true}
          loading={loading || loadingFaqs}
          loadingPagItems={loadingPagItems}
          paginationData={10}
          totalPages={faqsAllResult?.total_pages}
          onPageChange={faqsChangePage}
          offset={faqsPagination.current.offset}
          m0={true}
          module="frequently asked questions"
          actionsLeft={[
            {
              component: (
                <SearchBoxFilter 
                  placeholder="Search Frequently asked Questions"
                  handleChange={onChangeFilterFrequentlyAsked}
                  value={searchValue}
                />
              )
            }
          ]}
          actionsRight={[
            {
              component: (
                <AddButton 
                  handleClick={() => {
                    if (loading || loadingFaqs || loadingPagItems) return;
                    activeAddOrEditFrequentlyAsked()
                  }}
                  name="Add FAQ"
                />
              )
            }
          ]}
          moduleNoData="Frequently Asked Questions"
        />
      </CollapsePanel>

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Frequently Asked"
        to=""
        loading={loadingModal}
      >
        {openMenu && showAddOrEditFrequentlyAsked && (
          <>
            <Row className="itp-container-label-input">
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                xxl={5}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label">Question</label>
              </Col>
              <Col
                xs={7}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                className="itp-label-input-col"
              >
                <input
                  type="text"
                  placeholder=": Add question"
                  className="form-control-itp itp-label-input__input"
                  name="question"
                  value={form.question}
                  onChange={handleChangeForm}
                />
              </Col>
            </Row>
            <div className="d-flex flex-column gap-2">
              <label className="itp-label-input__label">Answer</label>

              <textarea
                value={form.answer}
                name="answer"
                onChange={handleChangeForm}
              />
            </div>

            <button
              onClick={addOrEditFrequentlyAsked}
              className="btn-primary itp-lead-detail-button-save loading mb-4"
              disabled={!form.question || !form.answer}
            >
              {loadingButtonMenu && <LoadingButton />}
              Save
            </button>
          </>
        )}
      </MenuRight>
    </>
  );
};

export default FrequentlyAsked;
