// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-container {
  position: relative;
  display: inline-block;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 4;
  background-color: #fff;
}

.select-option {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  justify-content: space-between;
}

.select-option:hover {
  background-color: #f5f5f5;
}

.select-option input[type="checkbox"] {
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SelectSingleCheckbox/SelectSingleCheckbox.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;EAChB,0BAA0B;EAC1B,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".select-container {\n  position: relative;\n  display: inline-block;\n}\n\n.select-options {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  width: 100%;\n  max-height: 200px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  border: 1px solid #ccc;\n  border-top: none;\n  border-radius: 0 0 4px 4px;\n  z-index: 4;\n  background-color: #fff;\n}\n\n.select-option {\n  display: flex;\n  align-items: center;\n  padding: 8px;\n  cursor: pointer;\n  justify-content: space-between;\n}\n\n.select-option:hover {\n  background-color: #f5f5f5;\n}\n\n.select-option input[type=\"checkbox\"] {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
