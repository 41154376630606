import handleChangeSwitchs from "./handleChangeSwitchs/handleChangeSwitchs";
import handleChangeForms from "./handleChangeForms/handleChangeForms";
import processForm from "./processForm/processForm";
import startRecordSound from "./startRecordSound/startRecordSound";
import convertArrBufferToBase64 from "./convertArrBufferToBase64/convertArrBufferToBase64";

export {
  handleChangeSwitchs,
  handleChangeForms,
  processForm,
  startRecordSound,
  convertArrBufferToBase64,
};
