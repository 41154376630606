import React, { useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import "./ShowFilterButton.css";

const ShowFilterButton = ({ handleClick, activeFilters, className }) => {
  const [showFiltersActive, setShowFiltersActive] = useState(false);

  const showHoverFilters = () => {
    setShowFiltersActive(true);
  };

  const hiddenHoverFilters = () => {
    setShowFiltersActive(false);
  };

  const activeFilterKeys = activeFilters ? Object.keys(activeFilters) : [];

  return (
    <button
      data-tip
      data-for={`text1 ${showFiltersActive ? "" : "v-hidden"}`}
      onClick={handleClick}
      className={`btn-light itp-show-filter-button ${className || ""}`}
      onMouseOver={showHoverFilters}
      onMouseOut={hiddenHoverFilters}
    >
      <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />
      Filters{" "}
      {activeFilters && activeFilterKeys.length > 0 && (
        <>
          <span>{`(${activeFilterKeys.length})`}</span>
          {activeFilters && activeFilterKeys.length > 0 && (
            <ReactTooltip id={`text1 ${showFiltersActive ? "" : "v-hidden"}`}>
              {activeFilterKeys.map((key) => (
                <div
                  style={{
                    gap: "10px",
                  }}
                  className="d-flex align-items-center"
                  key={key}
                >
                  <label>{key}:</label>
                  <label>{activeFilters[key]}</label>
                </div>
              ))}
            </ReactTooltip>
          )}
        </>
      )}
    </button>
  );
};

export default ShowFilterButton;
