// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actions-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
}

@media (min-width: 939px) {
  .actions-container {
    gap: 1rem;
  }
}

.actions-left {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.actions-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (min-width: 924px) and (max-width: 1067px) {
  .actions-right {
    margin-left: auto;
  }
}

@media (min-width: 1196px) {
  .actions-right {
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1067px) {
  .actions-right {
    margin-left: 0;
    align-items: stretch; /* Valor predeterminado de flex items, cambialo según necesites */
    display: flex;
    gap: 10px;
  }
}

@media (max-width: 834px) {
  .actions-right {
    margin-left: 0;
    align-items: stretch; /* Valor predeterminado de flex items, cambialo según necesites */
    display: flex;
    gap: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomTableMain/Components/Actions/Actions.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE;IACE,SAAS;EACX;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,cAAc;IACd,oBAAoB,EAAE,iEAAiE;IACvF,aAAa;IACb,SAAS;EACX;AACF;;AAEA;EACE;IACE,cAAc;IACd,oBAAoB,EAAE,iEAAiE;IACvF,aAAa;IACb,SAAS;EACX;AACF","sourcesContent":[".actions-container {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  margin-bottom: 1rem;\n}\n\n@media (min-width: 939px) {\n  .actions-container {\n    gap: 1rem;\n  }\n}\n\n.actions-left {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  flex-wrap: wrap;\n}\n\n.actions-right {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n@media (min-width: 924px) and (max-width: 1067px) {\n  .actions-right {\n    margin-left: auto;\n  }\n}\n\n@media (min-width: 1196px) {\n  .actions-right {\n    margin-left: auto;\n  }\n}\n\n@media (min-width: 992px) and (max-width: 1067px) {\n  .actions-right {\n    margin-left: 0;\n    align-items: stretch; /* Valor predeterminado de flex items, cambialo según necesites */\n    display: flex;\n    gap: 10px;\n  }\n}\n\n@media (max-width: 834px) {\n  .actions-right {\n    margin-left: 0;\n    align-items: stretch; /* Valor predeterminado de flex items, cambialo según necesites */\n    display: flex;\n    gap: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
