import petitionGet from "../../../../../services/petitionGet";

const fetchLeadDetails = async (e, setOpenMenu, setLeadDetails, setLoadingMenu) => {
  try {
    setOpenMenu(true)
    setLoadingMenu(true)
    const { data: result } = await petitionGet("leads", { parameter: `/${e.pk}` })

    setLeadDetails(result.result)
    setLoadingMenu(false)
  } catch (error) {
    console.log(error)
  }
}

export default fetchLeadDetails;