import petitionPost from "../../../../services/petitionPost"

const addTagService = async (tag, newTags, setNewTags, setDisabled) => {
  try {
    const data = {
      name: tag.inputValue,
      tag_color: tag?.tag_color || "",
    }

    const { data: result } = await petitionPost("addTags", { data })
    const newTag = result.result
    const newArrayTags = [...newTags, { ...newTag }]
    setDisabled(false)
    setNewTags(newArrayTags);
  } catch (error) {
    console.log(error)
  }
}

export default addTagService;