import { processHistory } from "../../utils";
import petitionGet from "../../../../services/petitionGet";

const fetchCallHistory = async (contactId, myExtension, query) => {
  const parameter = query + `&contact_id=${contactId}`
  const { data: result } = await petitionGet("contactHistory", { parameter })

  const timeZone = myExtension.time_zone
  const processedHistory = processHistory(result.result, timeZone)

  return { processedHistory, result }
}

export default fetchCallHistory;