// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resizable-vertical {
  resize: vertical;
  overflow: auto;
  min-height: auto;
  height: 400px;
  max-height: 100%; /* Altura máxima inicial */
  scrollbar-width: thin; /* Para Firefox */
  scrollbar-color: #888 #f1f1f1; /* Para Firefox */
}

/* Estilos del scrollbar para navegadores basados en WebKit (Chrome, Safari) */
.resizable-vertical::-webkit-scrollbar {
  width: 12px;
}

.resizable-vertical::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.resizable-vertical::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.resizable-vertical::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Estilos del scrollbar para Firefox */
.resizable-vertical {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* Estilos del scrollbar para Internet Explorer y Edge */
`, "",{"version":3,"sources":["webpack://./src/pages/private/LivePanel/components/Extensions/components/UsersTable/UsersTable.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,gBAAgB,EAAE,0BAA0B;EAC5C,qBAAqB,EAAE,iBAAiB;EACxC,6BAA6B,EAAE,iBAAiB;AAClD;;AAEA,8EAA8E;AAC9E;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA,uCAAuC;AACvC;EACE,qBAAqB;EACrB,6BAA6B;AAC/B;;AAEA,wDAAwD","sourcesContent":[".resizable-vertical {\n  resize: vertical;\n  overflow: auto;\n  min-height: auto;\n  height: 400px;\n  max-height: 100%; /* Altura máxima inicial */\n  scrollbar-width: thin; /* Para Firefox */\n  scrollbar-color: #888 #f1f1f1; /* Para Firefox */\n}\n\n/* Estilos del scrollbar para navegadores basados en WebKit (Chrome, Safari) */\n.resizable-vertical::-webkit-scrollbar {\n  width: 12px;\n}\n\n.resizable-vertical::-webkit-scrollbar-track {\n  background: #f1f1f1;\n  border-radius: 10px;\n}\n\n.resizable-vertical::-webkit-scrollbar-thumb {\n  background: #888;\n  border-radius: 10px;\n}\n\n.resizable-vertical::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n\n/* Estilos del scrollbar para Firefox */\n.resizable-vertical {\n  scrollbar-width: thin;\n  scrollbar-color: #888 #f1f1f1;\n}\n\n/* Estilos del scrollbar para Internet Explorer y Edge */\n@-ms-viewport {\n  scrollbar-face-color: #888;\n  scrollbar-shadow-color: #f1f1f1;\n  scrollbar-highlight-color: #f1f1f1;\n  scrollbar-3dlight-color: #f1f1f1;\n  scrollbar-darkshadow-color: #f1f1f1;\n  scrollbar-track-color: #f1f1f1;\n  scrollbar-arrow-color: #888;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
