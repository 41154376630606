import petitionGet from "../../../../../services/petitionGet";

const searchSourceService = async (inputValue, callback) => {
  try {
    const parameter = inputValue ? `?name=${inputValue}` : "?offset=0&limit=10";
    const { data: result } = await petitionGet("sources", { parameter });

    result.result.forEach((element) => {
      element.label = `${element?.name || ""}`;
      element.value = element?.pk || null;
    });

    callback(result.result);
  } catch (error) {
    console.log(error);
  }
};

export default searchSourceService;
