import { useState } from "react";
import Skeleton from "react-loading-skeleton";

const Image = ({ element, urlBase, api_id, token, handleClick, showFullScreen, handleClose }) => {
  const showImage = element?.message_mms_media && element?.mms_file_type !== "vcf" && element?.item_type !== "call" && !element?.audio_message ? true : false
  const showImageUrl = element?.url && element?.mms_file_type !== "vcf" && element?.item_type !== "call" && !element?.audio_message ? true : false
  const showImageFullScreen = showFullScreen && showFullScreen === element?.message_mms_media && element?.item_type !== "call" && !element?.audio_message ? true : false

  const [loaded, setLoaded] = useState({}); 

  if (showImage && !showImageFullScreen) {
    return (
      <>
        {loaded[element?.pk] !== true && (
          <Skeleton height={100} width={100} />
        )}

        <img
          src={`${urlBase}/itpvoice/v2/${api_id}/my-extension/chat/media/${element?.message_mms_media}?token=${token}`}
          className="itp-chat-window-footer-img"
          onClick={() => handleClick(element?.message_mms_media)}
          onLoad={() => setLoaded({ ...loaded, [element?.pk]: true })}
          style={{ display: loaded[element?.pk] === true ? '' : 'none' }}
        />
      </>
    )
  } else if (showImageUrl && !showImageFullScreen) {
    return (
      <img
        src={element?.url}
        className="itp-chat-window-footer-img"
      />
    )
  } else if (showImageFullScreen) {
    return (
      <div
        className="container-img-full-screen"
        onClick={handleClose}
      >
        <img
          src={`${urlBase}/itpvoice/v2/${api_id}/my-extension/chat/media/${element?.message_mms_media}?token=${token}`}
          alt="FullScreen"
          className="img-full-screen"
        />
      </div>
    )
  }
};

export default Image;
