// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-input-container {
    display: flex;
    align-items: center;
    border-radius: 20px;
    background-color: #FFFFFF24;
    padding: 5px;
    height: 32px;
    width: 365px;
  }
  
  .itp-search-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    fill: #FFFFFF24;
    cursor: pointer;
  }
  
  .itp-input {
    border: none;
    outline: none;
    flex: 1 1;
    padding: 5px;
    background-color: transparent;
    color: white;
  }

  .itp-input::placeholder {
    color: #FFFFFF24;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Search/Search.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,2BAA2B;IAC3B,YAAY;IACZ,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,SAAO;IACP,YAAY;IACZ,6BAA6B;IAC7B,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".itp-input-container {\n    display: flex;\n    align-items: center;\n    border-radius: 20px;\n    background-color: #FFFFFF24;\n    padding: 5px;\n    height: 32px;\n    width: 365px;\n  }\n  \n  .itp-search-icon {\n    width: 16px;\n    height: 16px;\n    margin-right: 5px;\n    fill: #FFFFFF24;\n    cursor: pointer;\n  }\n  \n  .itp-input {\n    border: none;\n    outline: none;\n    flex: 1;\n    padding: 5px;\n    background-color: transparent;\n    color: white;\n  }\n\n  .itp-input::placeholder {\n    color: #FFFFFF24;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
