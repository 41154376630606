const handleVolumeChangeFunction = (newValue, disabled, setVolume, audioRef) => {
  if (!disabled) {
    // Actualiza el estado del volumen
    setVolume(newValue);

    // Actualiza el atributo volume del elemento <audio> (rango: 0 a 1)
    audioRef.current.volume = newValue / 100;
  }
};

export default handleVolumeChangeFunction;
