// Function to relocate an object in the array
const repositionObject = (array, object) => {
    //set 'pinned' to true on the object to be reorganized
    object.pinned = true
  
    // Filter the object you want to relocate
    const filteredArray = array.filter((item) => item.pk !== object.pk);
  
    // Find objects with pin in true
    const pinnedObjects = filteredArray.filter((item) => item.pinned);
  
    if (pinnedObjects.length === 0) {
      // If there are no objects pinned to true, it places the object at position 0.
      return [object, ...filteredArray];
    } else {
      // Sort objects with pinned to true by last_updated in descending order
      pinnedObjects.sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));
  
      // Find the appropriate position to insert the object based on 'last_updated'.
      let insertIndex = pinnedObjects.findIndex(
        (item) => new Date(object.last_updated) > new Date(item.last_updated)
      );
  
      if (insertIndex === -1) {
        // If it does not find a position, place the object at the end of the 'pinned' ones.
        insertIndex = pinnedObjects.length;
      }
  
      // Reconstitute the array with the new object in the correct position
      const newArray = [
        ...filteredArray.slice(0, insertIndex),
        object,
        ...filteredArray.slice(insertIndex),
      ];
  
      return newArray;
    }
  };
  
  export default repositionObject;