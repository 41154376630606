// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-page-container {
  display: flex;
  /* height: calc(100%) !important; */
  position: absolute;
  width: 100%;
 
}

.itp-width-sidebar {
  margin-left: 233px;
  width: calc(100% - 233px) !important;
  position: relative !important;
  height: 100% !important;
  transition: all 0.5s;
  padding-right: 17px;
  padding-left: 7px;
  padding-top: 7px;
  padding-top: 7px;
  
}

.itp-width-100 {
  width: 100% !important;
  margin-left: 0 !important;
  transition: width 0.2s ease;
}

@media (max-width: 991px) {
  .itp-width-sidebar {
    width: 100% !important;
    margin-left: 0;
    transition: all 0.5s;
  }
}

th {
  font-weight: normal !important;

}

.itp-page-not-found {
  text-align: center;
  margin-top: 50px;
}

.itp-not-found-button {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/routes/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mCAAmC;EACnC,kBAAkB;EAClB,WAAW;;AAEb;;AAEA;EACE,kBAAkB;EAClB,oCAAoC;EACpC,6BAA6B;EAC7B,uBAAuB;EACvB,oBAAoB;EACpB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;;AAElB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE;IACE,sBAAsB;IACtB,cAAc;IACd,oBAAoB;EACtB;AACF;;AAEA;EACE,8BAA8B;;AAEhC;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;AACvB","sourcesContent":[".itp-page-container {\n  display: flex;\n  /* height: calc(100%) !important; */\n  position: absolute;\n  width: 100%;\n \n}\n\n.itp-width-sidebar {\n  margin-left: 233px;\n  width: calc(100% - 233px) !important;\n  position: relative !important;\n  height: 100% !important;\n  transition: all 0.5s;\n  padding-right: 17px;\n  padding-left: 7px;\n  padding-top: 7px;\n  padding-top: 7px;\n  \n}\n\n.itp-width-100 {\n  width: 100% !important;\n  margin-left: 0 !important;\n  transition: width 0.2s ease;\n}\n\n@media (max-width: 991px) {\n  .itp-width-sidebar {\n    width: 100% !important;\n    margin-left: 0;\n    transition: all 0.5s;\n  }\n}\n\nth {\n  font-weight: normal !important;\n\n}\n\n.itp-page-not-found {\n  text-align: center;\n  margin-top: 50px;\n}\n\n.itp-not-found-button {\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
