// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disabled-action {
  color: gray !important;
}

.disabled-action.hover-action {
  position: relative;
}

.action-dropdown-hover {
  visibility: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  margin-top: 4px;
  z-index: 2;
  top: -2rem;
}

.disabled-action.hover-action:hover > .action-dropdown-hover {
  visibility: visible;
}

.action-dropdown-hover-text {
  text-align: left;
  font: normal normal normal 12px/15px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin: 10px;
  white-space: nowrap;
}

.dropdown-menu.show {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomTableMain/Components/TableRow/ActionDropdown/ActionDropdown.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,+CAA+C;EAC/C,6CAA6C;EAC7C,mBAAmB;EACnB,UAAU;EACV,kBAAkB;EAClB,eAAe;EACf,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,oDAAoD;EACpD,mBAAmB;EACnB,cAAc;EACd,UAAU;EACV,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".disabled-action {\n  color: gray !important;\n}\n\n.disabled-action.hover-action {\n  position: relative;\n}\n\n.action-dropdown-hover {\n  visibility: hidden;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  border: 0.30000001192092896px solid #70707073;\n  border-radius: 10px;\n  opacity: 1;\n  position: absolute;\n  margin-top: 4px;\n  z-index: 2;\n  top: -2rem;\n}\n\n.disabled-action.hover-action:hover > .action-dropdown-hover {\n  visibility: visible;\n}\n\n.action-dropdown-hover-text {\n  text-align: left;\n  font: normal normal normal 12px/15px Instrument Sans;\n  letter-spacing: 0px;\n  color: #002340;\n  opacity: 1;\n  margin: 10px;\n  white-space: nowrap;\n}\n\n.dropdown-menu.show {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
