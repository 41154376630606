import AddCallflow from "./AddCallflow/AddCallflow";
import AddQueue from "./AddQueue/AddQueue";
import AddSwitch from "./AddSwitch/AddSwitch";
import AddTimeGroup from "./AddTimeGroup/AddTimeGroup";
import CallflowChart from "./CallflowChart/CallflowChart";
import Callflows from "./Callflows/Callflows";
import ChartMenuContent from "./ChartMenuContent/ChartMenuContent";
import EditCallFlowNumbers from "./EditCallFlowNumbers/EditCallFlowNumbers";
import EditSwitch from "./EditSwitch/EditSwitch";
import ModalDelete from "./ModalUtitlity/ModalDelete";
import QueueDetails from "./QueueDetails/QueueDetails"
import Queues from "./Queues/Queues";
import Switches from "./Switches/Switches";
import TimeGroups from "./TimeGroups/TimeGroups";
import TimeGroupsDetails from "./TimeGroupsDetails/TimeGroupsDetails"

export {
  AddCallflow,
  AddQueue,
  AddSwitch,
  AddTimeGroup,
  CallflowChart,
  Callflows,
  ChartMenuContent,
  EditCallFlowNumbers,
  EditSwitch,
  ModalDelete,
  QueueDetails,
  Queues,
  Switches,
  TimeGroups,
  TimeGroupsDetails,
};
