// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row.itp-aligned-row {
  visibility: visible;
}

@media (max-width: 561px) {
  .row.itp-aligned-row {
    margin: 0 !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AlignedRow/AlignedRow.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF","sourcesContent":[".row.itp-aligned-row {\n  visibility: visible;\n}\n\n@media (max-width: 561px) {\n  .row.itp-aligned-row {\n    margin: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
