import { Col, Row } from "reactstrap";
import informationBlue from "../../assets/icons/information-blue.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const BagdeAlert = ({ className, variant, alertText }) => (
  <Row className={`itp-alert-text alert-background-${variant} d-flex align-items-center ${className || ""}`}>
    <Col md={12} className="d-flex justify-content-start">
      <div className="d-flex alig-items-center">
        {variant && variant !== "default" && (
          <ErrorOutlineIcon className={`itp-alert-icon-${variant}`} />
        )}

        {(!variant || variant === "default") && (
          <div className="itp-alert-icon-default">
            <img src={informationBlue} alt="myExtensionTemp-icon" />
          </div>
        )}

        <div className={`itp-alert-data-${variant}`}>{alertText || ""}</div>
      </div>
    </Col>
  </Row>
);

export default BagdeAlert;
