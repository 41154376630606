// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-button-jobs-error {
  margin-left: 8px !important;
  margin-bottom: 8px !important;
}

.import-contacts-container {
  margin-left: 12px;
}

.label-input-add-contact-list {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.label-add-contact-list {
  margin-right: 12px;
}

.input-add-contact-list {
  width: 80% !important;
}

.add-contact-list-buttons {
  display: flex;
  gap: 8px;
}

.import-contact-nex-button {
  margin-bottom: 12px;
  margin-top: 8px;
}

.import-contact-container-table {
  margin-left: -2.1rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/private/Contacts/AddContact/options/Import/Import.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".itp-button-jobs-error {\n  margin-left: 8px !important;\n  margin-bottom: 8px !important;\n}\n\n.import-contacts-container {\n  margin-left: 12px;\n}\n\n.label-input-add-contact-list {\n  display: flex;\n  align-items: center;\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n}\n\n.label-add-contact-list {\n  margin-right: 12px;\n}\n\n.input-add-contact-list {\n  width: 80% !important;\n}\n\n.add-contact-list-buttons {\n  display: flex;\n  gap: 8px;\n}\n\n.import-contact-nex-button {\n  margin-bottom: 12px;\n  margin-top: 8px;\n}\n\n.import-contact-container-table {\n  margin-left: -2.1rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
