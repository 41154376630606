// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audio-settings{
    background: #002340;
    color: white;
    z-index: 1000;
    width: 700px;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid white;
}

.audio-settings-close{
    display: flex;
    justify-content: flex-end;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background: rgba(0, 0, 0, 0.2) !important;
    color:white !important;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
    color: white !important;
}

.nav-link{
    color: white !important;
}

.selects-audio-settings {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/WebPhone/views/Settings/Settings.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,yCAAyC;IACzC,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,sBAAsB;AAC1B","sourcesContent":[".audio-settings{\n    background: #002340;\n    color: white;\n    z-index: 1000;\n    width: 700px;\n    padding: 15px;\n    border-radius: 20px;\n    border: 1px solid white;\n}\n\n.audio-settings-close{\n    display: flex;\n    justify-content: flex-end;\n}\n\n.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {\n    background: rgba(0, 0, 0, 0.2) !important;\n    color:white !important;\n}\n\n.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{\n    color: white !important;\n}\n\n.nav-link{\n    color: white !important;\n}\n\n.selects-audio-settings {\n    display: flex;\n    justify-content: flex-start;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
