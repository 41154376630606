import petitionGet from "../../../../../services/petitionGet";

const searchFunnelService = async (inputValue, callback) => {
  try {
    const parameter = inputValue ? `?name=${inputValue}` : "?offset=0&limit=10"
    const { data: result } = await petitionGet("funnels", { parameter });

    result.result.forEach((funnel) => {
      funnel.label = funnel?.name || "";
      funnel.value = funnel?.pk || null; 
    });

    callback(result.result);
  } catch (error) {
    console.log(error);
  }
};

export default searchFunnelService;
