import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import Skeleton from "react-loading-skeleton";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Tag from "../../assets/icons/addTag.svg";
import CircularProgress from "@mui/material/CircularProgress"; // Indicador de carga
import { fetchTags } from "./services";
import { AlertNotificactions } from "../index";

const filter = createFilterOptions();
const tagColorsWithBlackFont = ["#f8e71c", "#dff21b", "", null, undefined, false];

const TagComponent = ({ disabled, tags, handleTag, refer, imageClick, icon, loadingData }) => {
  const [defaultTags, setDefaultTags] = useState([])
  const [optionsTags, setOptionsTags] = useState([]); // Inicialmente vacío
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(""); // Para manejar la entrada del usuario
  const [firstFocus, setFirstFocus] = useState(true); // Controlar la primera vez que se hace focus
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState("")
  
  const updateAlertVariables = (type, title, message, time) => {
    setAlertType(type)
    setAlertTitle(title)
    setAlertMessage(message)
    setShowErrorTime(time)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, time + 300)
  }

  const getInitialTags = async () => {
    const query = "?offset=0&limit=10"
    await fetchTags(query, setLoading, setOptionsTags, updateAlertVariables, setFirstFocus, setDefaultTags)
  }

  const searchTags = async (searchQuery) => {
    const query = `?name=${searchQuery}`
    await fetchTags(query, setLoading, setOptionsTags, updateAlertVariables)
  }

  const handleChangeTag = (newValue) => {
    handleTag(newValue)
    setOptionsTags(defaultTags)
  }

  // Manejador para el focus
  const handleFocus = () => {
    if (firstFocus) {
      getInitialTags(); // Cargar las primeras 10 opciones la primera vez
      setFirstFocus(false); // Desactivar el focus inicial
    }
  };

  // Manejador para cuando se escribe en el campo de búsqueda
  useEffect(() => {
    if (inputValue && !firstFocus) {
      searchTags(inputValue); // Hacer una búsqueda cada vez que cambie el inputValue
    } else if (inputValue === "" && !firstFocus) {
      setOptionsTags(defaultTags)
    }
  }, [inputValue]);

  return (
    <>
      <AlertNotificactions 
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

      {loadingData ? (
        <Skeleton height={50} width="100%" />
      ) : (
        <div className="itp-component-tag-container">
          {icon ? (
            <LocalOfferOutlinedIcon onClick={typeof imageClick === "function" ? imageClick : () => {}} />
          ) : (
            <img onClick={typeof imageClick === "function" ? imageClick : () => {}} src={Tag} alt="Add Tag" className="itp-component-tag-img" />
          )}

          <div ref={refer || null} className="itp-component-tag-width">
            <Stack>
            <Autocomplete
              disableClearable
              disablePortal
              filterSelectedOptions
              disabled={disabled}
              freeSolo
              multiple
              value={tags}
              options={optionsTags}
              onInputChange={(event, newInputValue) => setInputValue(newInputValue)} // Manejar cambios en la entrada de texto
              onFocus={handleFocus} // Cuando el campo gana el foco
              loading={loading} // Mostrar indicador de carga si está cargando
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option?.inputValue || option.title}
                    {...getTagProps({ index })}
                    style={{
                      backgroundColor: option.tag_color || "#edf6fd",
                      color: tagColorsWithBlackFont.includes(option.tag_color)
                        ? "black"
                        : "white",
                    }}
                  />
                ))
              }
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return "error";
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                } else {
                  return option.title;
                }
              }}
              getOptionDisabled={(option) => !!option.disabled}
              id="tags-standard"
              isOptionEqualToValue={(option, value) =>
                option.title === value.title
              }
              onChange={(event, newValue) => handleChangeTag(newValue)}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );

                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Add Tags"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <Chip
                  {...props}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#cfe3ff", // Cambiar al color más oscuro deseado
                      color: "black",
                    },
                    backgroundColor: option.tag_color || "#ffffff",
                    borderRadius: 0,
                    border: 0, // Mantener los bordes cuadrados
                    color: tagColorsWithBlackFont.includes(option.tag_color)
                      ? "black"
                      : "white",
                  }}
                  label={option.title}
                  variant="outlined"
                />
              )}
            />
            </Stack>
          </div>
        </div>
      )}
    </>
  )
}

export default TagComponent;
