// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active > .page-link,

.page-link.active {
  background-color: #6D28D9 !important;
  border-color: transparent !important;
  color:#fff !important;
}

.page-link {
  color: #6D28D9 !important;
  border-color: #b9b9b9 !important;
  font-size: 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/PaginationITP/PaginationITP.css"],"names":[],"mappings":"AAAA;;;EAGE,oCAAoC;EACpC,oCAAoC;EACpC,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,gCAAgC;EAChC,0BAA0B;AAC5B","sourcesContent":[".active > .page-link,\n\n.page-link.active {\n  background-color: #6D28D9 !important;\n  border-color: transparent !important;\n  color:#fff !important;\n}\n\n.page-link {\n  color: #6D28D9 !important;\n  border-color: #b9b9b9 !important;\n  font-size: 14px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
