import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { CollapsePanel, LabelSwitch } from "../../../../../components";
import { updateAccountDetailsService } from "./services";

const CompilanceSettings = ({ loading, accountDetails, updateAlertVariables }) => {
  const [switchs, setSwitchs] = useState({
    transcribing_enabled: false,
    transcribing_redact_pii: false,
    transcribing_redact_ssn: false,
    transcribing_redact_credit_card: false,
    transcribing_redact_dob: false,
    transcribing_redact_phone_number: false,
    transcribing_redact_address: false,
  });

  const handleChangeSwitchs = async (key) => {
    setSwitchs({ ...switchs, [key]: !switchs[key] });

    const data = { [key]: !switchs[key] };
    await updateAccountDetailsService(data, switchs, setSwitchs, updateAlertVariables);
  };

  useEffect(() => {
    if (accountDetails) {
      setSwitchs({
        transcribing_enabled: accountDetails?.transcribing_enabled || false,
        transcribing_redact_pii: accountDetails?.transcribing_redact_pii || false,
        transcribing_redact_ssn: accountDetails?.transcribing_redact_ssn || false,
        transcribing_redact_credit_card: accountDetails?.transcribing_redact_credit_card || false,
        transcribing_redact_dob: accountDetails?.transcribing_redact_dob || false,
        transcribing_redact_phone_number: accountDetails?.transcribing_redact_phone_number || false,
        transcribing_redact_address: accountDetails?.transcribing_redact_address || false,
      });
    }
  }, [accountDetails]);

  return (
    <>
      <br></br>
      <div className="mt-1">
        <Row>
          <Col style={{ zIndex: "-1" }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        title="Compliance"
        subtitle="Configure your account's compliance settings for call transcription and data protection."
      >
        <LabelSwitch 
          label="Enable Call Transcription"
          description="Turn on transcription for all recorded calls."
          loading={loading}
          skeletonStyles={{ width: "5rem", height: "2rem" }}
          checked={switchs.transcribing_enabled}
          handleChangeSwitch={handleChangeSwitchs}
          field="transcribing_enabled"
        />

        <LabelSwitch 
          label="Enable PII Redaction"
          description="Automatically redact Personally Identifiable Information from transcriptions."
          loading={loading}
          skeletonStyles={{ width: "5rem", height: "2rem" }}
          checked={switchs.transcribing_redact_pii}
          handleChangeSwitch={handleChangeSwitchs}
          field="transcribing_redact_pii"
        />

        {switchs.transcribing_redact_pii && (
          <>
            <LabelSwitch 
              label="Redact SSN"
              description="Redact Social Security Numbers from transcriptions."
              loading={loading}
              skeletonStyles={{ width: "5rem", height: "2rem" }}
              checked={switchs.transcribing_redact_ssn}
              handleChangeSwitch={handleChangeSwitchs}
              field="transcribing_redact_ssn"
            />

            <LabelSwitch 
              label="Redact Credit/Debit Card Numbers"
              description="Redact credit or debit card numbers from transcriptions."
              loading={loading}
              skeletonStyles={{ width: "5rem", height: "2rem" }}
              checked={switchs.transcribing_redact_credit_card}
              handleChangeSwitch={handleChangeSwitchs}
              field="transcribing_redact_credit_card"
            />

            <LabelSwitch 
              label="Redact Date of Birth"
              description="Redact dates of birth from transcriptions."
              loading={loading}
              skeletonStyles={{ width: "5rem", height: "2rem" }}
              checked={switchs.transcribing_redact_dob}
              handleChangeSwitch={handleChangeSwitchs}
              field="transcribing_redact_dob"
            />

            <LabelSwitch 
              label="Redact Phone Numbers"
              description="Redact phone numbers from transcriptions."
              loading={loading}
              skeletonStyles={{ width: "5rem", height: "2rem" }}
              checked={switchs.transcribing_redact_phone_number}
              handleChangeSwitch={handleChangeSwitchs}
              field="transcribing_redact_phone_number"
            />

            <LabelSwitch 
              label="Redact Addresses"
              description="Redact physical addresses from transcriptions."
              loading={loading}
              skeletonStyles={{ width: "5rem", height: "2rem" }}
              checked={switchs.transcribing_redact_address}
              handleChangeSwitch={handleChangeSwitchs}
              field="transcribing_redact_address"
            />
          </>
        )}
      </CollapsePanel>
    </>
  );
};

export default CompilanceSettings;
