// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fullscreen-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
}

.fullscreen-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.image-container img {
  max-width: 80%;
  max-height: 80%;
  width: auto;
  height: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.timestamp {
  /* position: absolute;
  top: 10px;
  right: 10px; */
  color: white;
  font-size: 12px;
}

.close-button {
  /* position: absolute;
  top: 10px;
  right: 10px; */
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/FullscreenImage/FullscreenImage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;AACR;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;EACf,WAAW;EACX,YAAY;EACZ,2CAA2C;AAC7C;;AAEA;EACE;;gBAEc;EACd,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE;;gBAEc;EACd,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,eAAe;EACf,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb","sourcesContent":[".fullscreen-image-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.8);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  z-index: 9999;\n}\n\n.fullscreen-header-container {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  height: 100%;\n  position: relative;\n  top: 0;\n}\n\n.image-container {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n\n.image-container img {\n  max-width: 80%;\n  max-height: 80%;\n  width: auto;\n  height: auto;\n  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);\n}\n\n.timestamp {\n  /* position: absolute;\n  top: 10px;\n  right: 10px; */\n  color: white;\n  font-size: 12px;\n}\n\n.close-button {\n  /* position: absolute;\n  top: 10px;\n  right: 10px; */\n  color: white;\n  font-size: 20px;\n  cursor: pointer;\n}\n\n.arrow {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  font-size: 30px;\n  color: white;\n  cursor: pointer;\n}\n\n.left {\n  left: 10px;\n}\n\n.right {\n  right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
