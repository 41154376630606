import petitionPatch from "../../../../../../../services/petitionPatch";

const updateAccountDetailsService = async (data, currentData, setData, updateAlertVariables) => {
  try {
    await petitionPatch("accountDetails", { data })
  } catch (error) {
    console.log(error)
    const errorMessage = "An error has ocurred, please try again"
    setData(currentData)
    updateAlertVariables("error", "Error", errorMessage, 3000)
  }
}

export default updateAccountDetailsService;