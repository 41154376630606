import CalendarIntegration from "./CalendarIntegration/CalendarIntegration";
import Devices from "./Devices/Devices";
import CallForwarding from "./CallForwarding/CallForwarding";
import CallerID from "./CallerID/CallerID";
import CallRecordings from "./CallRecordings/CallRecordings";
import IntercomPaging from "./IntercomPaging/IntercomPaging";
import Fax from "./Fax/Fax";
import Voicemail from "./Voicemail/Voicemail";
import SoundSettings from "./SoundSettings/SoundSettings";

export {
  CalendarIntegration,
  Devices,
  CallForwarding,
  CallerID,
  CallRecordings,
  IntercomPaging,
  Fax,
  Voicemail,
  SoundSettings,
};
