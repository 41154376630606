import { Fragment } from "react";
import Switch from "react-switch";

const SwitchAction = ({ actions, element }) => (
  <>
    {actions.map((action, index) => (
      <Fragment key={index}>
        {action.type === "component" && action.component(element)}

        {action.type !== "component" && action.switch && (
          <Switch
            className="itp-custom-table-switch"
            onColor="#626ed4"
            height={20}
            width={40}
            checked={element[`${action?.keySwitch}`] ? true : false}
            onChange={() => action.handleClick(element)}
          />
        )}
      </Fragment>
    ))}
  </>
)

export default SwitchAction;