import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";
import { convertUtcDateToTimeZoneDate, formatDate } from "../../../../../utils/getDate";

const formatedDate = (date, timeZone) => {
  const timeZoneDate = convertUtcDateToTimeZoneDate(date, timeZone)
  return formatDate(timeZoneDate)
}

const returnAgentName = (pk, totalUsers) => {
  const findUser = totalUsers.find((user) => user.pk.toString() === pk)

  if (findUser) {
    const firstname = findUser?.firstname || ""
    const lastname = findUser?.lastname || ""

    return `${firstname} ${lastname}`
  }

  return pk
}

const returnQueue = (pk, totalQueues) => {
  const findQueue = totalQueues.find((queue) => queue.pk.toString() === pk)

  if (findQueue) {
    return findQueue?.queue_name || pk
  }

  return pk
}

const selectDurationTime = (data1, data2) => {
  const data1Number = typeof data1 === "number" ? data1 : typeof data1 === "string" ? parseInt(data1) : 0
  const data2Number = typeof data2 === "number" ? data2 : typeof data2 === "string" ? parseInt(data2) : 0

  if (data1Number > data2Number) return data1Number

  if (data2Number > data1Number) return data2Number

  return data1Number
}

const selectHoldTime = (data1, data2) => {
  const data1Number = typeof data1 === "number" ? data1 : typeof data1 === "string" ? parseInt(data1) : 0
  const data2Number = typeof data2 === "number" ? data2 : typeof data2 === "string" ? parseInt(data2) : 0

  if (data1Number > data2Number) return data2Number

  if (data2Number > data1Number) return data1Number

  return data2Number
}

const parseQueueLog = (response, timeZone, totalUsers, totalQueues) => {
  if (response.errors || response.error) {
    return "An error occurred fetching the queue logs.";
  }
  
  const parsedLogs = response.result.map((log) => {
    let parsedEvent = "";
  
    switch (log.event) {
      case "ENTERQUEUE":
        parsedEvent = `Call from ${formatPhoneNumber(log.data2)} entered queue ${returnQueue(log.queuename, totalQueues)} at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "CONNECT":
        parsedEvent = `Call was connected to agent ${returnAgentName(log.agent, totalUsers)} at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "COMPLETEAGENT":
        parsedEvent = `Call completed by agent ${returnAgentName(log.agent, totalUsers)}. Duration: ${selectDurationTime(log.data1, log.data2)} seconds, hold time: ${selectHoldTime(log.data1, log.data2)} seconds at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "COMPLETECALLER":
        parsedEvent = `Call completed by the caller. Duration: ${selectDurationTime(log.data1, log.data2)} seconds, hold time: ${selectHoldTime(log.data1, log.data2)} seconds at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "TRANSFER":
        parsedEvent = `Call was transferred by agent ${returnAgentName(log.agent, totalUsers)} to another extension at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "ABANDON":
        parsedEvent = `Call was abandoned after ${log.data3} seconds in the queue at position ${log.data2} at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "EXITWITHKEY":
        parsedEvent = `Caller exited the queue by pressing a key at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "EXITWITHTIMEOUT":
        parsedEvent = `Caller exited the queue due to a timeout at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "RINGNOANSWER":
        parsedEvent = `Agent ${returnAgentName(log.agent, totalUsers)} was rung, but did not answer within ${log.data1} milliseconds at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "RINGCANCELED":
        parsedEvent = `Call ringing was canceled after ${log.data1} milliseconds at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "AGENTLOGIN":
        parsedEvent = `Agent ${returnAgentName(log.agent, totalUsers)} logged into the queue ${returnQueue(log.queuename, totalQueues)} at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "AGENTLOGOFF":
        parsedEvent = `Agent ${returnAgentName(log.agent, totalUsers)} logged out of the queue ${returnQueue(log.queuename, totalQueues)} at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "AGENTCALLBACKLOGIN":
        parsedEvent = `Agent ${returnAgentName(log.agent, totalUsers)} logged into the queue with callback login at ${formatedDate(log.time, timeZone)}.`;
        break;
      case "AGENTCALLBACKLOGOFF":
        parsedEvent = `Agent ${returnAgentName(log.agent, totalUsers)} logged out of the queue with callback logout at ${formatedDate(log.time, timeZone)}.`;
        break;
      default:
        parsedEvent = `Unknown event ${log.event} occurred at ${formatedDate(log.time, timeZone)}.`;
    }
  
    return { time: convertUtcDateToTimeZoneDate(log.time, timeZone), parsedEvent};
  });
  
  return parsedLogs
}

export default parseQueueLog;