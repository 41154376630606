import camera from "../../../../../../assets/icons/camera.svg"
import sms from "../../../../../../assets/icons/sms2.svg"
import { selectCallImage, findAndFormatPhoneNumbers } from "../index"

const selectTypeLastMessage = (element) => {
  if (element?.last_event_type === "sms" && !element?.last_message) {
    return <><img src={camera} alt="Camera" className="last-message-icons" /> Image</>
  } 
  
  if (element?.last_event_type === "sms" && element?.last_message) {
    return <><img src={sms} alt="SMS" className="last-message-icons" /> {findAndFormatPhoneNumbers(element?.last_message)}</>
  } 
  
  return <>{selectCallImage(element)} {findAndFormatPhoneNumbers(element?.last_message)}</>
}

export default selectTypeLastMessage;