// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.board {
  width: 240px;
  height: 120px;
}

h6,
p {
  font-family: sans-serif;
  font-size: 13px;
}

.itp-dashboard-chart__card-body.card-body {
  padding-left: 0;
  padding-bottom: 0;
}

.itp-dashboard-card-info-voicemail {
  margin-left: 3rem;
}

@media (min-width: 1920px) {
  .itp-dashboard-card-info-voicemail {
    margin-left: -6rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/private/Dashboard/Dashboard.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;;EAEE,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["\n.board {\n  width: 240px;\n  height: 120px;\n}\n\nh6,\np {\n  font-family: sans-serif;\n  font-size: 13px;\n}\n\n.itp-dashboard-chart__card-body.card-body {\n  padding-left: 0;\n  padding-bottom: 0;\n}\n\n.itp-dashboard-card-info-voicemail {\n  margin-left: 3rem;\n}\n\n@media (min-width: 1920px) {\n  .itp-dashboard-card-info-voicemail {\n    margin-left: -6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
