import petitionGet from "../../../../../services/petitionGet";

const downloadCallQueueMemberLogs = async (element, setLoadingCall, setModalError, key) => {
  try {
    setLoadingCall(element);

    const unique_id = key ? element[key] : element.unique_id
    const response = await petitionGet("downloadQueueMemberLogs", { unique_id }, false, "blob")

    if (response?.response?.status === 200 || response?.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data])); // Crear URL para el blob
      const link = document.createElement('a'); // Crear un enlace para descargar el archivo
      link.href = url;
      link.setAttribute('download', 'file.mp3'); // Asigna el nombre del archivo a descargar
      document.body.appendChild(link);
      link.click(); // Simular el clic para descargar el archivo
      link.remove(); // Eliminar el enlace del DOM después de descargar
      setLoadingCall(null);
    }
  } catch (error) {
    console.log(error)
    if (error?.response?.status === 404) {
      setLoadingCall(null)
      setModalError(true)
    } 
  }
}

export default downloadCallQueueMemberLogs;