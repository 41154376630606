import React, { useState } from "react";
import { ColumnHeaderResponsive, ResponsiveActions, TableRowResponsive } from "./components";
import useRowSelect from "../../Hooks/useRowSelect";
import SwitchAction from "../TableRow/SwitchAction/SwitchAction";
import IconAction from "../TableRow/IconAction/IconAction";
import Skeleton from "react-loading-skeleton";
import "./ResponsiveTable.css";

const ResponsiveTable = ({
  currentData,
  data,
  selectedAllRows,
  loading,
  externalSelectedRows,
  onSelectionChange,
  withCheckboxes,
  defaultOrderColumn,
  columnNames,
  changeColumnOrder,
  getBorderColor,
  borderColumnName,
  colorColumnName,
  getTextColor,
  columnConfig,
  renderCallStatus,
  loadingIcon,
  responsiveActionsClassMenu,
}) => {
  const { toggleRow, isSelected, toggleAll, isAllSelected } = useRowSelect(
    currentData,
    externalSelectedRows,
    onSelectionChange
  );

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const validViewColumn = (arr) => {
    return arr?.filter((el) => el?.viewColumn !== false);
  };
  
  const onChangeCheckbox = (element) => {
    toggleRow(element.pk)
  }

  const toggle = (event, i) => {
    event?.stopPropagation();
    setOpenDropdownIndex(i === openDropdownIndex ? null : i);
  };

  return (
    <div className="responsive-table">
      {currentData?.map((row, rowIndex) => (
        <div className="row-card" key={rowIndex}>
          {withCheckboxes && (
            <div className="row-item">
              <div className="column-name">
                <input
                  type="checkbox"
                  className="form-check-input itp-custom-table-main-check-header"
                  checked={isAllSelected || selectedAllRows}
                  onChange={toggleAll}
                  disabled={selectedAllRows || loading}
                />
              </div>

              <div className="column-value">
                <input
                  type="checkbox"
                  checked={isSelected(row.pk) || selectedAllRows} //It is selected if the row checkbox has been clicked or if all rows have been selected
                  onChange={(event) => { event.stopPropagation(); onChangeCheckbox(row)}} 
                  className="form-check-input"
                  disabled={selectedAllRows} //If you have clicked to select all the rows, it is disabled
                />
              </div>
            </div>
          )}

          {validViewColumn(data?.columns)?.map((column, colIndex) => (
            <div className="row-item" key={colIndex}>
              <ColumnHeaderResponsive 
                column={column}
                defaultOrderColumn={defaultOrderColumn}
                columnNames={columnNames}
                changeColumnOrder={changeColumnOrder}
                loading={loading}
              />
              
              <TableRowResponsive 
                allData={data}
                element={row}
                getBorderColor={getBorderColor}
                borderColumnName={borderColumnName}
                colorColumnName={colorColumnName}
                getTextColor={getTextColor}
                columnConfig={columnConfig}
                renderCallStatus={renderCallStatus}
                loading={loading}
                column={column}
              />
            </div>
          ))}

          {data?.actions?.title && (
            <div className="row-item">
              <div className="column-name">{data?.actions?.title}</div>
              {data?.actions?.content && (
                <div className="me-3">
                  {loading ? (
                    <Skeleton height="1rem" width="3rem" />
                  ) : (
                    <>
                      {!data?.actions?.isSwitch && !data?.actions?.isIcon && (
                        <ResponsiveActions
                          toggle={toggle}
                          classMenu={responsiveActionsClassMenu}
                          index={rowIndex}
                          actions={data?.actions?.content}
                          element={row}
                          isOpen={rowIndex === openDropdownIndex}
                        />
                      )}

                      {!data?.actions?.isSwitch && data?.actions?.isIcon && (
                        <IconAction 
                          element={row}
                          actions={data?.actions?.content}
                          loadingIcon={loadingIcon}
                        />
                      )}

                      {data?.actions?.isSwitch && !data?.actions?.isIcon && (
                        <SwitchAction 
                          actions={data?.actions?.content}
                          element={row}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ResponsiveTable;
