import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { contactDetailsService, downloadCallService, editContactService, fetchListCallHistory } from "./services";
import { myExtensionPetition } from "../../../services/myExtension.service";
import { AlertNotificactions, ContactInfo, CustomTableMain, DateRange, DateReportCalls, LoadingDots, MenuRight } from "../../../components";
import { defaultDate, processListCallHistory } from "./utils";

const CallHistory = () => {
  const myExtension = useRef({}) 
  const itemsPerPage = useRef("10");
  const customFields = useRef([])
    
  const [loading, setLoading] = useState(false);
  const [loadingCall, setLoadingCall] = useState(null)
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState("")
  const [selectValue, setSelectValue] = useState("")
  const [date, setDate] = useState({ timeStart: "", timeEnd: "" })
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [totalCalls, setTotalCalls] = useState(0)
  const [allResult, setAllResult] = useState({})
  const [openMenu, setOpenMenu] = useState(false)
  const [loadingDots, setLoadingDots] = useState(true)
  const [contactDetails, setContactDetails] = useState(null)
  const [errorEditContact, setErrorEditContact] = useState("")
  const [loadingMenuButton, setLoadingMenuButton] = useState(false);
  const [loadingMenu, setLoadingMenu] = useState(false)
  const [activeFilterByDate, setActiveFilterByDate] = useState(false)

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
    
  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Date & Time", key: "datetime" },
      { name: "Direction", key: "formatedDirection" },
      { name: "From", key: "fromNumber" },
      { name: "To", key: "toNumber" },
      { name: "Billable Time", key: "billsec" },
      { name: "Ring Time", key: "duration" },
      { name: "Disposition", key: "disposition" },
    ],
    actions: { title: "Download recording", content: [] }
  });

  const updateAlertVariables = (type, title, message, time) => {
    setAlertType(type)
    setAlertTitle(title)
    setAlertMessage(message)
    setShowErrorTime(time)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, time + 300)
  }

  const handleChangeSelect = (e) => {
    const { value } = e.target
    setSelectValue(value)

    let callList = []

    if (value === "all") {
      callList = allResult.all_calls
    } else if (value === "in") {
      callList = allResult.incoming_calls
    } else if (value === "out") {
      callList = allResult.outgoing_calls
    }

    const processedListCallHistory = processListCallHistory(callList, myExtension.current)
    setDataTable((prev) => {
      return { ...prev, content: processedListCallHistory }
    })
  }

  const activeMenuFilter = () => {
    setOpenMenu(true)
    setActiveFilterByDate(true)
  }

  const closeMenu = () => {
    setOpenMenu(false)
    setActiveFilterByDate(false)
    setContactDetails(null)
  }

  const onChangeDate = (e) => {
    const { name, value } = e.target
    setDate({ ...date, [name]: value })
  }

  const activeContactDetails = async (element) => {
    if (element?.contact?.pk) {
      await contactDetailsService(element, customFields, setContactDetails, setLoadingMenu, setOpenMenu)
    }
  }

  const reloadData = async (newContactData) => {
    closeMenu()

    setDataTable((prev) => {
      const newContent = prev.content.map((element) => {
        if (element?.contact?.pk === newContactData?.pk) {
          element.contact = newContactData
        }

        return element
      })

      return { ...prev, content: newContent }
    })
  }

  const editContact = async (form) => {
    const contact_id = contactDetails.pk
    await editContactService(setErrorEditContact, setLoadingMenuButton, form, contact_id, reloadData)
  }

  const downloadCall = async (element) => {
    await downloadCallService(element, setLoadingCall, updateAlertVariables)
  };

  const activeItemsPerPage = async (data) => {
    itemsPerPage.current = data
    forceUpdate()
  };

  const aplyFilter = () => {
    setOpenMenu(false)
    setLoading(true)
    setSelectValue("")
    const timeFrameTemp = defaultDate(myExtension.current, setFrom, setTo, setDate, date.timeStart, date.timeEnd)
    petition(timeFrameTemp, myExtension.current)
  }

  const petition = async (timeFrameTemp, myExtensionTemp) => {
    try {
      setLoading(true)
      
      const { processedListCallHistory, allCalls, allResult } = await fetchListCallHistory(timeFrameTemp, myExtensionTemp)

      setAllResult(allResult)
      setTotalCalls(allCalls)
      setDataTable({
        columns: [
          { name: "Date & Time", key: "datetime" },
          { name: "Direction", key: "formatedDirection" },
          { name: "From", key: "fromNumber" },
          { name: "To", key: "toNumber" },
          { name: "Billable Time", key: "billsec" },
          { name: "Ring Time", key: "duration" },
          { name: "Disposition", key: "disposition" },
        ],
        content: [...processedListCallHistory],
        actions: {
          title: "Download recording",
          isIcon: true,
          content: {
            icon: <FontAwesomeIcon icon={faDownload} />,
            handleClick: downloadCall,
          },
        },
      });

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition()
        myExtension.current = data

        const timeFrameTemp = defaultDate(data, setFrom, setTo, setDate)

        setLoadingDots(false)
        await petition(timeFrameTemp, data)
      } catch(error) {
        console.log(error)
      }
    }
  
      myExtensionData()
  }, [])

  return loadingDots ? (
    <LoadingDots className="mt-5" />
  ) : (
    <div className="mt-5">
      <AlertNotificactions 
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

      <CustomTableMain 
        data={dataTable}
        loading={loading}
        loadingIcon={loadingCall}
        m3={true}
        paginationData={parseInt(itemsPerPage.current)}
        showSelectSingleCheckbox={true}
        onChangeItemsPerPage={activeItemsPerPage}
        module={selectValue === "all" ? "call logs" : selectValue === "in" ? "inbound call" : selectValue == "out" ? "outbound calls" : "call logs"}
        actionsLeft={[
          {
            component: (
              <select
                onChange={handleChangeSelect}
                value={selectValue}
                className="form-select-itp width-12r"
                disabled={loading}
              >
                <option value="all">All Calls</option>
                <option value="in">Inbound</option>
                <option value="out">Outbound</option>
              </select>
            )
          },
          {
            component: (
              <button onClick={activeMenuFilter} className="btn-light">
                Filter By Date
              </button>
            )
          }
        ]}
        actionsRight={[
          {
            component: (
              <DateReportCalls
                reportDateText="Report Date :"
                from={from}
                to={to}
                totalCallsText="Total Calls :"
                totalCalls={totalCalls}
                loadingTotalCalls={loading}
              />
            )
          }
        ]}
        columnConfig={{
          "Date & Time": {
            type: "date",
          },
          Disposition: {
            type: "badge",
            keyStyles: "style",
          },
          Date: {
            type: "date",
          },
          From: {
            type: "hoverInfo",
            hoverKey: "fromNumberHover",
            text: "Extension Number :",
            alternativeKey: "From",
            alternativeText: "Phone Number:",
            clickRow: activeContactDetails,
          },
          To: {
            type: "hoverInfo",
            hoverKey: "toNumberHover",
            text: "Extension Number :",
            seconKey: "contact",
            alternativeText: "Phone Number :",
            thirdKey: "to_user_id",
            clickRow: activeContactDetails,
          },
        }}
      />

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Filter By Date"
        subtitle=""
        to=""
      >
        {openMenu && activeFilterByDate && (
          <>
            <label className="itp-label-input__label">Set Date</label>
            <p className="itp-analytics-description mb-2">
              Set a date to run a report for
            </p>
            <DateRange
             timeStart={date.timeStart}
             timeEnd={date.timeEnd}
             onChangeDate={onChangeDate}
            />

            <button
              disabled={!date.timeStart || !date.timeEnd}
              className="btn-primary loading itp-w-buton mt-3"
              onClick={aplyFilter}
            >
              Apply Filter
            </button>
          </>
        )}

        {openMenu && !activeFilterByDate && (
          <ContactInfo 
            contactDetails={contactDetails}
            customFields={customFields}
            editContact={editContact}
            loadingEdit={loadingMenuButton}
            myExtension={myExtension}
            setAddedCustomField={() => {}}
            errorEditContact={errorEditContact}
            loadingExternal={loadingMenu}
          />
        )}
      </MenuRight>
    </div>
  )
};

export default CallHistory;
