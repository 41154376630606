import { convertUtcDateToTimeZoneDate } from "../../../../utils/getDate";
import { selectArrow, selectIcon } from "../../utils";
import petitionGet from "../../../../services/petitionGet";

const fetchContactDetails = async (contactID, customFields, myExtension) => {
  const { data: result } = await petitionGet("contacts", { parameter: `/${contactID}` });
  
  result.result.tags = result.result.tags.map((tag) => ({
    ...tag,
    title: tag.tag.name,
    pk: tag.tag_id,
    tag_color: tag.tag.tag_color,
  }));

  result.result.selectIcon = selectIcon(result.result)
  result.result.selectArrow = selectArrow(result.result)
  result.result.keyDate = result.result?.last_activity_time ? convertUtcDateToTimeZoneDate(result.result?.last_activity_time.replaceAll(" ", "T"), myExtension.time_zone) : ""

  customFields.forEach((custom) => {
    if (custom.field_type === "date") {
      const threeSlashCharacter = result.result[custom.name].split("/").length === 3;

      if (threeSlashCharacter) {
        const spliterValue = result.result[custom.name].split("/");
        const year = spliterValue[2];
        const mont = spliterValue[0];
        const day = spliterValue[1];

        result.result[custom.name] = `${year}-${mont}-${day}`;
      }
    }
  });

  return result.result
};

export default fetchContactDetails;
