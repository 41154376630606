import petitionPatch from "../../../../../../services/petitionPatch";

const saveUsersService = async (setLoadingNumber, number, data, newData, organizeDataUsers, closeMenu, setInfo, allUsers, updateAlertVariables) => {
  try {
    setLoadingNumber(true);

    await petitionPatch("numberEdit", { number, data });
    setInfo(newData);
    organizeDataUsers(newData, allUsers);
    setLoadingNumber(false);
    closeMenu();
  } catch (error) {
    console.log(error);
    updateAlertVariables("error", "Error", "An error has ocured", 3000)
  }
};

export default saveUsersService;
