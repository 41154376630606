import { Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import Switch from "react-switch";
import infoIcon from "../../assets/icons/information-icon.svg";
import "./LabelSwitch.css";

const LabelSwitch = ({
  label,
  description,
  loading,
  skeletonStyles,
  checked,
  handleChangeSwitch,
  field,
}) => (
  <>
    <span className="itp-label-switch__label">{label}</span>

    <Row className="itp-label-switch__row">
      <Col md={10} className="d-flex">
        <div>
          <img src={infoIcon} alt="myExtensionTemp-icon" />
        </div>
        <div className="ms-3">
          <p>{description}</p>
        </div>
      </Col>

      <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
        {loading ? (
          <Skeleton
            width={skeletonStyles.width}
            height={skeletonStyles.height}
          />
        ) : (
          <div className="itp-label-switch__switch-container">
            <Switch
              className="itp-label-switch__switch"
              onColor="#626ed4"
              height={20}
              width={40}
              checked={checked}
              onChange={() => handleChangeSwitch(field || "")}
            />
          </div>
        )}
      </Col>
    </Row>
  </>
);

export default LabelSwitch;
