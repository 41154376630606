import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ColumnHeaderResponsive = ({ column, defaultOrderColumn, columnNames, changeColumnOrder, loading }) => {
  const putArrow = defaultOrderColumn && columnNames && columnNames[defaultOrderColumn?.key] && columnNames[defaultOrderColumn?.key] === column.name && defaultOrderColumn?.order;
  const stillNotPutArrow = defaultOrderColumn && columnNames && columnNames[defaultOrderColumn?.key] && columnNames[defaultOrderColumn?.key] !== column.name && defaultOrderColumn?.order;
  
  const handleClick = () => changeColumnOrder(column.name);
  
  const Arrow = ({ direction }) => {
    if (direction === "asc") {
      return <KeyboardArrowUpIcon className="arrow-direction" />;
    } else if (direction === "desc") {
      return <KeyboardArrowDownIcon className="arrow-direction" />;
    }
  };
    
  return (
    <div className="column-name" onClick={(putArrow || stillNotPutArrow) && !loading ? handleClick : () => {}}>
      <div className="d-flex">
        {(putArrow && !loading) && <Arrow direction={defaultOrderColumn.order} />}
        {column.name}
      </div>
    </div>
  );
}

export default ColumnHeaderResponsive;