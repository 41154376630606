// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-hoverInfo-container {
  position: relative;
}

.itp-hoverInfo-content {
  position: absolute;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f6f6f9;
  width: auto;
  overflow: auto;
  padding: 8px;
  white-space: normal;
  border: 1px solid gray;
  /* transition: all ease 0.1s; */
}

.itp-hoverInfo-container:hover > .itp-hoverInfo-content {
  visibility: visible;
}

.itp-hoverInfo-container.click-row:hover > .itp-hoverInfo-rowData {
  color: #002340;
}

.itp-hoverInfo-p {
  white-space: nowrap;
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomTableMain/utils/renderCell/renderFunctions/HoverInfo/HoverInfo.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,yBAAyB;EACzB,WAAW;EACX,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".itp-hoverInfo-container {\n  position: relative;\n}\n\n.itp-hoverInfo-content {\n  position: absolute;\n  visibility: hidden;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  background-color: #f6f6f9;\n  width: auto;\n  overflow: auto;\n  padding: 8px;\n  white-space: normal;\n  border: 1px solid gray;\n  /* transition: all ease 0.1s; */\n}\n\n.itp-hoverInfo-container:hover > .itp-hoverInfo-content {\n  visibility: visible;\n}\n\n.itp-hoverInfo-container.click-row:hover > .itp-hoverInfo-rowData {\n  color: #002340;\n}\n\n.itp-hoverInfo-p {\n  white-space: nowrap;\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
