import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchBoxFilter.css";

const SearchBoxFilter = ({ placeholder, handleChange, value, className }) => {
  const [inputFocused, setInputFocused] = useState(false);

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  return (
    <div className={`itp-container-search-input ${inputFocused ? "focused-input" : ""} ${className || ""}`}>
      <SearchIcon className="itp-custom-table-search-icon" />
      <input
        className="itp-search-input"
        placeholder={placeholder || ""}
        onChange={handleChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        value={value}
      />
    </div>
  );
};

export default SearchBoxFilter;
