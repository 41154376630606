// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-array-tags {
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0px;
  color: #437097;
  background: #51a9f01a 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  margin-right: 8px;
  display: inline-block;
  min-width: 4rem;
  width: auto;
  padding-top: 4px;
  padding-bottom: 4px;
}

.with-arrow {
  background-color: #edf6fd !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomTableMain/utils/renderCell/renderFunctions/Tags/Tags.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,iDAAiD;EACjD,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,qBAAqB;EACrB,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".itp-array-tags {\n  text-align: center;\n  font-family: Montserrat;\n  font-size: 14px;\n  letter-spacing: 0px;\n  color: #437097;\n  background: #51a9f01a 0% 0% no-repeat padding-box;\n  border-radius: 8px;\n  opacity: 1;\n  margin-right: 8px;\n  display: inline-block;\n  min-width: 4rem;\n  width: auto;\n  padding-top: 4px;\n  padding-bottom: 4px;\n}\n\n.with-arrow {\n  background-color: #edf6fd !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
