// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-hour {
  text-align: left;
  font: normal normal normal 10px/13px Instrument Sans;
  letter-spacing: 0px;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomTableMain/utils/renderCell/renderFunctions/Date/Date.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oDAAoD;EACpD,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".itp-hour {\n  text-align: left;\n  font: normal normal normal 10px/13px Instrument Sans;\n  letter-spacing: 0px;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
