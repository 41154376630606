// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown >.CustomDropDown{
    background: transparent!important;
    height: 30px !important;
    border: none;
    border-right: 1px solid #ccc !important;
    color: black !important;
}

.dropdown >.CustomDropDown:hover{
    background: #D8D8D8 !important;
    height: 30px !important;
    border: none;
    border-right: 1px solid #ccc !important;
    color: black !important;
}

.CustomDropDownItem:active{
    background: #D8D8D8 !important;
    color: black !important;
}

button.CustomDropDown.dropdown-toggle.btn.btn-secondary {
    height: 30px !important;
    padding: 5px 5px !important;
}

/* .dropdown-menu.show{
    border-radius: 0px !important;
} */

button.CustomDropDown.btn.btn-secondary {
    background: transparent !important;
    color: black;
    border: none;

}

button.CustomDropDown.btn.btn-secondary {
    padding: 5px 5px !important;

}
.dropDownTextAreaSelect.dropdown:nth-of-type(1) {
    border-left:1px solid #D8D8D8 !important ;
}`, "",{"version":3,"sources":["webpack://./src/components/TextAreaVarSelect/styles.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,uBAAuB;IACvB,YAAY;IACZ,uCAAuC;IACvC,uBAAuB;AAC3B;;AAEA;IACI,8BAA8B;IAC9B,uBAAuB;IACvB,YAAY;IACZ,uCAAuC;IACvC,uBAAuB;AAC3B;;AAEA;IACI,8BAA8B;IAC9B,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA;;GAEG;;AAEH;IACI,kCAAkC;IAClC,YAAY;IACZ,YAAY;;AAEhB;;AAEA;IACI,2BAA2B;;AAE/B;AACA;IACI,yCAAyC;AAC7C","sourcesContent":[".dropdown >.CustomDropDown{\n    background: transparent!important;\n    height: 30px !important;\n    border: none;\n    border-right: 1px solid #ccc !important;\n    color: black !important;\n}\n\n.dropdown >.CustomDropDown:hover{\n    background: #D8D8D8 !important;\n    height: 30px !important;\n    border: none;\n    border-right: 1px solid #ccc !important;\n    color: black !important;\n}\n\n.CustomDropDownItem:active{\n    background: #D8D8D8 !important;\n    color: black !important;\n}\n\nbutton.CustomDropDown.dropdown-toggle.btn.btn-secondary {\n    height: 30px !important;\n    padding: 5px 5px !important;\n}\n\n/* .dropdown-menu.show{\n    border-radius: 0px !important;\n} */\n\nbutton.CustomDropDown.btn.btn-secondary {\n    background: transparent !important;\n    color: black;\n    border: none;\n\n}\n\nbutton.CustomDropDown.btn.btn-secondary {\n    padding: 5px 5px !important;\n\n}\n.dropDownTextAreaSelect.dropdown:nth-of-type(1) {\n    border-left:1px solid #D8D8D8 !important ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
