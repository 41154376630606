import { Fragment } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Flows, PanelRight } from "../../../../../../components";
import { ChartMenuContent } from "../index";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import fullScreen from "../../../../../../assets/icons/fullScreen.svg";
import moreZoom from "../../../../../../assets/icons/moreZoom.svg";
import lessZoom from "../../../../../../assets/icons/lessZoom.svg";

const CallflowChart = ({ 
  toggleMenu, 
  openPanelCallFlows, 
  toggleFullScreen, 
  showHeader, 
  editFlowChart,
  deleteModule,
  editModuleWithBranch,
  callFlowDetails,
  setSelectedAction,
  selectedAction,
  closePanelCallFlows,
  activeEditChartCallFlow,
  activePanelCallFlows,
  timeGroups,
  isEnd,
  queues,
  switches,
  voicemails,
  users,
  plays,
  loadingCallFlowChart,
}) => {
  const wheelOptions = {
    step: 0.05,
  };

  return (
    <>
      <div>
        <div
          style={{ height: "853px" }}
          className={`itp-advanced-callFlows__flows ${toggleMenu ? "full-screen" : ""} ${openPanelCallFlows ? "open-panel" : ""}`}
        >
          <TransformWrapper
            maxScale={1.8}
            minScale={0.4}
            initialScale={1}
            wheel={wheelOptions}
            onInit={() => {}}
            onClick={(e) => console.log(e)}
            centerOnInit={true}
            disabled={false}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <Fragment>
                <div className={`itp-advanced-callFlows__flow-top-bar ${toggleMenu ? "full-screen" : ""}`}>
                  <label className="itp-advanced-callFlows__flow-top-bar-label">
                    Work Flow Chart
                  </label>
                  <div className="itp-advanced-callFlows__flow-top-bar-actions">
                    {!showHeader ? (
                      <CloseFullscreenIcon
                        onClick={toggleFullScreen}
                        className="itp-advanced-callFlows__flow-top-bar-icon white"
                      />
                    ) : (
                      <img
                        onClick={toggleFullScreen}
                        src={fullScreen}
                        alt="Full"
                        className="itp-advanced-callFlows__flow-top-bar-icon"
                      />
                    )}

                    <img
                      src={moreZoom}
                      alt="More"
                      className="itp-advanced-callFlows__flow-top-bar-icon"
                      onClick={() => zoomIn()}
                    />
                    <img
                      src={lessZoom}
                      alt="Less"
                      className="itp-advanced-callFlows__flow-top-bar-icon"
                      onClick={() => zoomOut()}
                    />
                    <button
                      onClick={editFlowChart}
                      className="itp-advanced-callFlows__flow-top-bar-button"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
                <TransformComponent
                  wrapperStyle={{
                    width: "100%",
                    height: "90%",
                  }}
                  disabled={true}
                >
                  <Flows
                    activeMenu={() => console.log("activeMenu")}
                    menu={false}
                    addFirstAction={() => console.log("addFirstAction")}
                    deleteFunction={deleteModule}
                    editModuleWithBranch={editModuleWithBranch}
                    onMenuTriger={() => console.log("onMenuTriger")}
                    data={callFlowDetails.current || {}}
                    setSelectedAction={setSelectedAction}
                    selectedAction={selectedAction}
                  />
                </TransformComponent>
              </Fragment>
            )}
          </TransformWrapper>
        </div>
        {callFlowDetails.current && (
          <PanelRight
            show={openPanelCallFlows}
            closeMenu={closePanelCallFlows}
            title="Actions"
            subtitle="Choose an action for a current state"
            to=""
          >
            {openPanelCallFlows && (
              <ChartMenuContent
                edit={activeEditChartCallFlow}
                data={openPanelCallFlows}
                callFlowDetails={callFlowDetails}
                activePanelCallFlows={activePanelCallFlows}
                closePanelCallFlows={closePanelCallFlows}
                timeGroups={timeGroups.current}
                isEnd={isEnd}
                queues={queues?.current}
                switches={switches.current}
                voicemails={voicemails.current}
                users={users.current}
                plays={plays.current}
                loadingCallFlowChart={loadingCallFlowChart}
              />
            )}
          </PanelRight>
        )}
      </div>
    </>
  );
};

export default CallflowChart;
