import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import { LoadingButton } from "../index"
import { GithubPicker } from "react-color";
import TextField from "@mui/material/TextField";
import "./ModalTagsColor.css";

const ModalTagsColor = ({ modal, closeModal, loading, title, sendDatatoParent, defaultTags }) => {
  const [useColor, setUseColor] = useState(false);
  const [color, setColor] = useState("#D0021B");
  const [name, setName] = useState("");

  const handleChangeComplete = (color) => {
    setColor(color.hex); 
    setUseColor(true);
  };

  const addtag = () => {
    let tag={};
    if (useColor === false) {
      tag = {
        inputValue: name,
      }
    }
    else {
      tag = {
        inputValue: name,
        tag_color: color,
      }
    }
    defaultTags.push(tag)
    closeModal()
    sendDatatoParent(defaultTags)
  }


  return (
    <Modal isOpen={modal ? true : false} className="info">     
      <ModalHeader>{title || "Modal Tag"}</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div style={{minHeight: "80px", width:"100%"}}>
              <Row>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <TextField
                    variant="standard"
                    placeholder="Name tag"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <div>
                    <Row>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                          <div style={{display:"flex", alignItems:"end", height:"30px"}}>
                            <span>Color</span>
                            <input type="checkbox" style={{marginLeft:"10px", marginBottom:"4px"}} value={useColor} onClick={()=>{setUseColor(!useColor)}}></input>
                          </div>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} style={{padding:"0px"}}>
                          <div style={{display: useColor ? "block" : "none"}}>
                            <div style={{ display: 'block', position: 'relative', zIndex: '2'}} className="">
                              <GithubPicker
                              color={color}
                              width="100%"
                              onChangeComplete={handleChangeComplete}
                              colors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505','#DFF21B']}
                              className="github-picker"
                              />
                            </div>
                          </div>
                        </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn-primary loading"
          onClick={() => {addtag()}}
        >
          {loading && <LoadingButton />}
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalTagsColor;
