import React, { useEffect, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { LoadingButton, ToggleImage } from "../../../../index";
import "./ActionDropdown.css"

const ActionDropdown = ({ index, actions, element, isOpen, toggle, loadingIcon }) => {
  const [stylePosition, setStylePosition] = useState({})

  const handleToggleClick = (event) => {    
    const togglePosition = event.currentTarget.getBoundingClientRect();
    const top = togglePosition.top;
    
    setStylePosition({ position: "fixed", top: `${top + 20}px`, zIndex: 9999 })
  };

  const selectClassName = (action) => {
    if (action?.canBeDisabled && !element[action?.keyDisabled]) {
      return `disabled-action ${action?.hoverDisabledAction ? "hover-action" : ""}`
    } else {
      return ""
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setStylePosition({})
    }
  }, [isOpen]);

  return (
    <>
      {loadingIcon === element ? (
        <LoadingButton />
      ) : (
        <Dropdown isOpen={isOpen} toggle={(event) => toggle(event, index)}>
          <DropdownToggle className="nav-link" onClick={handleToggleClick}>
          <ToggleImage />
          </DropdownToggle>
          <div style={stylePosition} className={isOpen ? "" : "d-none"}>
            <DropdownMenu className="custom-dropdown-menu">
              {actions.map((action, idx) => (
                <DropdownItem
                  key={`${action.name}-${idx}`}
                  onClick={() => action.handleClick(element, idx)}
                >
                  <div className={selectClassName(action)}>
                    {action.hoverDisabledAction ? (
                      <>
                        <div className="action-dropdown-hover">
                          <span className="action-dropdown-hover-text">
                            {action.textHover}
                          </span>
                        </div>
                        {action?.icon || ""} {action?.name || ""}
                      </>
                    ) : (
                      <>
                        {action?.icon || ""} {action?.name || ""}
                      </>
                    )}
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </div>
        </Dropdown>
      )}
    </>
  );
};

export default ActionDropdown;
