import { useEffect, useState } from "react";
import { fetchLeadDetails, updateActiveLeadService } from "../services";
import { FunnelStages, LabelInput, LeadSource, LoadingButton } from "../../../../../components";

const Leads = ({ chatWindowData, updateAlertVariables }) => {
  const [loading, setLoading] = useState(true)
  const [leadData, setLeadData] = useState(null)
  const [finishPetition, setFinishPetition] = useState(false)
  const [finishFetchFunnels, setFinishFetchFunnels] = useState(false)
  const [defaultLeadSource, setDefaultLeadSource] = useState(null)
  const [defaultFunnel, setDefaultFunnel] = useState(null)
  const [loadingButton, setLoadingButton] = useState(false)

  const [form, setForm] = useState({
    name: "",
    lead_source_id: null,
    funnel_id: null,
    funnel_pipeline_stage_id: null,
  })

  const handleChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleClickSave = async () => {
    const lead_id = leadData.pk
    await updateActiveLeadService(lead_id, loading, loadingButton, setLoadingButton, form, petition, updateAlertVariables)
  }

  const petition = async (updated) => {
    try {
      const { leadDetails, name, leadSource, funnel, funnel_id, funnel_pipeline_stage_id } = await fetchLeadDetails(chatWindowData)

      if (leadDetails) {
        setDefaultFunnel(funnel)
        setDefaultLeadSource(leadSource)
        setForm({ 
          ...form, 
          name, 
          lead_source_id: leadSource.pk,
          funnel_id, 
          funnel_pipeline_stage_id, 
        })
      } else {
        setFinishFetchFunnels(true)
      }

      setLeadData(leadDetails)
      setFinishPetition(true)
      setLoadingButton(false)

      if (updated) updateAlertVariables("success", "Success", "Lead updated successfully", 3000)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (finishPetition && finishFetchFunnels) setLoading(false)
  }, [finishPetition, finishFetchFunnels])

  useEffect(() => {
    petition()
  }, [chatWindowData])

  return (
    <div className="mt-3">
      {(loading || leadData) && (
        <>
          <LabelInput loading={loading} label="Lead Name">
            <input 
              type="text"
              name="name"
              className="form-control-itp itp-label-input__input"
              value={form.name}
              onChange={handleChangeForm}
            />
          </LabelInput>

          <LeadSource 
            defaultLeadSource={defaultLeadSource}
            loading={loading}
            setForm={setForm}
          />

          <FunnelStages 
            loading={loading}
            form={form}
            setForm={setForm}
            defaultFunnelId={form.funnel_id}
            defaultFunnel={defaultFunnel}
            setFinishFetchFunnels={setFinishFetchFunnels}
            defaultStageId={form.funnel_pipeline_stage_id}
          />

          <button 
            className="btn-primary loading"
            onClick={handleClickSave}
            disabled={!form.funnel_id || !form.funnel_pipeline_stage_id || !form.lead_source_id}
          >
            {loadingButton && <LoadingButton />}
            Save
          </button>
        </>
      )}

      {!loading && !leadData && (
        <div className="d-flex justify-content-center align-items-center w-100">
          <label className="itp-label-input__label">This contact is not assigned to any active lead</label>
        </div>
      )}
    </div>
  )
}

export default Leads;