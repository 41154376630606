// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-select-edit-user {
  width: 12rem !important;
  margin-right: 2rem;
}

.itp-edit-user-nageativeSeparator {
  margin-top: -3rem;
}

.itp-edit-user-permissions-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/Users/components/EditUser/EditUser.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".itp-select-edit-user {\n  width: 12rem !important;\n  margin-right: 2rem;\n}\n\n.itp-edit-user-nageativeSeparator {\n  margin-top: -3rem;\n}\n\n.itp-edit-user-permissions-content {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
