import { convertUtcDateToTimeZoneDate, formatDate } from "../../../../utils/getDate";

const processHistory = (contactHistory, timeZone) => {
  const processedHistory = contactHistory.map((element) => {
    const convertToTimeZoneDate = convertUtcDateToTimeZoneDate(element.created_at, timeZone)
    
    element.formatedCreatedAt = formatDate(convertToTimeZoneDate)

    return  element
  })

  return processedHistory
}

export default processHistory;