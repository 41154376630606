// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-custom-table-main {
  border-collapse: collapse;
  width: 100%;
}

.itp-custom-table-main-reduced {
  width: 100%;
  margin: 0 auto; /* Centrado horizontal */
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomTableMain/Components/Table/Table.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,cAAc,EAAE,wBAAwB;AAC1C","sourcesContent":[".itp-custom-table-main {\n  border-collapse: collapse;\n  width: 100%;\n}\n\n.itp-custom-table-main-reduced {\n  width: 100%;\n  margin: 0 auto; /* Centrado horizontal */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
