import petitionPatch from "../../../../../../services/petitionPatch"

const handleBlurService = async (key, form, setForm, currentForm, setCurrentForm, number, updateAlertVariables) => {
  if (form[key] !== currentForm[key]) {
    try {
      let data = {}
      
      if (key === "timeout") {
        data = {  timeout: parseInt(form.timeout) }
      } else {
        data = { [key]: form[key] }
      }
  
      await petitionPatch("numberEdit", { number, data })
      setCurrentForm(form)
    } catch (error) {
      console.log(error)

      let showMessageError = ""
      const isError400 = error?.response?.status === 400

      if (isError400) {
        const callerIdNameError = `caller_id_name: Length of '${form[key]}' ${form[key].length} > 16`
        const messageError = error?.response?.data?.error?.error_detailed?.error?.error_detailed

        if (messageError === callerIdNameError) {
          showMessageError = "Caller ID (CNAM). Maximum length exceeded, cannot be more than 16 characters."
        } else {
          showMessageError = messageError || "An error has ocurred"
        }
      } else {
        showMessageError = "An error has ocurred"
      }

      setForm(currentForm)
      updateAlertVariables("error", "Error", showMessageError, 3000)
    }
  }
}

export default handleBlurService;