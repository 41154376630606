import petitionPatch from "../../../../../../services/petitionPatch";

const updateActiveLeadService = async (lead_id, loading, loadingButton, setLoadingButton, form, petition, updateAlertVariables) => {
  try {
    if (loading || loadingButton) return;
    setLoadingButton(true)

    await petitionPatch("leads", { data: form, lead_id })
    petition(true)
  } catch (error) {
    console.log(error)
    updateAlertVariables("error", "Error", "An error has ocurred, please try agaain", 3000)
    setLoadingButton(false)
  }
}

export default updateActiveLeadService;