// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-modal-addTag-color-container {
  display: flex;
  align-items: flex-end;
  height: 30px;
}

.form-check-input.itp-modal-addTag-checkColor {
  margin-left: 10px;
  margin-bottom: 4px;
}

.itp-modal-addColor-pickerColor-container {
  display: block;
  position: relative;
  z-index: 2;
}

.itp-modal-addTag-container {
  min-height: 80px;
  width: 100%;
  margin-bottom: -2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatList/components/BulkActionTags/BulkActionTags.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,oBAAoB;AACtB","sourcesContent":[".itp-modal-addTag-color-container {\n  display: flex;\n  align-items: flex-end;\n  height: 30px;\n}\n\n.form-check-input.itp-modal-addTag-checkColor {\n  margin-left: 10px;\n  margin-bottom: 4px;\n}\n\n.itp-modal-addColor-pickerColor-container {\n  display: block;\n  position: relative;\n  z-index: 2;\n}\n\n.itp-modal-addTag-container {\n  min-height: 80px;\n  width: 100%;\n  margin-bottom: -2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
