import petitionGet from "../../../../../../services/petitionGet";

const fetchAdminAccountDetails = async () => {
  try {
    const { data: result } = await petitionGet("accountDetails");

    return result.result;
  } catch (error) {
    console.log(error);
  }
};

export default fetchAdminAccountDetails;
