import petitionGet from "../../../../../../services/petitionGet";

const fetchQuestionList = async (info, setQuestionListValue, setQuestionsList) => {
  const { data: result } = await petitionGet("salesLead", { parameter: "?ofsset=0&limit=10" });

  result.result.forEach((element) => {
    element.label = element.name;
    element.value = element.pk;

    if (element.pk === info.ai_lead_manager_screening_questions_list_id) {
      setQuestionListValue(element);
    }
  });

  setQuestionsList(result.result);
};

export default fetchQuestionList;
