import json from "../../../../../config.json"

const downloadCallService = (value, setLoadingCall, setModalError) => {
  return new Promise(async (resolve, reject) => {
    try {
      setLoadingCall(value);
      const token = JSON.parse(localStorage.getItem("userDetails"))?.access_token;

      const urlB = json.prod ? json.urlBase.prod : json.urlBase.dev;

      const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;

      const downloadFile = async () =>
        fetch(
          `${urlB}/itpvoice/v2/${id}/cdr/${value.pk}/download-recording?accept=audio/mpeg`,
          {
            headers: {
              Authorization: "Bearer " + token.replace(/['"]+/g, ""),
            },
          }
        ).then((res) => {
          if (res.status === 200) {
            return res.blob();
          } else if (res.status === 404) {
            return res.status;
          }
        });
      const createURL = (blob) => URL.createObjectURL(blob);

      const blob = await downloadFile();
      if (blob === 404) {
        setLoadingCall(null); 
        setModalError(true);
        return;
      }
      const create = createURL(blob);

      let link = document.createElement("a");
      link.download = `${value.call_recording_filename}.mp3`;
      link.href = create;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();

      setLoadingCall(false);

      resolve(true);
    } catch (error) {
      setLoadingCall(false);
      reject(error);
    }
  });
};

export default downloadCallService;
