// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-select-add-lead-label {
  text-align: left;
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-add-lead-select {
  margin-top: 6px;
  width: 90%;
}

.itp-lead-button {
  width: 4rem;
  height: 2rem;
  margin-top: 1.5rem;
}

.itp-add-lead-new-contact {
  margin-top: 8px;
  width: 80%;
}

.basic-single.add-lead-select {
  margin-top: 4px;
  width: 80%;
}

.add-lead-container-field {
  margin-bottom: 12px;
}

.add-lead-new-contact {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.select__placeholder.css-1jqq78o-placeholder {
  font: normal normal normal 14px Instrument Sans !important;
  letter-spacing: 0px !important;
  color: #3a3a3a99 !important;
  opacity: 1 !important;
}


.css-1dimb5e-singleValue {
  color: #535353 !important;
  font-size: 14px;

}

.css-tj5bde-Svg {
  color: #535353;
}

.select__control.css-13cymwt-control:active {
  border: 0.30000001192092896px solid #1976d2 !important;
}

.select__control.css-13cymwt-control:focus {
  border: 0.30000001192092896px solid #1976d2 !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/private/Leads/AddLead/AddLead.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oDAAoD;EACpD,mBAAmB;EACnB,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,0DAA0D;EAC1D,8BAA8B;EAC9B,2BAA2B;EAC3B,qBAAqB;AACvB;;;AAGA;EACE,yBAAyB;EACzB,eAAe;;AAEjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,sDAAsD;AACxD;;AAEA;EACE,sDAAsD;AACxD","sourcesContent":[".itp-select-add-lead-label {\n  text-align: left;\n  font: normal normal normal 12px/16px Instrument Sans;\n  letter-spacing: 0px;\n  color: #002340;\n  opacity: 1;\n}\n\n.itp-add-lead-select {\n  margin-top: 6px;\n  width: 90%;\n}\n\n.itp-lead-button {\n  width: 4rem;\n  height: 2rem;\n  margin-top: 1.5rem;\n}\n\n.itp-add-lead-new-contact {\n  margin-top: 8px;\n  width: 80%;\n}\n\n.basic-single.add-lead-select {\n  margin-top: 4px;\n  width: 80%;\n}\n\n.add-lead-container-field {\n  margin-bottom: 12px;\n}\n\n.add-lead-new-contact {\n  margin-top: 3rem;\n  margin-bottom: 3rem;\n}\n\n.select__placeholder.css-1jqq78o-placeholder {\n  font: normal normal normal 14px Instrument Sans !important;\n  letter-spacing: 0px !important;\n  color: #3a3a3a99 !important;\n  opacity: 1 !important;\n}\n\n\n.css-1dimb5e-singleValue {\n  color: #535353 !important;\n  font-size: 14px;\n\n}\n\n.css-tj5bde-Svg {\n  color: #535353;\n}\n\n.select__control.css-13cymwt-control:active {\n  border: 0.30000001192092896px solid #1976d2 !important;\n}\n\n.select__control.css-13cymwt-control:focus {\n  border: 0.30000001192092896px solid #1976d2 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
