import petitionPatch from "../../../../../services/petitionPatch";

const editContactService = async (setError, setLoading, data, contact_id, reloadData) => {
  try {
    setError("");
    setLoading(true);

    await petitionPatch("contact", { data, contact_id });

    setLoading(false);
    reloadData()
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 400) {
      const messageError = error?.response?.data?.error?.error_message;
      setError(messageError);
    }

    setLoading(false);
  }
};

export default editContactService;
