// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basic-single.edit-user-time-zone.css-b62m3t-container {
  margin-right: 2rem;
  width: 12rem;
}

.itp-edit-user-skeleton-timeZone {
  margin-right: 2rem;
  width: 12rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/Users/components/EditUser/components/BasicSettings/BasicSettings.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".basic-single.edit-user-time-zone.css-b62m3t-container {\n  margin-right: 2rem;\n  width: 12rem;\n}\n\n.itp-edit-user-skeleton-timeZone {\n  margin-right: 2rem;\n  width: 12rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
