import "./LoadingDots.css"

const LoadingDots = ({ className }) => (
  <div className={`loading-dots ${className || ""}`}>
    <span></span>
    <span></span>
    <span></span>
  </div>
);

export default LoadingDots;
