// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-add-number-border {
  border: 2px solid rgb(128, 128, 128, 0.5);
  width: 10rem;
}

.itp-add-number-border.active {
  border: 2px solid #002340;
}

.itp-edit-number-container-second-step {
  margin-left: 8px;
}

.mt-3rem {
  margin-top: 3rem !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/PhoneNumbers/components/AddNumber/AddNumber.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".itp-add-number-border {\n  border: 2px solid rgb(128, 128, 128, 0.5);\n  width: 10rem;\n}\n\n.itp-add-number-border.active {\n  border: 2px solid #002340;\n}\n\n.itp-edit-number-container-second-step {\n  margin-left: 8px;\n}\n\n.mt-3rem {\n  margin-top: 3rem !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
