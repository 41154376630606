// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.itp-phone-nuumber-click {
  text-decoration: none;
}

a.itp-phone-nuumber-click:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomTableDefault/componennts/PhoneNumberLink/PhoneNumberLink.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["a.itp-phone-nuumber-click {\n  text-decoration: none;\n}\n\na.itp-phone-nuumber-click:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
