// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-table-container-allf.card.customize {
  overflow: inherit!important;
  width: 960px!important;
  margin: auto;
}

.form-letter-property {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Instrument Sans', sans-serif;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/Forms/Forms.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,0CAA0C;EAC1C,mBAAmB;EACnB,cAAc;EACd,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".itp-table-container-allf.card.customize {\n  overflow: inherit!important;\n  width: 960px!important;\n  margin: auto;\n}\n\n.form-letter-property {\n  font-weight: 600;\n  font-size: 18px;\n  line-height: 24px;\n  font-family: 'Instrument Sans', sans-serif;\n  letter-spacing: 0px;\n  color: #3A3A3A;\n  opacity: 1;\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
