import React, { useEffect, useRef, useState } from "react";
import { fetchCallEvents } from "../../services";
import { CustomTableMain } from "../../../../../components";
import useScreenSize from "../../../../../hooks/useScreenSize";

const CallEvents = ({ details, timeZone, totalUsers, totalQueues, selectedReport }) => {
  const itemsPerPage = useRef("10")

  const { width } = useScreenSize()

  const [loading, setLoading] = useState(true);

  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Time", key: "time" },
      { name: "Event", key: "parsedEvent" },
    ],
  });

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleChangeItemsPerPage = (data) => {
    itemsPerPage.current = data
    forceUpdate()
  }

  const petition = async () => {
    const callEvents = await fetchCallEvents( details, timeZone, totalUsers, totalQueues, selectedReport);
    setDataTable({
      columns: [
        { name: "Time", key: "time" },
        { name: "Event", key: "parsedEvent" },
      ],
      content: [...callEvents],
    });
    setLoading(false);
  };

  useEffect(() => {
    petition();
  }, []);

  return (
    <>
      <CustomTableMain
        data={dataTable}
        loading={loading}
        module="call events"
        m3={true}
        responsive={width <= 1182 ? true : false}
        showSelectSingleCheckbox={true}
        paginationData={parseInt(itemsPerPage.current)}
        onChangeItemsPerPage={handleChangeItemsPerPage}
        columnConfig={{
          "Time": {
            type: "date",
          }
        }}
      />
    </>
  );
};

export default CallEvents;
