// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  overflow: hidden visible !important;
}

.webphone-component{
  position: fixed;
  z-index: 100000;
  left: calc(50% - 127px);
  top: 100px;
  /* width: 100%;
  height: 100%;
  background: red; */
}

.reconnectedAction{
  text-decoration: underline;
  cursor: pointer;
  margin-left: 20px;
  font-weight: 600;
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;AACrC;;AAEA;EACE,eAAe;EACf,eAAe;EACf,uBAAuB;EACvB,UAAU;EACV;;oBAEkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB","sourcesContent":["body {\n  overflow: hidden visible !important;\n}\n\n.webphone-component{\n  position: fixed;\n  z-index: 100000;\n  left: calc(50% - 127px);\n  top: 100px;\n  /* width: 100%;\n  height: 100%;\n  background: red; */\n}\n\n.reconnectedAction{\n  text-decoration: underline;\n  cursor: pointer;\n  margin-left: 20px;\n  font-weight: 600;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
