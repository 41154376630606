// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-show-filter-button {
  min-width: 7.2rem;
  width: auto;
  height: 2.1rem;
}

@media (max-width: 514px) {
  .itp-show-filter-button {
    min-width: 9.5rem;
    width: auto;
    height: 2.1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ShowFilterButton/ShowFilterButton.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE;IACE,iBAAiB;IACjB,WAAW;IACX,cAAc;EAChB;AACF","sourcesContent":[".itp-show-filter-button {\n  min-width: 7.2rem;\n  width: auto;\n  height: 2.1rem;\n}\n\n@media (max-width: 514px) {\n  .itp-show-filter-button {\n    min-width: 9.5rem;\n    width: auto;\n    height: 2.1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
