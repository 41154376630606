import petitionPatch from "../../../../../../services/petitionPatch"

const handleChangeFunnelService = async (e, number, funnelValue, setFunnelValue, updateAlertVariables) => {
  const currentFunnelValue = funnelValue

  try {
    setFunnelValue(e)

    const data = {
      ai_lead_manager_funnel_id: e.pk,
    }

    await petitionPatch("numberEdit", { number, data })
  } catch (error) {
    console.log(error)
    setFunnelValue(currentFunnelValue)
    updateAlertVariables("error", "Error", "An error has ocured", 3000)
  }
}

export default handleChangeFunnelService;