import React from "react";
import TableHeader from "../TableHeader/TableHeader";
import TableRow from "../TableRow/TableRow";
import useRowSelect from "../../Hooks/useRowSelect";
import "./Table.css";

const Table = ({
  centered,
  data,
  currentData,
  module,
  noDataMessage,
  withCheckboxes,
  externalSelectedRows,
  onSelectionChange,
  selectedAllRows,
  getBorderColor,
  colorColumnName,
  getTextColor,
  borderColumnName,
  columnConfig,
  renderCallStatus,
  clickRow,
  defaultOrderColumn,
  columnNames,
  changeColumnOrder,
  loading,
  loadingIcon,
}) => {
  // useRowSelect handles the logic of selecting rows with its checkbox
  // Inputs:
  // - currentData: table rows of the current page.
  // - externalSelectedRows: array with the selected ids.
  // - onSelectionChange: function that receives the new value with the selected ids.

  // Outputs:
  // - toggleRow: function to mark or unmark a row.
  // - isSelected: function to find out if the checkbox is checked.
  // - toggleAll: function to check or uncheck all checkboxes in all rows of the current page.
  // - isAllSelected: variable indicating whether all the rows on the current page are checked..

  const { toggleRow, isSelected, toggleAll, isAllSelected } = useRowSelect(
    currentData,
    externalSelectedRows,
    onSelectionChange
  );

  //this ensures that if the viewColumn property on the object where the column is named is false, 
  //then the entire column is not displayed.
  const validViewColumn = (arr) => {
    return arr?.filter((el) => el?.viewColumn !== false);
  };

  return (
    <>
      <table className={`itp-custom-table-main ${centered ? "itp-custom-table-main-reduced" : ""}`}>
        {/* Renders the table header based on the columns and actions provided */}
        <TableHeader
          columns={data.columns}
          actions={data?.actions}
          withCheckboxes={withCheckboxes}
          toggleAll={toggleAll}
          isAllSelected={isAllSelected}
          selectedAllRows={selectedAllRows}
          validViewColumn={validViewColumn}
          defaultOrderColumn={defaultOrderColumn} 
          columnNames={columnNames} 
          changeColumnOrder={changeColumnOrder} 
          loading={loading}
        />
        <tbody>
          {/* Map the current data to rows of the table*/}
          {currentData?.length > 0 ? (
            currentData?.map((element, index) => (
              <TableRow
                allData={data}
                key={index}
                index={index}
                element={element}
                columns={data?.columns}
                actions={data?.actions?.content}
                switchActions={data?.actions?.isSwitch}
                iconAction={data?.actions?.isIcon}
                withCheckboxes={withCheckboxes}
                toggleRow={toggleRow}
                isSelected={isSelected}
                selectedAllRows={selectedAllRows}
                validViewColumn={validViewColumn}
                getBorderColor={getBorderColor}
                colorColumnName={colorColumnName}
                getTextColor={getTextColor}
                borderColumnName={borderColumnName}
                columnConfig={columnConfig}
                renderCallStatus={renderCallStatus}
                clickRow={clickRow}
                loading={loading}
                loadingIcon={loadingIcon}
              />
            ))
          ) : (
            <>
              {!loading && (
                <tr>
                  <td
                    colSpan={data.columns.length + (data.actions ? 1 : 0)}
                    className="text-center"
                  >
                    {/* Use the custom noDataMessage if provided, or fall back to a default message */}
                    {noDataMessage || `You currently have no ${module ? module : "records"}.`}
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Table;
