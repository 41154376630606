import React from "react";
import { Link } from "react-router-dom";
import "./PhoneNumberLink.css"

const PhoneNumberLink = ({ onClickNumber, number }) => {
  const handleLinkClick = (event) => {
    event.stopPropagation()
    onClickNumber(number);
  };

  return (
    <Link to="#" onClick={handleLinkClick} className="itp-phone-nuumber-click">
      {number}
    </Link>
  );
};

export default PhoneNumberLink;
