// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.important {
    width: 93%!important;
    margin-top: 40px!important;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/LivePanel/components/CallParkingSlots/CallParkingSlots.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,0BAA0B;AAC9B","sourcesContent":[".important {\n    width: 93%!important;\n    margin-top: 40px!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
