// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-container-search-input {
  display: flex;
  align-items: center;
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  opacity: 1;
  width: 30rem;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  height: 35px;
  background-color: #fff;
}

@media (min-width: 389px) and (max-width: 542px) {
  .itp-container-search-input {
    width: 20rem;
  }
}
@media (max-width: 388px) {
  .itp-container-search-input {
    width: 17rem;
  }
}

.itp-container-search-input.focused-input {
  border: 1px solid #6d28d9;
}

.itp-search-input {
  border: 0;
  outline: none;
  background-color: transparent;
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  width: 100%;
}

input.itp-search-input::placeholder {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchBoxFilter/SearchBoxFilter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,SAAS;EACT,aAAa;EACb,6BAA6B;EAC7B,oDAAoD;EACpD,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".itp-container-search-input {\n  display: flex;\n  align-items: center;\n  border: 1px solid #b9b9b9;\n  border-radius: 8px;\n  opacity: 1;\n  width: 30rem;\n  padding-left: 8px;\n  padding-top: 4px;\n  padding-bottom: 4px;\n  height: 35px;\n  background-color: #fff;\n}\n\n@media (min-width: 389px) and (max-width: 542px) {\n  .itp-container-search-input {\n    width: 20rem;\n  }\n}\n@media (max-width: 388px) {\n  .itp-container-search-input {\n    width: 17rem;\n  }\n}\n\n.itp-container-search-input.focused-input {\n  border: 1px solid #6d28d9;\n}\n\n.itp-search-input {\n  border: 0;\n  outline: none;\n  background-color: transparent;\n  font: normal normal normal 12px/16px Instrument Sans;\n  letter-spacing: 0px;\n  color: #3a3a3acc;\n  opacity: 1;\n  width: 100%;\n}\n\ninput.itp-search-input::placeholder {\n  font: normal normal normal 12px/16px Instrument Sans;\n  letter-spacing: 0px;\n  color: #3a3a3acc;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
