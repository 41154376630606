// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-livePanel-switch-filter-label {
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0px;
  color: #002340;
  margin-right: 8px;
  font-weight: 100 !important;
}

.itp-livePanel-switch-filter .react-switch-handle {
  width: 17px !important;
  height: 17px !important;
  top: 1px !important;
  left: 1px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/private/LivePanel/components/Extensions/components/Filters/Filters.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".itp-livePanel-switch-filter-label {\n  font-family: Montserrat;\n  font-size: 12px;\n  letter-spacing: 0px;\n  color: #002340;\n  margin-right: 8px;\n  font-weight: 100 !important;\n}\n\n.itp-livePanel-switch-filter .react-switch-handle {\n  width: 17px !important;\n  height: 17px !important;\n  top: 1px !important;\n  left: 1px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
