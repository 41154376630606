import { formatedThreads } from "../../utils";
import petitionGet from "../../../../../../services/petitionGet";

const threadListService = async (number, query) => {
  const parameter = query || "?offset=0&limit=15"
  const response = await petitionGet("listSms", { parameter, number })
  const { data: result } = response
  const threadList = formatedThreads(result)

  return threadList
}

export default threadListService;