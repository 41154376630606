import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import { LoadingButton, TagComponent } from "../../../../../components";

const ModalTags = ({ modal, closeModal, loading, modalFunction, title }) => {
  const keyTag = title === "Add Tags" ? "added_tags" : "removed_tags"

  const [tags, setTags] = useState([]);
  const [form, setForm] = useState({ [keyTag]: [] });

  const handleTag = (newValue) => {
    setTags(newValue);

    let listPk = newValue.map((tag) =>  tag.pk);
    setForm({ [keyTag]: listPk });
  };

  const saveFunction = () => {
    modalFunction(form)
  }

  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{title || "Modal Tag"}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {!loading && <label className="itp-component-tag-label">Tags</label>}
            <TagComponent 
               tags={tags}
               handleTag={handleTag}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn-primary loading"
          onClick={saveFunction}
        >
          {loading && <LoadingButton />}
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalTags;
