// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-data-report-date {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-right: 2rem;
}

.itp-data-report-range-date {
  font: normal normal 600 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.form-select-itp.itp-call-logs-select {
  width: 12rem;
  margin-top: 20px;
}

.itp-tex-back-container.itp-analytics-report {
  top: 5rem;
}

.grphic-title {
  text-align: center;
  margin: 10px 10px 10px 10px;
}

.form-select-itp.width-12r {
  width: 12rem;
}

@media (max-width: 922px) {
  .form-select-itp.width-12r {
    margin-bottom: 1rem;
  }
}

.dropdown-menu.show.itp-responsive-table-action-dropdown {
  inset: -5rem 0 auto auto !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/Analytics/components/DataReport/DataReport.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,iDAAiD;EACjD,mBAAmB;EACnB,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".itp-data-report-date {\n  font: normal normal normal 14px/19px Instrument Sans;\n  letter-spacing: 0px;\n  color: #3a3a3acc;\n  opacity: 1;\n  margin-right: 2rem;\n}\n\n.itp-data-report-range-date {\n  font: normal normal 600 14px/19px Instrument Sans;\n  letter-spacing: 0px;\n  color: #002340;\n  opacity: 1;\n}\n\n.form-select-itp.itp-call-logs-select {\n  width: 12rem;\n  margin-top: 20px;\n}\n\n.itp-tex-back-container.itp-analytics-report {\n  top: 5rem;\n}\n\n.grphic-title {\n  text-align: center;\n  margin: 10px 10px 10px 10px;\n}\n\n.form-select-itp.width-12r {\n  width: 12rem;\n}\n\n@media (max-width: 922px) {\n  .form-select-itp.width-12r {\n    margin-bottom: 1rem;\n  }\n}\n\n.dropdown-menu.show.itp-responsive-table-action-dropdown {\n  inset: -5rem 0 auto auto !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
