import petitionPost from "../../../../../services/petitionPost";

const addLeadService = async (form, setLoadingLead, reloadData, setErrorAddContact) => {
  setLoadingLead(true);

  try {
    await petitionPost("leads", { data: form })

    setLoadingLead(false);
    reloadData(false);
  } catch (error) {
    console.log(error)
    if (error?.response?.status === 400) {
      const errorMessage = error?.response?.data?.error?.error_message || ""
      setErrorAddContact(errorMessage)
    } else {
      setErrorAddContact("An error has ocurred, please try again")
    }
    setLoadingLead(false);
  }
}

export default addLeadService;