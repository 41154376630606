// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-avatar {
  width: 35px;
  height: 35px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 50% !important;
  font-size: .857rem;
  font-weight: 600;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  line-height: 1;
  overflow: visible;
  position: relative;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.itp-avatar-text {
  font-size: calc(14px);
  border-radius: inherit;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-mask-image: radial-gradient(#fff,#000);
          mask-image: radial-gradient(#fff,#000);
}`, "",{"version":3,"sources":["webpack://./src/components/Avatar/Avatar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,gGAAgG;AAClG;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,8CAAsC;UAAtC,sCAAsC;AACxC","sourcesContent":[".itp-avatar {\n  width: 35px;\n  height: 35px;\n  margin-left: 5px;\n  margin-top: 5px;\n  text-align: center;\n  border-radius: 50% !important;\n  font-size: .857rem;\n  font-weight: 600;\n  color: #fff;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  vertical-align: middle;\n  line-height: 1;\n  overflow: visible;\n  position: relative;\n  transition: color .15s ease-in-out,background-color .15s ease-in-out,box-shadow .15s ease-in-out;\n}\n\n.itp-avatar-text {\n  font-size: calc(14px);\n  border-radius: inherit;\n  text-transform: uppercase;\n  white-space: nowrap;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  mask-image: radial-gradient(#fff,#000);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
