import React, { useState } from "react";
import { renderCell } from "../../utils";
import ActionDropdown from "./ActionDropdown/ActionDropdown ";
import SwitchAction from "./SwitchAction/SwitchAction";
import IconAction from "./IconAction/IconAction";
import Skeleton from "react-loading-skeleton";
import "./TableRow.css";

const TableRow = React.memo (({
  allData,
  element,
  columns,
  actions,
  switchActions,
  iconAction,
  index,
  withCheckboxes,
  toggleRow,
  isSelected,
  selectedAllRows,
  validViewColumn,
  getBorderColor,
  borderColumnName,
  colorColumnName,
  getTextColor,
  columnConfig,
  renderCallStatus,
  clickRow,
  loading,
  loadingIcon,
}) => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const toggle = (event, i) => {
    event?.stopPropagation();
    setOpenDropdownIndex(i === openDropdownIndex ? null : i);
  };

  const onChangeCheckbox = (element) => {
    toggleRow(element.pk)
  }

  const handleClickRow = () => {
    clickRow(element)
  }

  return (
    <>
      <tr style={element?.rowStyles || {}} onClick={handleClickRow} className="itp-custom-table-main-row">
        {withCheckboxes && (
          <td>
            {loading ? (
              <Skeleton height={20} width="20px" />
            ) : (
              <div onBlur={(event) => event.stopPropagation()} onClick={(event) => event.stopPropagation()}>
                <input
                  type="checkbox"
                  checked={isSelected(element.pk) || selectedAllRows} //It is selected if the row checkbox has been clicked or if all rows have been selected
                  onChange={(event) => { event.stopPropagation(); onChangeCheckbox(element)}} 
                  className="form-check-input"
                  disabled={selectedAllRows} //If you have clicked to select all the rows, it is disabled
                />
              </div>
            )}
          </td>
        )}

        {validViewColumn(columns).map((column) => (
          <td 
            key={column.name} 
            className={`itp-custom-table-main-td-styles ${columns.length < 2 ? "text-center" : ""}`}
            style={{
              // here you put the left border or text color to a column
              borderLeft: (column.name === borderColumnName && !loading) ? `6px solid ${getBorderColor(element)}` : '',
              color: (column.name === colorColumnName && !loading) ? getTextColor(element) : '',
            }}
          >
            {loading ? (
              <Skeleton height={30} width="120px" />
            ) : (
              <>
                {renderCell(element, column, columnConfig[column?.name], allData, renderCallStatus)}
              </>
            )}
          </td>
        ))}

        {actions && (
          <td className={!switchActions && iconAction ? "text-center" : ""}>
            {loading ? (
              <Skeleton height={30} width="120px" />
            ) : (
              <>
                {!switchActions && !iconAction && (
                  <ActionDropdown
                    index={index}
                    actions={actions}
                    element={element}
                    isOpen={index === openDropdownIndex}
                    toggle={toggle}
                    loadingIcon={loadingIcon}
                  />
                )}

                {!switchActions && iconAction && (
                  <IconAction 
                    element={element}
                    actions={actions}
                    loadingIcon={loadingIcon}
                  />
                )}

                {switchActions && !iconAction && (
                  <SwitchAction 
                    actions={actions}
                    element={element}
                  />
                )}
              </>
            )}
          </td>
        )}
      </tr>
    </>
  );
});

export default TableRow;
