import React, { useState, useRef, useEffect, useCallback } from "react";
import { LoadingButton } from "../index";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import VolumeMuteOutlinedIcon from "@mui/icons-material/VolumeMuteOutlined";
import Slider from "@mui/material/Slider";
import profile from "../../assets/images/profile.png";
import voiceSquare from "../../assets/icons/voiceSquare.svg";
import microphone from "../../assets/icons/microphone.svg";
import UserAvatar from "../UserAvatar/UserAvatar";
import ModalError from "./ModalError/ModalError";
import togglePlay from "./logic/togglePlay";
import formatTime from "./logic/formatTime";
import handleVolumeChangeFunction from "./logic/handleVolumeChangeFunction";
import useDownloadAudio from "./hooks/useDownloadAudio";
import useAudioRef from "./hooks/useAudioRef";
import "./AudioMessage.css";

const AudioMessage = ({
  audioUrl,
  firstname,
  lastname,
  avatar,
  icon,
  hiddenTime,
  w100,
  noBorderContainer,
  outVolume,
  ml0,
  styles,
  noFetch,
  voicemailIcon,
  recordingIcon,
  downloadWhenClicked,
  customDownload,
  leftInAudioIcon,
  leftInMessageIcon,
  marginLeftContainerAudio,
  leftAvatar,
  barHeight,
  contacts,
  stylesVolume,
  audioType,
}) => {
  const {
    waveformRef,
    loadingDownload,
    setStartDownload,
    timeProgress,
    setTimeProgress,
    disabled,
    modal,
    setModal,
    audio,
  } = useDownloadAudio(audioUrl, customDownload, downloadWhenClicked, noFetch, barHeight, audioType)

  const {
    onLoadedMetadata,
    handleProgressChange,
    onClickVolumenIcon,
    audioRef,
    progressBarRef,
    duration,
    isPlaying,
    setIsPlaying,
    volume,
    setVolume,
    muteVolume,
  } = useAudioRef(setTimeProgress)

  const togglePlayFunction = () => {
    togglePlay(downloadWhenClicked, disabled, setStartDownload, isPlaying, setIsPlaying)
  }

  const handleVolumeChange = (newValue) => {
    handleVolumeChangeFunction(newValue, disabled, setVolume, audioRef)
  };

  return (
    <div
      style={styles || {}}
      className={`audio-message ${noBorderContainer ? "border0" : "boder-container "}`}
    >
      {icon ? (
        <img src={voiceSquare} alt="" className="audio-message-voicemail" />
      ) : (
        <div className="position-relative">
          {!avatar ? (
            <img
              className="audio-message-image rounded-circle voicemail-icon"
              src={profile}
              alt="profile"
              style={{ left: leftInAudioIcon || "" }}
            />
          ) : (
            <UserAvatar
              firstname={firstname}
              lastname={lastname}
              className="audio-message-image rounded-circle"
              style={{ left: leftAvatar || "" }}
              contacts={contacts}
            />
          )}

          {voicemailIcon && (
            <img
              className="audio-message-icon-absolute"
              src={voiceSquare}
              alt=""
              style={{ left: leftInMessageIcon || "" }}
            />
          )}

          {recordingIcon && (
            <img
              className="audio-message-icon-absolute"
              src={microphone}
              alt=""
              style={{ left: leftInMessageIcon || "" }}
            />
          )}
        </div>
      )}

      <div
        style={{ marginLeft: marginLeftContainerAudio || "" }}
        className={`container-audio ${w100 ? "w100" : "0"}`}
      >
        {!disabled && (
          <audio
            ref={audioRef}
            src={!disabled ? audio : "http://localhost:3000"}
            onLoadedMetadata={onLoadedMetadata}
          />
        )}

        <div className="audio-controls">
          <div className="play-pause" onClick={togglePlayFunction}>
            {loadingDownload ? (
              <div className="container-loading-audio">
                <LoadingButton />
              </div>
            ) : (
              <div className="play-pause" onClick={togglePlayFunction}>
                {isPlaying ? (
                  <PauseIcon
                    className={`audio-message-icon ${ml0 ? "ml-0" : ""}`}
                  />
                ) : (
                  <PlayArrowIcon
                    className={`audio-message-icon ${ml0 ? "ml-0" : ""}`}
                  />
                )}
              </div>
            )}
          </div>
          <div className={`progress-bar-container ${w100 ? "w100" : "0"}`}>
            <div className="progress-bar-background" />
            <div ref={waveformRef} className="waveform-canvas" />
            <input
              type="range"
              ref={progressBarRef}
              defaultValue="0"
              onChange={handleProgressChange}
              disabled={disabled}
            />
          </div>
        </div>

        {!outVolume && (
          <div className="container-volumen-icon" style={stylesVolume || {}}>
            {(muteVolume || volume === 0) && (
              <VolumeMuteOutlinedIcon
                className="volume-icon"
                onClick={onClickVolumenIcon}
              />
            )}
            {(!muteVolume && volume > 0) && (
              <VolumeUpOutlinedIcon
                className="volume-icon"
                onClick={onClickVolumenIcon}
              />
            )}

            <div className="volume-slider">
              <Slider
                value={volume}
                onChange={(e, newValue) => setVolume(newValue)}
                aria-label="Custom slider"
                classes={{
                  root: "custom-slider-root", // Aplica la clase al elemento raíz
                  track: "custom-slider-track", // Aplica la clase a la línea de seguimiento
                  thumb: "custom-slider-thumb", // Aplica la clase al icono redondo
                }}
                sx={{
                  // Aplica estilos personalizados aquí
                  fontSize: "0.75rem", // Puedes ajustar el valor según tus necesidades
                  height: 2, // Puedes ajustar el valor según tus necesidades
                }}
              />
            </div>
          </div>
        )}

        <div className={`time-info ${hiddenTime ? "d-none" : ""}`}>
          {formatTime(timeProgress)} / {formatTime(duration)}
        </div>
      </div>

      {outVolume && (
        <div className="container-volumen-icon out">
          {volume === 0 && (
            <VolumeMuteOutlinedIcon
              className="volume-icon"
              onClick={onClickVolumenIcon}
            />
          )}
          {volume > 0 && (
            <VolumeUpOutlinedIcon
              className="volume-icon"
              onClick={onClickVolumenIcon}
            />
          )}

          <div className="volume-slider out">
            <Slider
              value={volume}
              onChange={(e, newValue) => setVolume(newValue)}
              aria-label="Custom slider"
              classes={{
                root: "custom-slider-root", // Aplica la clase al elemento raíz
                track: "custom-slider-track", // Aplica la clase a la línea de seguimiento
                thumb: "custom-slider-thumb", // Aplica la clase al icono redondo
              }}
              sx={{
                // Aplica estilos personalizados aquí
                fontSize: "0.75rem", // Puedes ajustar el valor según tus necesidades
                height: 2, // Puedes ajustar el valor según tus necesidades
              }}
            />
          </div>
        </div>
      )}
      {modal && <ModalError modal={modal} closeModal={() => setModal(false)} />}
    </div>
  );
};

export default AudioMessage;
