import { Select,  PhoneNumberLink,  Check,  Date,  Tags,  LastActivity,  AvatarAndIcon,  RowLink,  IconAndText, ArrayInfo, ClickColumn, Badge, HoverInfo } from "./renderFunctions";

const renderCell = (element, column, config, allData, renderCallStatus) => {
  const renderFunctions = {
    callStatus: (element) => renderCallStatus(element),
    select: (element, config) => (
      <Select allData={allData} element={element} config={config} />
    ),
    phoneNumberLink: (element, config, column) => (
      <PhoneNumberLink element={element} config={config} column={column} />
    ),
    check: (element, config, column) => (
      <Check element={element} config={config} column={column} />
    ),
    date: (element, config, column) => (
      <Date element={element} config={config} column={column} />
    ),
    tags: (element, config, column) => (
      <Tags element={element} config={config} column={column} />
    ),
    lastActivity: (element, config, column) => (
      <LastActivity element={element} config={config} column={column} />
    ),
    avatarAndIcon: (element, config, column) => (
      <AvatarAndIcon element={element} config={config} column={column} />
    ),
    rowLink: (element, config, column) => (
      <RowLink element={element} config={config} column={column} />
    ),
    iconAndText: (element, config, column) => (
      <IconAndText element={element} config={config} column={column} />
    ),
    arrayInfo: (element, config, column) => (
      <ArrayInfo element={element} config={config} column={column} />
    ),
    clickColumn: (element, config, column) => (
      <ClickColumn element={element} config={config} column={column} />
    ),
    badge: (element, config, column) => (
      <Badge element={element} config={config} column={column} />
    ),
    hoverInfo: (element, config, column) => (
      <HoverInfo element={element} config={config} column={column} />
    ),
  };

  if (config && config?.type && renderFunctions[config?.type]) {
    return renderFunctions[config?.type](element, config, column);
  }

  // Displayed by default if no special configuration is made.
  return element[column.key];
};

export default renderCell;
