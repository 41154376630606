import petitionPatch from "../../../../../../services/petitionPatch"

const editNumberService = async (number, data, updateAlertVariables, formError, form, setForm, setCurrentForm) => {
  try {
    await petitionPatch("numberEdit", { number, data })
    setCurrentForm(form)
  } catch (error) {
    console.log(error)
    const errorDescription = "A Required Field is missing or is an invalid data type."
    const isError400 = error?.response?.status === 400
    const errorMessage = error?.response?.data?.error?.error_detailed?.error?.error_message
    let showMessageError = ""

    if (isError400 && errorMessage === errorDescription) {
      showMessageError = "You must choose a valid data for the Ring strategy."
    } else {
      showMessageError = "An error has ocurred"
    }

    setForm(formError);
    updateAlertVariables("error", "Error", showMessageError, 3000)
  }
}

export default editNumberService;