// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-add-number-border.campaign-wizard-border {
  width: 5rem;
}

.itp-add-number-border.campaign-wizard-border.long {
  width: 6rem;
}

.itp-add-number-border.campaign-wizard-border.schedule {
  width: 8.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/SmartCampaigns/AddCampaign/AddCampaign.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":[".itp-add-number-border.campaign-wizard-border {\n  width: 5rem;\n}\n\n.itp-add-number-border.campaign-wizard-border.long {\n  width: 6rem;\n}\n\n.itp-add-number-border.campaign-wizard-border.schedule {\n  width: 8.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
