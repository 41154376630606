import petitionGet from "../../../../../services/petitionGet"

const contactDetailsService = async (e, customFields, setContactDetails, setLoadingMenu, setOpenMenu) => {
  try {
    setOpenMenu(true)
    setLoadingMenu(true)
    const { data: result } = await petitionGet("customFields")

    result.result.forEach((element) => {
      element.label = element.name;
      element.value = element.pk; 
    })

    e.contact.tags = e.contact.tags.map((tag) => ({
      ...tag,
      title: tag.tag.name,
      tag_color: tag.tag.tag_color,
      pk: tag.tag_id,
    }));

    customFields.current = result.result
    setContactDetails(e.contact)
    setLoadingMenu(false)
  } catch (error) {
    console.log(error)
  }
}

export default contactDetailsService;