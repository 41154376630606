import addOrEditTagService from "./addOrEditTagService/addOrEditTagService";
import deleteTagService from "./deleteTagService/deleteTagService";
import addOrEditContactListService from "./addOrEditContactListService/addOrEditContactListService";
import deleteContactListService from "./deleteContactListService/deleteContactListService";

export {
  addOrEditTagService,
  deleteTagService,
  addOrEditContactListService,
  deleteContactListService,
};
