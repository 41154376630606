import petitionPost from "../../../../../../services/petitionPost";

const addTagService = async (tagName, tags, optionsTags) => {
  try {
    const data = { name: tagName }
    const { data: result } = await petitionPost("addTags", { data })
    const newOption = {
      ...result.result,
      title: result.result?.name,
      label: result.result?.name,
      value: result.result?.pk,
      disabled: false,
    };

    const newTagId = result.result?.pk
    const newTags = [...tags, newOption];
    const newOptioTags = [...optionsTags, newOption];

    return { newTags, newOptioTags, newTagId }
  } catch (error) {
    console.log(error)
  }
}

export default addTagService;