// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-numbers-container-search-input {
  display: flex;
  align-items: center;
  border: 1px solid #3a3a3a99;
  border-radius: 16px;
  opacity: 1;
  width: 30rem;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 10px;
  /* height: 1rem; */
}

.itp-phone-numbers-transfer-button {
  margin-top: 12px;
  width: 10rem !important;
  background-image: none !important;
  white-space: nowrap;
  height: 1.9rem;
  padding-top: 3.5px !important;
  margin-left: 12px;
  background-color: transparent !important;
}

.itp-phone-numbers-transfer-button:hover {
  background-color: #fff !important;
}

#RFS-StepperContainer {
  margin-left: -3rem;
}

.itp-phone-numbers-refresh-icon {
  margin-right: -0.8rem;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/PhoneNumbers/PhoneNumbers.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,iCAAiC;EACjC,mBAAmB;EACnB,cAAc;EACd,6BAA6B;EAC7B,iBAAiB;EACjB,wCAAwC;AAC1C;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":[".phone-numbers-container-search-input {\n  display: flex;\n  align-items: center;\n  border: 1px solid #3a3a3a99;\n  border-radius: 16px;\n  opacity: 1;\n  width: 30rem;\n  padding-left: 8px;\n  padding-top: 4px;\n  padding-bottom: 4px;\n  margin-top: 10px;\n  /* height: 1rem; */\n}\n\n.itp-phone-numbers-transfer-button {\n  margin-top: 12px;\n  width: 10rem !important;\n  background-image: none !important;\n  white-space: nowrap;\n  height: 1.9rem;\n  padding-top: 3.5px !important;\n  margin-left: 12px;\n  background-color: transparent !important;\n}\n\n.itp-phone-numbers-transfer-button:hover {\n  background-color: #fff !important;\n}\n\n#RFS-StepperContainer {\n  margin-left: -3rem;\n}\n\n.itp-phone-numbers-refresh-icon {\n  margin-right: -0.8rem;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
