import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CollapsePanel, LoadingButton, ToggleImage } from "../index";
import { faFileText, faPlus, faCopy, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../utils/getDate";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import { addNoteService, deleteNoteService, editNoteService, fetchNotes } from "./services";
import Skeleton from "react-loading-skeleton";
import handleTextCopy from "../../utils/handleTextCopy";
import "./Notes.css";

const Notes = ({ contactId, setFinishFetchNotes, loading, myExtension }) => {
  const firstLoad = useRef(true);

  const [addNote, setAddNote] = useState(false);
  const [deleteNote, setDeleteNote] = useState(null);
  const [noteContent, setNoteContent] = useState("");
  const [loadingNote, setLoadingNote] = useState(false);
  const [notes, setNotes] = useState([]);
  const [editNote, setEditNote] = useState(null);
  const [changeEditNote, setChangeEditNote] = useState(null);
  const [openDropdownNotes, setOpenDropdownNotes] = useState(null);
  const [arryNotes, setArrayNotes] = useState([{}, {}]);

  const backNote = () => {
    setAddNote(false);
    setDeleteNote(false);
  };

  const toggleNotes = (event, index) => {
    if (loading) return;
    event.stopPropagation();
    setOpenDropdownNotes(index === openDropdownNotes ? null : index);
  };

  const addNewNote = () => {
    if (loading) return;
    setAddNote(true);
  };

  const showEditNote = (note_id) => {
    let showEditTextBox = editNote;
    showEditTextBox[note_id].edit = true;
    setChangeEditNote(showEditTextBox);
  };

  const noShowEditNote = () => {
    let showEditTextBox = editNote;
    Object.keys(showEditTextBox).map((element) => {
      showEditTextBox[element].edit = false;
    });
    setChangeEditNote(showEditTextBox);
  };

  const saveNote = async () => {
    await addNoteService(contactId, noteContent, setNoteContent, setLoadingNote, backNote, getData);
  };

  const removeNote = async () => {
    await deleteNoteService(deleteNote, setLoadingNote, backNote, getData);
  };

  const updateNote = async (note_id) => {
    await editNoteService(note_id, setLoadingNote, contactId, noteContent, noShowEditNote, getData);
  };

  const getData = async () => {
    try {
      await fetchNotes(contactId, setNotes, setEditNote);
      firstLoad.current = false;
      setLoadingNote(false);
      setFinishFetchNotes(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (changeEditNote) {
      setEditNote(changeEditNote);
      setChangeEditNote(null);
    }
  }, [changeEditNote]);

  useEffect(() => {
    if (!loading) setArrayNotes(notes);
  }, [loading, notes]);

  useEffect(() => {
    if (contactId && firstLoad.current) getData();
  }, [contactId]);

  return (
    <>
      <CollapsePanel
        title="Notes"
        subtitle="Add or view the notes related to this specific contact"
        className="mb-3"
        headerClassName="itp-note-panel"
        colStyle={{ padding: "0" }}
        colStyleContent={{ padding: "0" }}
        xxl={12}
        m0
      >
        {addNote && !deleteNote && (
          <div className="itp-add-note">
            <textarea
              spellCheck={false}
              onChange={(e) => setNoteContent(e.target.value)}
            />
            <div className="itp-add-note-buttons">
              <button
                onClick={backNote}
                disabled={loadingNote}
                className="btn-light"
              >
                Back
              </button>
              <button
                className="btn-primary itp-save-notes-button"
                disabled={noteContent === "" || loadingNote}
                onClick={saveNote}
              >
                {loadingNote && <LoadingButton />}
                Save
              </button>
            </div>
          </div>
        )}

        {!addNote && deleteNote && (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p>Do you want to Delete this note?</p>
            <div className="itp-add-note-buttons">
              <button
                onClick={backNote}
                disabled={loadingNote}
                className="btn-light"
              >
                Back
              </button>
              <button
                className="btn-primary itp-save-notes-button"
                disabled={loadingNote}
                onClick={removeNote}
              >
                {loadingNote && <LoadingButton />}
                Delete
              </button>
            </div>
          </div>
        )}

        {!addNote && !deleteNote && (
          <>
            <div className="itp-note-title">
              <label className="itp-notes-label">Add note</label>
              <button
                onClick={addNewNote}
                className="btn-primary itp-notes-button"
              >
                <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" /> Add
                Note
              </button>
            </div>

            {arryNotes.map((note, i) => (
              <div key={note?.pk || i} className="itp-note-content">
                <div className="itp-note-content__title">
                  <div
                    className={`title-note-left-section ${
                      loading ? "d-flex align-items-center gap-1" : ""
                    }`}
                  >
                    {loading ? (
                      <>
                        <Skeleton
                          borderRadius="10px"
                          height={24}
                          width="24px"
                        />
                        <Skeleton height={10} width="90px" />
                        <Skeleton height={10} width="140px" />
                      </>
                    ) : (
                      <>
                        <label className="itp-note-text">
                          {myExtension.firstname} {myExtension.lastname}
                        </label>
                        <span className="itp-note-text">
                          {note?.date_added ? formatDate(note?.date_added) : ""}
                        </span>
                      </>
                    )}
                  </div>
                  {editNote && !editNote[note?.pk]?.edit && (
                    <Dropdown
                      isOpen={i === openDropdownNotes}
                      toggle={(event) => toggleNotes(event, i)}
                      className="title-note-right-section"
                    >
                      <DropdownToggle className="nav-link">
                        <ToggleImage />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => setDeleteNote(note.pk)}
                          className="dropdown-item"
                        >
                          {<FontAwesomeIcon icon={faTrash} />} Delete Note
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => handleTextCopy(note.note_message)}
                          className="dropdown-item"
                        >
                          {<FontAwesomeIcon icon={faCopy} />} Copy Note
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => showEditNote(note.pk)}
                          className="dropdown-item"
                        >
                          {<FontAwesomeIcon icon={faPenToSquare} />} Edit Note
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  )}
                </div>

                {editNote && editNote[note?.pk]?.edit && (
                  <>
                    <textarea
                      className="itp-edit-note"
                      defaultValue={editNote[note.pk].note_message}
                      spellCheck={false}
                      onChange={(e) => setNoteContent(e.target.value)}
                    />
                    <div className="itp-add-note-buttons">
                      <button
                        onClick={noShowEditNote}
                        disabled={loadingNote}
                        className="btn-light"
                      >
                        Back
                      </button>
                      <button
                        className="btn-primary loading itp-save-notes-button"
                        disabled={
                          noteContent === "" ||
                          noteContent === editNote[note.pk].note_message
                        }
                        onClick={() => updateNote(note.pk)}
                      >
                        {loadingNote && <LoadingButton />}
                        Edit
                      </button>
                    </div>
                  </>
                )}

                {editNote && !editNote[note?.pk]?.edit && (
                  <div className="itp-note-content__content">
                    {!loading && (
                      <FontAwesomeIcon
                        icon={faFileText}
                        className="icon-text-note"
                      />
                    )}

                    {loading ? (
                      <Skeleton height={40} width="250px" />
                    ) : (
                      <p className="itp-contact-details-p">
                        {note.note_message}
                      </p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </CollapsePanel>
    </>
  );
};

export default Notes;
