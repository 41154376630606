import petitionPost from "../../../../../../services/petitionPost";

const addWebhookService = async (setLoadingWebhook, form, reloadData, updateAlertVariables) => {
  try {
    setLoadingWebhook(true);
    await petitionPost("webhook", { data: form })

    setLoadingWebhook(false);
    reloadData();
  } catch (error) {
    console.log(error)
    setLoadingWebhook(false);
    
    if (error?.response?.status === 400) {
      const errorMessage = error?.response?.data?.error?.error_detailed?.error?.error_detailed || error?.response?.data?.error?.error_detailed?.message
      updateAlertVariables("error", "Error", errorMessage, 4000)
    } else {
      const errorMessage = "An error has ocurred, please try again"
      updateAlertVariables("error", "Error", errorMessage, 4000)
    }
  }
}

export default addWebhookService;