import { Accordion } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import useScreenSize from "../../hooks/useScreenSize";

const CollapsePanel = ({ 
  children, 
  title, 
  subtitle, 
  className,
  m0, 
  m1, 
  isOpen, 
  colStyle, 
  headerClassName, 
  colStyleContent,
  xxl, 
}) => {
  const { width } = useScreenSize()

  const margin = width >= 992 && !m0 && !m1 ? "ms-5 me-5" : (m0 || width < 992) ? "ms-0 me-0" : m1 ? "ms-3 me-3" : "ms-5 me-5"

  const rowContainerClassName = `${margin} ${width >= 1697 ? "justify-content-center" : ""}`;

  return (
    <Row className={`${rowContainerClassName} ${className || ""}`}>
      <Col style={colStyle || {}} xs={12} sm={12} md={12} lg={12} xl={12} xxl={xxl ? xxl : width >= 1697 ? 10 : 12}>
        <Accordion defaultActiveKey={isOpen ? "0" : null}>
          <Accordion.Item eventKey="0">
            <Accordion.Header className={headerClassName || ""}>
              <div className="d-flex flex-column gap-2">
                {title && (
                  <h3 className="itp-accordion-header-title">{title}</h3>
                )}

                {subtitle && (
                  <h6 className="itp-accordion-header-subtitle">{subtitle}</h6>
                )}
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col style={colStyleContent || {}}>{children}</Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};

export default CollapsePanel;
