// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h2-color {
  color: #3A3A3A!important;
}

.btn-border {
  background-color: white;
  border-color: #437097;
  border-radius: 20px;
  color: #437097;
  width: 120px!important;
  height: 32px;
  font-family: revert!important;
  font-size: revert!important;
  font-weight: bold;
}

.hover-property:hover {
  background-color: #00264d;
  color: white;
}

.search-property {
  font-family: revert!important;
}

.search-property:hover {
  cursor: pointer;
	border-color: #437097;
}

.workflows-letter-property {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Instrument Sans', sans-serif;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
  text-align: left;
}

.itp-custom-table-container-actions.none {
  display: none!important;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/WorkFlows/WorkFlows.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;EACnB,cAAc;EACd,sBAAsB;EACtB,YAAY;EACZ,6BAA6B;EAC7B,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,eAAe;CAChB,qBAAqB;AACtB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,0CAA0C;EAC1C,mBAAmB;EACnB,cAAc;EACd,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".h2-color {\n  color: #3A3A3A!important;\n}\n\n.btn-border {\n  background-color: white;\n  border-color: #437097;\n  border-radius: 20px;\n  color: #437097;\n  width: 120px!important;\n  height: 32px;\n  font-family: revert!important;\n  font-size: revert!important;\n  font-weight: bold;\n}\n\n.hover-property:hover {\n  background-color: #00264d;\n  color: white;\n}\n\n.search-property {\n  font-family: revert!important;\n}\n\n.search-property:hover {\n  cursor: pointer;\n\tborder-color: #437097;\n}\n\n.workflows-letter-property {\n  font-weight: 600;\n  font-size: 18px;\n  line-height: 24px;\n  font-family: 'Instrument Sans', sans-serif;\n  letter-spacing: 0px;\n  color: #3A3A3A;\n  opacity: 1;\n  text-align: left;\n}\n\n.itp-custom-table-container-actions.none {\n  display: none!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
