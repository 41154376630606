// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-data-report-card-data {
  height: 3rem !important;
}

.itp-date-report-date {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-right: 2rem;
  white-space: nowrap;
}

.itp-date-report-range-date {
  font: normal normal 600 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-date-report-arrow-separator {
  margin-left: 12px;
  margin-right: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DateReportCalls/DateReportCalls.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iDAAiD;EACjD,mBAAmB;EACnB,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".itp-data-report-card-data {\n  height: 3rem !important;\n}\n\n.itp-date-report-date {\n  font: normal normal normal 14px/19px Instrument Sans;\n  letter-spacing: 0px;\n  color: #3a3a3acc;\n  opacity: 1;\n  margin-right: 2rem;\n  white-space: nowrap;\n}\n\n.itp-date-report-range-date {\n  font: normal normal 600 14px/19px Instrument Sans;\n  letter-spacing: 0px;\n  color: #002340;\n  opacity: 1;\n}\n\n.itp-date-report-arrow-separator {\n  margin-left: 12px;\n  margin-right: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
